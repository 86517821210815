.header--list {
  margin-bottom: 80px;
  padding-bottom: 30px;

  .header-filters {
    max-width: 100%;
    margin-right: initial;
    margin-left: initial;
    padding-top: 30px;
    border-top: 2px solid $brand-orange;

    form {
      max-width: gs(8, 7);
      margin-right: auto;
      margin-left: auto;

      @include mq($from: 'xxl') {
        max-width: gs(8, 7, 1392px, 12, 48px);
      }
    }
  }

  ~ .modules {
    position: relative;
    z-index: 1;
  }
}
