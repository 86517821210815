.module-card-program {
  .module-card__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    overflow: visible;
    border-radius: 0;
    background-color: transparent;

    @include mq($until: 's') {
      flex-direction: column;
    }

    &::after,
    &::before {
      content: none;
    }
  }

  &__column {
    &:nth-child(1),
    &:nth-child(3) {
      @include mq($until: 'm') {
        margin-bottom: 30px;
      }

      @include mq($from: 'm') {
        flex-basis: gs(2, 2);
      }

      @include mq($from: 'xxl') {
        flex-basis: gs(2, 2, 1392px, 12, 48px);
      }
    }

    &:nth-child(2) {
      flex: 1;

      @include mq($from: 's', $until: 'l') {
        margin-left: 30px;
      }

      @include mq($from: 'm', $until: 'l') {
        margin-right: 30px;
      }
      
      @include mq($from: 'l') {
        margin-right: gs(1, 1);
        margin-left: gs(1, 1);
      }

      @include mq($from: 'xxl') {
        margin-right: gs(1, 1, 1392px, 12, 48px);
        margin-left: gs(1, 1, 1392px, 12, 48px);
      }
    }

    &:nth-child(3) {
      @include mq($until: 'm') {
        display: none;
      }
    }
  }

  &__location,
  &__elligibility {
    font-size: rem(15px);
    line-height: (20 / 15);
    letter-spacing: 0.4px;
  }

  &__location {
    margin-bottom: 4px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: rem(28px);
    line-height: (32 / 28);
    letter-spacing: -0.5;
  }

  &__tag {
    margin-bottom: 32px;
  }

  &__text {
    font-size: rem(16px);
    line-height: (26 / 16);
    letter-spacing: -0.5;
  }

  &__button {
    margin-top: 16px;
  }

  &__logo {
    @include size(100%, auto);
    @include aspect-ratio(200, 140);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: $brand-white;

    img {
      @include position(absolute, 50% null null 50%);
      @include size(100%, auto);
      max-width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      transform: translate(-50%, -50%);
    }
  }
}
