.header--landing-detail {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, null null 215px 50%);
      @include margin(null null null -680px);

      @include mq($until: 'xl') {
        margin-left: -580px;
      }

      @include mq($until: 'l') {
        margin-left: -480px;
        bottom: 100px;
      }

      @include mq($until: 'm') {
        margin-left: -370px;
        bottom: 60px;
      }

      @include mq($until: 's') {
        margin-left: -140px;
        top: auto;
        bottom: -20px;
      }

      @include mq($until: 'xs') {
        margin-left: -160px;
      }
      
      picture {
        @include size(280px);
        clip-path: url(#header-3);

        @include mq($until: 'l') {
          @include size(200px);
        }

        @include mq($until: 'm') {
          @include size(150px);
        }

        @include mq($until: 's') {
          @include size(112px);
        }
      }

      .circle--1 {
        @include position(absolute, -105px -9px null null);
        @include size(78px, 108px);

        @include mq($until: 'l') {
          @include size(50px, 75px);
          top: -70px;
        }

        @include mq($until: 's') {
          top: -60px;
          right: -60px
        }
        
        @include mq($until: 'xs') {
          right: -30px
        }
        
        &::before {
          background-image: image('decorations/deco-ellipse-header.png');
          transform: scaleX(-1);
        }
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null -65px 50%);
      @include margin(null null null 170px);

      @include mq($until: 'l') {
        margin-left: 155px;
      }

      @include mq($until: 'm') {
        margin-left: 110px;
      }

      @include mq($until: 's') {
        margin-left: 30px;
      }

      @include mq($until: 'xs') {
        margin-left: -40px;
      }

      picture {
        @include size(500px);
        clip-path: url(#header-2);

        @include mq($until: 'l') {
          @include size(340px);
        }

        @include mq($until: 'm') {
          @include size(240px);
        }

        @include mq($until: 's') {
          @include size(200px);
        }
      }
    }
  }
}
