.module-form {
  &__group {
    legend {
      @include font('alternative', 'extra-bold');
      border-width: 2px;
      border-color: $brand-grey;
      color: $brand-blue-brighter;
    }

    p {
      color: $brand-blue;
    }
  }

  &__footer {
    border-width: 2px;
    border-color: $brand-grey;
  }
}
