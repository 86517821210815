.content-callout {
  padding-top: 80px;

  .module-header {
    &__text {
      margin-bottom: 0;
    }
  }

  .module-image {
    &:nth-child(1) {
      @include position(absolute, 57px null null 50%);
      @include margin(null null null -560px);

      @include mq($until: 'l') {
        margin-left: -480px;
      }

      @include mq($until: 'm') {
        margin-left: -370px;
        top: auto;
        bottom: 110px;
      }

      @include mq($until: 's') {
        margin-left: -260px;
        bottom: 10px;
      }

      @include mq($until: 'xs') {
        margin-left: -190px;
        bottom: -10px
      }
      
      picture {
        @include size(140px);
        clip-path: url(#small-large);

        @include mq($until: 'm') {
          @include size(120px);
        }
      }

      .circle--1 {
        @include position(absolute, -40px -25px null null);
        @include size(46px, 56px);

        @include mq($until: 'm') {
          top: -80px;
          right: 0px;
        }

        @include mq($until: 'xs') {
          right: 50px;
        }

        @include mq($until: 'xs') {
          @include size(36px, 46px);
        }
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null 180px 50%);
      @include margin(null null null 318px);

      @include mq($until: 'l') {
        margin-left: 260px;
      }

      @include mq($until: 'm') {
        margin-left: 180px;
        bottom: 90px;
      }

      @include mq($until: 's') {
        bottom: 40px;
        margin-left: 120px;
      }

      @include mq($until: 'xs') {
        bottom: -20px;
        margin-left: 50px;
      }

      picture {
        @include size(240px);
        clip-path: url(#large-large);

        @include mq($until: 'm') {
          @include size(180px);
        }

        @include mq($until: 'xs') {
          @include size(140px);
        }
      }
    }
  }
}
