// Set up a decent box model on the root element
// -----------------------------------------------------------------------------
html {
  height: 100%;
  box-sizing: border-box;
}

// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

// Set up body size to fill screen
// -----------------------------------------------------------------------------
body {
  width: 100%;
  background-color: $brand-grey-lighter;

  &:not(.keyboard-user) {
    * {
      outline: none;
    }
  }

  .skip-to-content {
    @include position(absolute, 0 null null 0);
    display: none;

    .button {
      &:not(:focus) {
        opacity: 0;
      }
    }
  }

  &.keyboard-user {
    .skip-to-content {
      display: block;
    }
  }
}

// Wrapper styles
// -----------------------------------------------------------------------------
.site-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Fallback */
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  .site-container {
    position: relative;
    flex-grow: 1;
  }
}

// Center Element
// -----------------------------------------------------------------------------
.center {
  padding-right: 30px;
  padding-left: 30px;

  @include mq($until: 'm') {
    padding-right: 44px;
    padding-left: 44px;
  }

  @include mq($from: 'xl') {
    max-width: 1220px;
    margin-right: auto;
    margin-left: auto;
  }

  @include mq($from: 'xxl') {
    max-width: 1452px;
  }
}

// Set up global SVG container
// -----------------------------------------------------------------------------
.svg {
  display: inline-block;

  svg {
    display: block;
  }
}

// Styleguide Labels
// -----------------------------------------------------------------------------
.site-group {
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  .debug-on & {
    border-color: #e8e8e8;
  }

  &__label {
    @include position(absolute, 0 null null 0);
    @include padding(5px 10px);
    display: none;
    background-color: #bbb;
    color: #f8f8f8;
    font-family: 'Monaco', monospace;
    font-size: rem(9px);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    .debug-on & {
      display: block;
    }
  }

  + .site-group {
    .debug-on & {
      margin-top: -1px;
    }
  }
}

// Tiny Slider
// -----------------------------------------------------------------------------
.tns-visually-hidden {
  display: none;
}

@for $n from 0 through 10 {
  .transition-delay--#{$n} {
    transition-delay: $n / 8 * 1s !important;
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .js-title-effect {
    opacity: 0;
  
    > div {
      transition: transform .5s $ease-out-quad, opacity .5s $ease-out-quad;
      transform: translateY(40px);
      opacity: 0;
  
      @for $n from 0 through 7 {
        &:nth-child(#{$n}) {
          transition-delay: $n / 10 * 1s + .1s;
        }
      }
    }
  }

  &.in-view .js-title-effect.is-ready {
    opacity: 1;

    > div {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.svg-filters {
  position: absolute;
}
.module-separator{
    padding-top:15px;
}