.content-keypoints {
  padding-top: 60px;
  padding-bottom: 60px;

  .module-header {
    margin-bottom: 50px;
  }

  &__list {
    @include mq($until: 'l', $from: 's') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
    }
  }

  &__text {
    @include mq($until: 'l') {
      margin-left: 0;
    }
  }

  &__item {
    @include mq($until: 's') {
      width: 100%;
    }
    
    @include mq($from: 's') {
      width: calc(50% - 11px);
      max-width: 360px;
    }

    @include mq($until: 'l', $from: 's') {
      &:nth-child(even) {
        margin-left: 22px;
      }
    }

    @include mq($from: 'l', $until: 'xl') {
      flex-basis: calc(25% - 18px);
      min-width: calc(25% - 18px);
      max-width: calc(25% - 18px);

      &:not(:first-child) {
        margin-left: 20px;  
      }
    }

    @include mq($from: 'xl') {
      flex-basis: gs(3, 2);
      min-width: gs(3, 2);
      max-width: gs(3, 2);
    }
    
    @include mq($from: 'xxl') {
      flex-basis: gs(3, 2, 1392px, 12, 48px);
      min-width: gs(3, 2, 1392px, 12, 48px);
      max-width: gs(3, 2, 1392px, 12, 48px);
    }
  }

  &__box {
    @include size(100%, 100%);
    @include padding(57px 50px 50px);
    border-radius: 16px;
    background-color: $brand-white;
  }

  &__index {
    @include font('alternative', 'extra-bold');
    color: $brand-orange-bright;
  }

  &__text {
    @include font('alternative', 'bold');
    color: $brand-purple-bright;
  }

  html:not(.mobile):not(.ie) &.js-in-view {
    .content__column {
      &:first-child {
        .content-keypoints__line {
          transform-origin: right center;
        }
      }
      
      &:last-child {
        .content-keypoints__line {
          transform-origin: left center;
        }
      }
    }

    .content-keypoints__index {
      @include mq($from: 'l') {
        transform: translateY(20px);
        opacity: 0;
        transition: transform 0.4s .3s $ease-out-quad, opacity 0.4s .3s $ease-out-quad;
      }
    }
    
    .content-keypoints__line,
    .content__line-spark::before {
      transform: scaleX(0);
      transition: transform 0.5s .2s $ease-out-quad
    }

    .content-keypoints__circle {
      &::before {
        transform: scale(0);
        transition: transform 1.1s .2s $ease-out-expo;
      }
    }

    .content__inner {
      &::before {
        transform: scale(0);
        transition: transform 1.1s .1s $ease-out-expo;
      }
    }

    &.in-view {
      .content-keypoints__line,
      .content__line-spark::before {
        transform: scaleX(1);
      }

      .content-keypoints__index {
        transform: translateY(0px);
        opacity: 1;
      }

      .content-keypoints__circle {
        &::before {
          transform: scale(1);
        }
      }

      .content__inner {
        &::before {
          transform: scale(1);
        }
      }
    }
  }
}
