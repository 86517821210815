.button-icon {
  @include font('alternative', 'extra-bold');

  &--purple,
  &--purple-bright {
    .svg {
      fill: $brand-purple-bright;
      background-color: $brand-white;
    }
  }

  &--purple {
    color: $brand-purple-bright;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.4);
    }
    
    &::before {
      background-color: $brand-purple-light;
    }
  }

  &--purple-bright {
    color: $brand-white;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.3);
    }

    &::before {
      background-color: $brand-purple-bright;
    }
  }

  &--blue,
  &--blue-brighter {
    color: $brand-blue-brighter;

    .svg {
      fill: $brand-blue-brighter;
      background-color: $brand-white;
    }

    &::before {
      background-color: $brand-blue-light;
    }

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-blue-brighter, 0.3);
    }
  }

  &--white {
    color: $brand-purple-bright;

    .svg {
      fill: $brand-white;
      background-color: $brand-purple-bright;
    }

    &::before {
      background-color: $brand-white;
    }

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-white, 0.6);
    }
  }

  &[disabled] {
    color: #65769F;
    
    &::before {
      background-color: #dadee7;
    }

    .svg {
      fill: #65769F;
      background-color: $brand-white;
    }
  }
}
