.header-nav {
  &.has-menu {
    border-color: #FCD70E;
  }

  &__page-name {
    @include font('alternative', 'extra-bold');
    color: $brand-grey-darker;
  }

  &__menu {
    &-item {
      a:not(.button) {
        @include position(relative);
        @include font('alternative', 'bold');
        color: $brand-purple-bright;

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-blue-brighter;

          &::before {
            background-color: $brand-blue-brighter;
          }
        }

        &::before {
          @include size(100%, 2px);
          @include position(absolute, null null -4px 0);
          content: '';
          background-color: $brand-purple-bright;
          border-radius: 2px;
          transition: background-color .3s $ease-out-quad;
        }
      }
    }
  }
}
