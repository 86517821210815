.module-popup-activity {
    &__content {
        &-title {
            @include font('sans-serif', 'book');
            color: $brand-grey-darker;

            @include mq($until: 'm') {
                @include font('sans-serif', 'bold');
            }
        }

        &-text {
            @include font('sans-serif', 'book');
            color: $brand-grey-popup-content;

            a {
                color: $brand-purple-bright;
            }
        }
    }
}