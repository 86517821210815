.content-key-numbers {
  padding-top: 60px;
  padding-bottom: 60px;
  
  .module-header {
    margin-bottom: 50px;
  }

  &__list {
    @include mq($until: 'l', $from: 's') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mq($until: 'xl') {
      align-items: stretch;
    }
  }

  &__item {
    padding: 50px;
    border-radius: 16px;
    background-color: $brand-white;

    @include mq($until: 's') {
      width: 100%;
    }
    
    @include mq($from: 's') {
      width: calc(50% - 11px);
      max-width: 360px;
    }

    @include mq($until: 'l', $from: 's') {
      &:nth-child(even) {
        margin-left: 22px;
      }
    }

    @include mq($from: 'l', $until: 'xl') {
      flex-basis: calc(25% - 18px);
      min-width: calc(25% - 18px);
      max-width: calc(25% - 18px);

      &:not(:first-child) {
        margin-left: 20px;  
      }
    }

    @include mq($from: 'xl') {
      flex-basis: gs(3, 2);
      min-width: gs(3, 2);
      max-width: gs(3, 2);
    }
    
    @include mq($from: 'xxl') {
      flex-basis: gs(3, 2, 1392px, 12, 48px);
      min-width: gs(3, 2, 1392px, 12, 48px);
      max-width: gs(3, 2, 1392px, 12, 48px);
    }
  }

  &__value {
    @include margin(null null 12px);
    @include font('alternative', 'extra-bold');
    color: $brand-orange-bright;
    font-size: rem(40px);
    line-height: (44 / 40);
    letter-spacing: -1px;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }
}
