.module-faq {
  &__list {
    &-item {
      &:not(:first-child) {
        border-color: $brand-grey;
        border-width: 2px;
      }
      
      &-title {
        @include font('alternative', 'bold');
        color: $brand-purple-bright;
      }

      &-button {
        .svg {
          fill: $brand-purple-bright;
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          ~ .module-faq__list-item-title {
            color: $brand-blue-brighter;
          }
        }
      }

      &-text {
        @include font('sans-serif', 'regular');
        color: $brand-blue;

        b,
        strong {
          @include font('sans-serif', 'bold');
        }

        i,
        em {
          @include font('sans-serif', 'regular-italic');
        }

        ul {
          li::before {
            background-color: $brand-blue;
          }
        }
    
        ol {
          li::before {
            @include font('sans-serif', 'bold');
            color: $brand-blue;
          }
        }
      }
    }
  }
}
