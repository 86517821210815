.module-card-schedule {
  &__text,
  &__caption {
    @include font('sans-serif', 'regular');
  }
  
  &__text {
    color: $brand-blue-brighter;
  }

  &__hour {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
  }

  &__caption {
    color: $brand-blue;
  }

  + .module-card-schedule {
    margin-top: -2px;
    border-color: $brand-grey;
    border-width: 2px;
  }
}
