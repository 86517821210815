.module-tabs {
  @include mq($from: 'xl') {
    border-radius: 36px;
    background-color: $brand-white;
  }

  &__list {
    @include mq($until: 'xl') {
      background-color: $brand-white;
    }
    
    @include mq($from: 'm', $until: 'xl') {
      border-radius: 36px;
    }

    @include mq($until: 'm') {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    button {
      &::after {
        background-color: $brand-purple-bright;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus,
      &.is-active {
        .module-tabs__list-day,
        .module-tabs__list-date {
          color: $brand-purple-bright;
        }
      }
    }
    
    li:last-child {
      &::before {
        background-color: $brand-white;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &::after {
        background-color: $brand-grey-lighter;
      }
    }

    &-day,
    &-date {
      @include font('alternative', 'extra-bold');
      color: $brand-blue-brighter;
    }
  }
}
