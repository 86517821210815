.header {
  background-color: $brand-yellow-bright;

  @include mq($until: 'm') {
    padding-bottom: 150px;
  }

  .header-inner {
    max-width: gs(6, 5);

    &__text {
      max-width: 440px;
    }
  }

  .module-image {
    pointer-events: none;

    .module-image__video-button {
      pointer-events: auto;
    }
  }

  &--curvy {
    background-color: transparent;

    &::after {
      @include position(absolute, null null -10px 50%);
      @include size(3280px);
      @include margin(null null null -1640px);
      content: '';
      z-index: -2;
      border-radius: 50%;
      background-color: $brand-yellow-bright;
    }
  }
}
