.module-card-activity {
  .module-card__link {
    .no-touchevents &:focus,
    .no-touchevents &:hover {
      ~ .module-card__inner {
        .module-card__image-overlay {
          opacity: 1;
        }

        .module-card-activity {
          &__image {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .module-card__inner {
    background-color: transparent;

    &::before {
      content: none;
    }
  }

  .module-card__dot {
    display: none;
  }

  .module-card__image-overlay {
    &::before {
      content: none;
    }

    &::after {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      content: '';
      z-index: 2;
      background-image: linear-gradient(198deg, rgba(152, 42, 136, 0.0) 28%, rgba(152, 42, 136, 0.8) 70%);
    }
  }

  &__image {
    border-radius: 24px;

    &::after {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      content: '';
      z-index: 1;
      pointer-events: none;
      transition: opacity 0.3s $ease-out-quad;
      background-image: linear-gradient(198deg, rgba(68, 23, 62, 0.0) 28%, rgba(68, 23, 62, 0.7) 70%);
    }
  }

  &__tags {
    margin-bottom: 12px;
  }

  &__title {
    @include font('alternative', 'bold');
    color: $brand-white;
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -0.5px;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-white;
  }

  &__content {
    color: $brand-white;
  }
}
