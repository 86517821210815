.header--home {
  .module-image {
    z-index: 3;

    &:nth-child(1) {
      @include position(absolute, 120px null null 50%);
      @include margin(null null null -610px);

      @include mq($until: 'l') {
        margin-left: -450px;
        top: auto;
        bottom: 180px;
      }

      @include mq($until: 'm') {
        margin-left: -290px;
        bottom: 10px;
      }

      @include mq($until: 's') {
        margin-left: -130px;
        top: auto;
        bottom: 0px;
      }
      
      @include mq($until: 'xs') {
        margin-left: -150px;
        bottom: 10px;
      }

      picture {
        @include size(286px);
        clip-path: url(#header-1);

        @include mq($until: 'l') {
          @include size(200px);
        }

        @include mq($until: 'm') {
          @include size(150px);
        }

        @include mq($until: 's') {
          @include size(112px);
        }
      }

      .circle--1 {
        @include position(absolute, null null 35px 0);
        @include size(66px);

        @include mq($until: 'l') {
          @include size(40px);
          bottom: -10px;
        }

        @include mq($until: 'xs') {
          bottom: 0px;
        }
        
        &::before {
          background-image: image('decorations/deco-ellipse-header.png');
        }
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null -85px 50%);
      @include margin(null null null 170px);

      @include mq($until: 'l') {
        margin-left: 155px;
      }

      @include mq($until: 'm') {
        margin-left: 110px;
      }

      @include mq($until: 's') {
        margin-left: 20px;
        bottom: -75px;
      }

      @include mq($until: 'xs') {
        margin-left: -30px;
        bottom: -50px;
      }

      picture {
        @include size(500px);
        clip-path: url(#header-2);

        @include mq($until: 'l') {
          @include size(340px);
        }

        @include mq($until: 'm') {
          @include size(240px);
        }

        @include mq($until: 'xs') {
          @include size(200px);
        }
      }
    }
  }
}