.module-card-icon {
  .module-card__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner {
      .module-card-icon__title {
        color: $brand-purple-bright;
      }
    }
  }

  &__title {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
    transition: color 0.3s $ease-out-quart;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }

  &__icon {
    background-color: $brand-yellow-bright;
  }
}
