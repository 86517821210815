.header--home-affiliate {
  z-index: initial;

  .header-inner {
    margin-left: 0;
    text-align: left;

    &__title {
      @include mq($until: 'm') {
        padding: 0;
      }
    }

    &__text {
      margin-left: 0;
    }

    &__buttons {
      justify-content: flex-start;

      @include mq($until: 'm') {
        align-items: flex-start;
      }
    }
  }

  .module-image {
    &:nth-child(1) {
      @include position(absolute, 40px null null 50%);
      @include margin(null null null -30px);

      @include mq($until: 'xl') {
        top: auto;
        bottom: -110px;
        margin-left: 0;
      }

      @include mq($until: 'xs') {
        bottom: -90px;
        margin-left: -100px;
      }

      picture {
        @include size(880px);
        clip-path: url(#header-affiliate);

        @include mq($until: 'xl') {
          @include size(60vw);
          min-width: 250px;
          min-height: 250px;
        }
      }
    }
  }

  &.header--curvy::after {
    @include size(3000px, 1000px);
    margin-left: -1500px;
  }
}