.button-line {
  @include font('alternative', 'extra-bold');

  &--purple-bright {
    color: $brand-purple-bright;

    span {
      fill: $brand-purple;

      &:not(.svg) {
        &::before {
          background-color: $brand-purple;
        }
      }
    }

    .no-touchevents &:active {
      span:not(.svg) {
        &::before {
          background-color: $brand-purple-bright;
        }
      }
    }
  }

  &[disabled] {
    color: #4d5a7c;

    span:not(.svg) {
      &::before {
        background-color: rgba(#4d5a7c, 0.2);
      }
    }
  }
}
