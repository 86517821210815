.alert-bar {
  @include padding(null 30px);
  background-color: $brand-purple-darker;

  p {
    @include font('sans-serif', 'regular');
    color: #FF5F14;

    a {
      @include font('sans-serif', 'medium');
      color: $brand-white;
    }
  }

  &__button {
    fill: #FF5F14;
  }
}