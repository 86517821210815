.site-footer {
  background-color: $brand-purple-darker;

  &__primary-nav {
    &-item {
      &-button {
        @include font('alternative', 'extra-bold');
        font-size: rem(20px);
        line-height: (24 / 20);
        letter-spacing: -.2px;

        @include mq($from: 'xxl') {
          font-size: rem(22px);
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: #E96398;
        }
      }
    }
  }

  &__secondary-nav {
    &-item {
      &-button {
        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: #E96398;
        }
      }
    }
  }

  &__text {
    color: #E96398;
  }

  &__metas {
    display: flex;
    align-items: center;

    &-item,
    &-item-button {
      color: #E96398;
    }

    &-item-button {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-white;
      }
    }
  }

  .eyebrow {
    color: #E96398;
  }

  &__column {
    &-toggler {
      @include mq($until: 's') {
        color: #E96398;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-color: rgba($brand-purple-bright, .4);
    }
  }

  &__dropdown {
    .dropdown__button {
      @include font('sans-serif', 'heavy');
      color: $brand-white;
      font-size: rem(10px);
      line-height: (16 / 10);
      letter-spacing: 1.5px;
      background: $brand-purple-bright;
    }
  }
}
