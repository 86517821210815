.module-card-text {
  .module-card__inner {
    background: transparent;
  }

  &__eyebrow {
    border-color: $brand-grey;
    border-width: 2px;
  }

  &__title {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }
}
