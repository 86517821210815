.widget-videos {
  &__item {
    border-radius: 16px;
    background-color: $brand-white;

    &-title {
      @include font('alternative', 'bold');
      color: $brand-blue-brighter;
    }

    &-text {
      @include font('sans-serif', 'medium');
      color: $brand-blue;
    }
  }
}
