.eyebrow {
  @include font('alternative', 'extra-bold');
  color: $brand-blue;

  &--blue {
    color: $brand-blue;
  }

  &--white {
    color: $brand-white;
  }

  &--blue-bright {
    color: $brand-blue-bright;
  }

  &--blue-brighter {
    color: $brand-blue-brighter;
  }

  &--purple-bright {
    color: $brand-purple-bright;
  }
}
