.header--detail {
  margin-bottom: 80px;
  padding-bottom: 70px;

  @include mq($until: 'l') {
    padding-bottom: 45px;
  }

  .header-inner {
    max-width: 70%;
    margin-left: 0;
    text-align: left;

    @include mq($until: 'm') {
      max-width: 100%;
    }

    &__title {
      @include mq($until: 'm') {
        @include padding(null 0);
      }
    }

    &__text {
      @include mq($until: 'm') {
        @include margin(null 0);
      }
    }
  }
}
