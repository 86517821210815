.form-filter {
  &__button {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    background: $brand-white;
  }

  &__dropdown {
    background-color: $brand-white;
    box-shadow: 0 10px 40px 0 rgba(6, 41, 78, 0.08);

    &-footer {
      border-color: $brand-blue-light;
    }

    &-clear {
      @include font('sans-serif', 'medium');
      color: $brand-blue;
    }
  }

  .module-form__checkbox {
    label {
      color: $brand-purple-bright;

      &::after {
        background-image: url('../assets/images/icons/checked-white@2x.png');
      }
    }

    input:checked {
      + label::before {
        border-color: $brand-purple-bright;
        background-color: $brand-purple-bright;
      }
    }
  }
}
