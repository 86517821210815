.site-header {
  &__bar {
    transition: border-color .3s $ease-out-quad;
    border-color: #FCD70E;

    body.submenu-is-open & {
      border-color: rgba($brand-grey, .2);
    }

    &::before {
      background-color: $brand-yellow-bright;
    }
  }

  &__logo {
    @include padding(null 36px null 35px);
    border-color: rgba($brand-grey-darker, .05);

    @include mq($until: 1280px) {
      @include padding(null 32px null 30px);
    }

    &-icw {
      width: 164px;
      flex-shrink: 0;
      margin-bottom: -5px;

      @include mq($until: 1280px) {
        width: 132px;
        margin-bottom: 0;
      }

      &-text {
        fill: $brand-blue-darker;
      }

      &-back {
        fill: $brand-white;
      }
    }

    &-thf {
      width: 34px;
      margin-left: 8px;
      flex-shrink: 0;

      @include mq($until: 1280px) {
        width: 28px;
        margin-bottom: 6px;
      }

      &-bg {
        fill: $brand-blue-darker;
      }
  
      &-text {
        fill: $brand-white;
      }
    }
  }

  &__menu-button {
    border-color: #FCD70E;

    &-bar {
      background-color: $brand-blue-darker;
    }
  }

  &__desktop-submenu-backdrop {
    background-color: $brand-white;
  }
  
  &__dropdown {
    .dropdown__button {
      border-color: $brand-blue-light;
      color: rgba($brand-grey-darker, .4);
    }
  }

  &__submenu-close-button {
    @include font('sans-serif', 'bold');
    color: $brand-blue-darker;
    font-size: rem(14px);
    line-height: (20 / 14);
    letter-spacing: .5px;
  }

  &__nav {
    @include mq($until: 'xl') {
      background-color: $brand-white;
    }
  }

  &__secondary-nav {
    .button {
      @include mq($until: 'xl') {
        @include padding(21px null);
        height: 52px;
        font-size: rem(11px);
      }
    }
    
    @include mq($until: 'xl') {
      &:last-child {
        border-bottom: 1px solid rgba($brand-grey-darker, .05);
      }

      &:first-child {
        @include mq($until: 'xl') {
          .site-header__secondary-nav-item:not(:first-child) {
            .site-header__secondary-nav-item-button {
              border: 1px solid $brand-blue-light;
            }
          }
        }
      }
    }

    &-item {
      &-select-wrapper {
        .svg {
          fill: rgba($brand-grey-darker, .4);
        }
      }

      &-select {
        @include font('sans-serif', 'bold');
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: $brand-ochre;
        border: 1px solid rgba($brand-ochre, .2);

        @include mq($until: 'xl') {
          color: $brand-blue;
          border-color: $brand-grey;
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          border-color: $brand-blue-brighter;
          color: $brand-blue-brighter;
        }

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }
      }
      
      &-button {
        @include font('sans-serif', 'bold');
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: $brand-ochre;

        @include mq($until: 'xl') {
          color: $brand-blue;
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-blue-brighter;
        }

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }
      }
    }
  }

  &__primary-nav {
    &-item {
      &-button {
        @include font('alternative', 'bold');
        color: $brand-blue-darker;
        font-size: rem(17px);
        line-height: (24 / 17);
        letter-spacing: -.3px;

        @include mq($until: 1360px) {
          font-size: rem(16px);
        }

        @include mq($until: 1280px) {
          font-size: rem(15px);
        }

        @include mq($until: 'xl') {
          @include font('alternative', 'semi-bold');
          @include padding(14px null 14px null);
          font-size: rem(24px);
        }

        @include mq($until: 'm') {
          font-size: rem(20px);
        }
        
        
        &-label {
          &::before {
            background-color: $brand-purple-bright;
          }
        }

        .svg {
          fill: $brand-purple-bright;
        }

        .svg--arrow-right {
          background-color: $brand-grey-light;
        }
      }
    }
  }

  &__submenu {
    @include mq($until: 'xl') {
      background-color: $brand-white;
    }

    &-content {
      &-title {
        @include font('sans-serif', 'bold');
        color: $brand-blue-brighter;
        font-size: rem(22px);
        line-height: (26 / 22);
        letter-spacing: -.6px;
      }

      &-text {
        @include font('sans-serif', 'regular');
        color: $brand-blue;
        font-size: rem(16px);
        line-height: (24 / 16);
        letter-spacing: -.5px;
      }
    }

    &-nav {
      &-item {
        &:not(:last-child) {
          border-color: $brand-blue-light;
        }

        &-label {
          color: $brand-blue;
        }

        &-button {
          color: $brand-blue-brighter;

          .no-touchevents &:hover,
          .no-touchevents &:focus {
            color: $brand-purple-bright;
          }
        }
      }
    }
  }
}
