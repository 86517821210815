.widget-video {
  border-radius: 16px;

  &__play {
    z-index: 3;

    .svg {
      fill: $brand-purple-bright;
    }
  }

  &__poster {
    &::before,
    &::after {
      @include size(100%, 100%);
      @include position(absolute, 0 null null 0);
      content: '';
      z-index: 2;
    }

    &::after {
      background-image: linear-gradient(198deg, rgba(68, 23, 62, 0.0) 28%, rgba(68, 23, 62, 0.7) 70%);
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      .widget-video__play {
        background-color: $brand-purple-bright;
      }
    }
  }
}
