.module-text {
  &__title {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    line-height: (46 / 44);
    letter-spacing: -1.5px;
  }

  &__lead {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    font-size: rem(36px);
    line-height: (40 / 36);
    letter-spacing: -1px;
  }

  &__content {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }
}
