.tag {
  @include padding(1px null 0);
  @include font('alternative', 'extra-bold');
  line-height: 24px;

  .svg {
    margin-top: -2px;
  }

  &--purple-faded {
    color: $brand-purple-bright;
    background-color: rgba(#a51890, 0.1);

    &::before {
      display: none;
    }
  }

  &--purple-bright {
    color: $brand-purple-bright;
    background-color: $brand-purple-light;
  }

  &--orange-bright {
    color: $brand-orange-bright;
    background-color: $brand-orange;
  }

  &--white {
    color: $brand-purple-bright;
    background-color: $brand-white;
  }
}
