.content-cards {
  padding-bottom: 75px;

  &.content--curvy {
    padding-top: 120px;
    padding-bottom: 120px;

    .module-card-icon,
    .module-card-press,
    .module-card-small {
      .module-card__inner::before {
        content: '';
      }
    }
  }
}
