.widget-quote {
  border-color: $brand-grey;

  p {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
  }

  cite {
    @include font('sans-serif', 'bold');
    color: rgba($brand-blue, 0.5);
    font-size: rem(13px);
    line-height: (24 / 13);
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
