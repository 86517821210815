.circle {
  position: relative;
  display: block;
  pointer-events: none;
  backface-visibility: hidden;

  &::before {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
    content: '';
    background-image: image('decorations/deco-ellipse.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include mq($until: 's') {
      @include size(70%, 70%);
    }
  }
}