.header--landing-alternative {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 120px null null 50%);
      @include margin(null null null 360px);

      @include mq($until: 'xl') {
        margin-left: 320px;
      }

      @include mq($until: 'l') {
        top: auto;
        margin-left: 220px;
        bottom: -30px;
      }

      @include mq($until: 'm') {
        margin-left: 140px;
        bottom: -50px;
      }

      @include mq($until: 's') {
        margin-left: 50px;
      }
      
      picture {
        @include size(264px);
        clip-path: url(#header-4);

        @include mq($until: 'l') {
          @include size(184px);
        }

        @include mq($until: 'm') {
          @include size(150px);
        }

        @include mq($until: 's') {
          @include size(112px);
        }
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null -65px 50%);
      @include margin(null null null -650px);

      @include mq($until: 'l') {
        bottom: -120px;
        margin-left: -420px;
      }

      @include mq($until: 'm') {
        bottom: -50px;
        margin-left: -320px;
      }

      @include mq($until: 's') {
        margin-left: -200px;
      }

      @include mq($until: 'xs') {
        margin-left: -170px;
      }

      picture {
        @include size(440px);
        clip-path: url(#large-small);

        @include mq($until: 'l') {
          @include size(340px);
        }

        @include mq($until: 'm') {
          @include size(240px);
        }

        @include mq($until: 's') {
          @include size(200px);
        }
      }

      .circle--1 {
        @include position(absolute, -60px null null 164px);
        @include size(76px, 84px);

        @include mq($until: 'l') {
          @include size(50px, 58px);
          left: 90px;
        }

        @include mq($until: 's') {
          left: 200px;
          top: 10px;
        }
        
        &::before {
          background-image: image('decorations/deco-ellipse-header.png');
        }
      }
    }
  }
}