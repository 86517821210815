.content-column {
  .module-text {
    &__title {
      + .module-text__content {
        margin-top: 0;
      }
    }

    &__content {
      @include mq($from: 'm') {
        margin-top: 50px;
      }
    }
  }

  .module-perks {
    margin-top: 20px;
  }

  .module-image {
    @include margin(null auto);
  }

  .content__column {
    @include mq($from: 'm') {
      margin-top: 105px;
      margin-bottom: 55px;
    }
  }

  &:not(.content-quotes) .content__column {
    @include mq($until: 'm') {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  &--wide {
    .content__column {
      > * {
        @include mq($from: 'xl') {
          margin-right: 0;
          margin-left: 0;
        }
      }

      &:nth-child(1) {
        @include mq($from: 'xl') {
          flex-basis: gs(5, 4);
          min-width: gs(5, 4);
          max-width: gs(5, 4);
        }

        @include mq($from: 'xxl') {
          flex-basis: gs(5, 4, 1392px, 12, 48px);
          min-width: gs(5, 4, 1392px, 12, 48px);
          max-width: gs(5, 4, 1392px, 12, 48px);
        }
      }

      &:nth-child(2) {
        @include mq($from: 'xl') {
          flex-basis: gs(6, 6);
          min-width: gs(6, 6);
          max-width: gs(6, 6);
        }

        @include mq($from: 'xxl') {
          flex-basis: gs(6, 6, 1392px, 12, 48px);
          min-width: gs(6, 6, 1392px, 12, 48px);
          max-width: gs(6, 6, 1392px, 12, 48px);
        }
      }
    }
  }

  &:not(.content-column--reflect) {
    .module-image {
      .module-image__item,
      .module-image__elements {
        @include mq($from: 'm') {
          margin-left: -50px;
        }
      }
    }
  }

  &--reflect {
    .content__column {
      @include mq($from: 'm') {
        margin-top: 105px;
        margin-bottom: 55px;
      }
    }

    .module-image {
      transform: scaleX(-1);

      .module-image__item,
      .module-image__elements {
        @include mq($from: 'm') {
          margin-right: -50px;
        }
      }

      img {
        transform: scaleX(-1);
      }
    }
  }

  &--mobile-reflect {
    .content__column {
      &:last-child {
        @include mq($until: 'm') {
          margin-bottom: 20px;
        }
      }
    }
  }
}
