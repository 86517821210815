@charset "UTF-8";
/**
 * Kennel 0.0.1
 *
 * @project : Kennel - Dogstudio Style Sheets
 * @author  : Dogstudio
 */
/**
 * Default colors palette
 *
 * @type Map
 */
/**
 * Default z-layers map
 *
 * @type Map
 */
@keyframes plyr-progress {
  to {
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: var(--plyr-font-weight-regular, 400);
  height: 100%;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
.plyr video,
.plyr audio,
.plyr iframe {
  display: block;
  height: 100%;
  width: 100%;
}
.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}
.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}
.plyr--full-ui *,
.plyr--full-ui *::after,
.plyr--full-ui *::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: var(--plyr-badge-background, hsl(216deg, 15%, 34%));
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}
.plyr__captions span:empty {
  display: none;
}
@media (min-width: 480px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}
.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
  position: relative;
  transition: all 0.3s ease;
}
.plyr__control svg {
  display: block;
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
}
.plyr__control:focus {
  outline: 0;
}
.plyr__control.plyr__tab-focus {
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

a.plyr__control {
  text-decoration: none;
}
a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}
.plyr__controls .plyr__controls__item {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}
.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}
.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
  padding-left: 0;
}
.plyr__controls:empty {
  display: none;
}

.plyr [data-plyr=captions],
.plyr [data-plyr=pip],
.plyr [data-plyr=airplay],
.plyr [data-plyr=fullscreen] {
  display: none;
}

.plyr--captions-enabled [data-plyr=captions],
.plyr--pip-supported [data-plyr=pip],
.plyr--airplay-supported [data-plyr=airplay],
.plyr--fullscreen-enabled [data-plyr=fullscreen] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}
.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}
.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}
.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}
.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: 4px;
  bottom: 100%;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-menu-color, hsl(216deg, 15%, 34%));
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
.plyr__menu__container::after {
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: "";
  height: 0;
  position: absolute;
  right: calc(((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7)) - (var(--plyr-menu-arrow-size, 4px) / 2));
  top: 100%;
  width: 0;
}
.plyr__menu__container [role=menu] {
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}
.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}
.plyr__menu__container .plyr__control {
  align-items: center;
  color: var(--plyr-menu-color, hsl(216deg, 15%, 34%));
  display: flex;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  padding-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5);
  user-select: none;
  width: 100%;
}
.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}
.plyr__menu__container .plyr__control::after {
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
}
.plyr__menu__container .plyr__control--forward::after {
  border-left-color: var(--plyr-menu-arrow-color, hsl(216deg, 15%, 52%));
  right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}
.plyr__menu__container .plyr__control--back {
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(var(--plyr-control-spacing, 10px) * 0.7);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
  position: relative;
  width: calc(100% - (calc(var(--plyr-control-spacing, 10px) * 0.7) * 2));
}
.plyr__menu__container .plyr__control--back::after {
  border-right-color: var(--plyr-menu-arrow-color, hsl(216deg, 15%, 52%));
  left: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--back::before {
  background: var(--plyr-menu-back-border-color, hsl(216deg, 15%, 88%));
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}
.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}
.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before, .plyr__menu__container .plyr__control[role=menuitemradio]::after {
  border-radius: 100%;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(35, 40, 47, 0.1);
}
.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) - 2) * -1);
  overflow: hidden;
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 3.5);
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none; /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  display: block;
  height: calc((var(--plyr-range-thumb-active-shadow-width, 3px) * 2) + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}
.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
}
.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  -webkit-appearance: none; /* stylelint-disable-line */
  margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2 * -1);
}
.plyr--full-ui input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
}
.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
}
.plyr--full-ui input[type=range]::-ms-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  color: transparent;
}
.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background: currentColor;
}
.plyr--full-ui input[type=range]::-ms-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
}
.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}
.plyr--full-ui input[type=range]:focus {
  outline: 0;
}
.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time::before {
  content: "⁄";
  margin-right: var(--plyr-control-spacing, 10px);
}
@media (max-width: calc(768px - 1)) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: var(--plyr-tooltip-radius, 3px);
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-tooltip-color, hsl(216deg, 15%, 34%));
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}
.plyr__tooltip::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}
.plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}
.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}
.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}
.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}
.plyr__progress .plyr__tooltip {
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none; /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}
.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}
.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}
.plyr__volume input[type=range] {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, hsl(216deg, 15%, 34%));
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr--video {
  background: #000;
  overflow: hidden;
}
.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}
@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  border: 0;
  border-radius: 100%;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}
.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}
.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:fullscreen video {
  height: 100%;
}
.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

/* stylelint-disable-next-line */
.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:-webkit-full-screen video {
  height: 100%;
}
.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

/* stylelint-disable-next-line */
.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:-moz-full-screen video {
  height: 100%;
}
.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

/* stylelint-disable-next-line */
.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:-ms-fullscreen video {
  height: 100%;
}
.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}
.plyr--fullscreen-fallback video {
  height: 100%;
}
.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static;
}
.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
.plyr__ads::after {
  background: hsl(216deg, 15%, 16%);
  border-radius: 2px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}
.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin: -var(--plyr-range-track-height, 5px)/2 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: var(--plyr-tooltip-radius, 3px);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}
.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.plyr__preview-thumb::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}
.plyr__preview-thumb__image-container {
  background: hsl(216deg, 15%, 79%);
  border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}
.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
  color: #fff;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  padding: 3px 6px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}
.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}
.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

.grid-helper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  pointer-events: none;
}
.grid-helper__inner {
  height: 100%;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  box-sizing: initial;
  border-right: 1px solid rgba(0, 128, 0, 0.1);
  border-left: 1px solid rgba(0, 128, 0, 0.1);
}
@media (min-width: 76.25em) {
  .grid-helper__inner {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 90.75em) {
  .grid-helper__inner {
    max-width: 1392px;
  }
}
@media (max-width: 76.24em) {
  .grid-helper__inner {
    max-width: calc(100% - 60px);
  }
}
.grid-helper__column {
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: rgba(255, 0, 0, 0.1);
}
.grid-helper__column + .grid-helper__column {
  margin-left: 40px;
}
@media (min-width: 90.75em) {
  .grid-helper__column + .grid-helper__column {
    margin-left: 48px;
  }
}

.u-pull-left {
  float: left;
}

.u-pull-right {
  float: right;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-text-justify {
  text-align: justify;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-clearfix::after {
  clear: both;
  content: "";
  display: table;
}

.u-inline {
  display: inline;
}

.u-inline-block {
  display: inline-block;
}

.u-block {
  display: block;
}

.u-table {
  display: table;
}

.u-cell {
  display: table-cell;
}

.u-va-top {
  vertical-align: top;
}

.u-va-middle {
  vertical-align: middle;
}

.u-va-bottom {
  vertical-align: bottom;
}

.u-hidden {
  display: none;
}

.u-invisible {
  visibility: hidden;
}

.u-hide-text {
  padding: 0; /* 1 */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

.u-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}
.u-visually-hidden--focusable:active, .u-visually-hidden--focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.u-text-hyphenate {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; /* Browser Support */
  hyphens: auto;
}

.u-button-reset {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}
.u-button-reset:focus {
  outline: 0;
}

.u-nobr {
  white-space: nowrap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none; /* Browser Support */
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  width: 100%;
  background-color: #f7f6ec;
}
body:not(.keyboard-user) * {
  outline: none;
}
body .skip-to-content {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
body .skip-to-content .button:not(:focus) {
  opacity: 0;
}
body.keyboard-user .skip-to-content {
  display: block;
}

.site-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Fallback */
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}
.site-wrapper .site-container {
  position: relative;
  flex-grow: 1;
}

.center {
  padding-right: 30px;
  padding-left: 30px;
}
@media (max-width: 51.74em) {
  .center {
    padding-right: 44px;
    padding-left: 44px;
  }
}
@media (min-width: 76.25em) {
  .center {
    max-width: 1220px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 90.75em) {
  .center {
    max-width: 1452px;
  }
}

.svg {
  display: inline-block;
}
.svg svg {
  display: block;
}

.site-group {
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.debug-on .site-group {
  border-color: #e8e8e8;
}
.site-group__label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  display: none;
  background-color: #bbb;
  color: #f8f8f8;
  font-family: "Monaco", monospace;
  font-size: 0.5625rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.debug-on .site-group__label {
  display: block;
}
.debug-on .site-group + .site-group {
  margin-top: -1px;
}

.tns-visually-hidden {
  display: none;
}

.transition-delay--0 {
  transition-delay: 0s !important;
}

.transition-delay--1 {
  transition-delay: 0.125s !important;
}

.transition-delay--2 {
  transition-delay: 0.25s !important;
}

.transition-delay--3 {
  transition-delay: 0.375s !important;
}

.transition-delay--4 {
  transition-delay: 0.5s !important;
}

.transition-delay--5 {
  transition-delay: 0.625s !important;
}

.transition-delay--6 {
  transition-delay: 0.75s !important;
}

.transition-delay--7 {
  transition-delay: 0.875s !important;
}

.transition-delay--8 {
  transition-delay: 1s !important;
}

.transition-delay--9 {
  transition-delay: 1.125s !important;
}

.transition-delay--10 {
  transition-delay: 1.25s !important;
}

html:not(.mobile):not(.ie) .js-in-view .js-title-effect {
  opacity: 0;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(40px);
  opacity: 0;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(0) {
  transition-delay: 0.1s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(1) {
  transition-delay: 0.2s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(2) {
  transition-delay: 0.3s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(3) {
  transition-delay: 0.4s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(4) {
  transition-delay: 0.5s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(5) {
  transition-delay: 0.6s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(6) {
  transition-delay: 0.7s;
}
html:not(.mobile):not(.ie) .js-in-view .js-title-effect > div:nth-child(7) {
  transition-delay: 0.8s;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .js-title-effect.is-ready {
  opacity: 1;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .js-title-effect.is-ready > div {
  transform: translateY(0);
  opacity: 1;
}

.svg-filters {
  position: absolute;
}

.module-separator {
  padding-top: 15px;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* 1 */
  font-size: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

body {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
@media (min-width: 90.75em) {
  body {
    font-family: "FF-Mark-Pro", serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.5555555556;
    letter-spacing: -0.6px;
  }
}

.tag {
  position: relative;
  padding: 5px 8px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 0.625rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.tag::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: #fff;
}
.tag .svg {
  margin-top: -1px;
  fill: currentColor;
  margin-right: 5px;
}

.eyebrow {
  display: block;
  font-size: 0.6875rem;
  line-height: 1.2727272727;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.dropdown {
  position: relative;
}
.dropdown__button {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.dropdown__button .svg--caret-down {
  margin-left: 20px;
  fill: currentColor;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.dropdown__list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  visibility: hidden;
  z-index: 5;
  box-shadow: 0 10px 40px 0 rgba(6, 41, 78, 0.08);
}
.dropdown__list-item {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.75rem;
  letter-spacing: 0.4px;
}
.dropdown__list-item-link {
  position: relative;
  padding: 11px 12px;
  display: block;
  padding-right: 40px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.dropdown__list-item-link .svg--arrow-right {
  position: absolute;
  top: 14px;
  right: 10px;
  fill: currentColor;
}
.dropdown__list-item:not(:last-child) .dropdown__list-item-link {
  border-bottom: 1px solid #d6eaff;
}
.dropdown.is-open .dropdown__button .svg--caret-down {
  transform: rotate(180deg);
}
.dropdown.is-open .dropdown__list {
  visibility: visible;
}
.dropdown--top .dropdown__list {
  top: auto;
  bottom: calc(100% + 5px);
}

.no-touchevents .dropdown:not(.is-open) .dropdown__button:hover:not(.is-open) {
  box-shadow: 0 3px 12px 0 rgba(6, 41, 78, 0.08);
}

.loading-module {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 160px;
  background-image: url("../assets/images/decorations/loading-spinner.gif");
  background-position: center;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  height: 100%;
}

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 900;
}
@media (max-width: 76.24em) {
  .site-header {
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  body.header-sticky .site-header {
    position: fixed;
    top: -117px;
  }
  body.menu-is-open .site-header {
    position: fixed;
  }
  body.header-sticky:not(.header-is-hidden) .site-header, body.header-sticky.menu-is-open .site-header {
    transform: translateY(100%);
  }
}
@media (max-width: 51.74em) {
  body.header-sticky .site-header {
    position: fixed;
    top: -81px;
  }
}
.site-header__bar {
  position: relative;
  padding-right: 50px;
  display: flex;
  border-bottom: 2px solid transparent;
}
@media (max-width: 76.24em) {
  .site-header__bar {
    padding-right: 0;
    min-height: 98px;
    display: flex;
    justify-content: space-between;
  }
  .site-header__bar::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #fff;
    z-index: 2;
  }
  body.header-sticky .site-header__bar, body.menu-is-open .site-header__bar {
    background-color: #fff;
  }
}
@media (max-width: 51.74em) {
  .site-header__bar {
    min-height: 79px;
  }
}
.site-header__logo {
  display: flex;
  align-items: center;
  border-right: 2px solid transparent;
}
@media (min-width: 76.25em) {
  .site-header__logo {
    max-height: 122px;
  }
}
@media (min-width: 67.75em) and (max-width: 76.24em) {
  .site-header__logo {
    max-height: 113px;
  }
}
.site-header__logo img {
  display: block;
  width: 220px;
}
@media (max-width: 80em) {
  .site-header__logo img {
    width: 160px;
  }
}
@media (max-width: 51.74em) {
  .site-header__logo img {
    width: 140px;
  }
}
@media (max-width: 76.24em) {
  .site-header__logo {
    position: relative;
    border: 0;
    z-index: 3;
  }
  body.submenu-is-open .site-header__logo {
    visibility: hidden;
  }
}
.site-header__menu-button {
  padding: 0;
  height: 113px;
  width: 113px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  border-left: 2px solid transparent;
}
@media (min-width: 76.25em) {
  .site-header__menu-button {
    display: none;
  }
}
@media (max-width: 76.24em) {
  .site-header__menu-button {
    position: relative;
    z-index: 3;
  }
}
@media (max-width: 51.74em) {
  .site-header__menu-button {
    height: 79px;
    width: 79px;
  }
}
.site-header__menu-button-bars {
  height: 17px;
  width: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.site-header__menu-button-bar {
  height: 3px;
  width: 100%;
  display: block;
  flex-shrink: 0;
  border-radius: 1.5px;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-header__menu-button-bar:nth-child(1) {
  transform-origin: left center;
}
.site-header__menu-button-bar:nth-child(3) {
  transform-origin: left center;
}
body.menu-is-open .site-header__menu-button-bar:nth-child(1) {
  transform: translate(2px, 1px) rotate(45deg);
}
body.menu-is-open .site-header__menu-button-bar:nth-child(2) {
  opacity: 0;
}
body.menu-is-open .site-header__menu-button-bar:nth-child(3) {
  transform: translate(2px, -1px) rotate(-45deg);
}
.site-header__desktop-submenu-backdrop {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1000px;
  width: 100%;
  content: "";
  z-index: -1;
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 76.24em) {
  .site-header__desktop-submenu-backdrop {
    display: none;
  }
}
body.submenu-is-open .site-header__desktop-submenu-backdrop {
  opacity: 1;
}
.site-header__submenu-close-button {
  position: absolute;
  top: 0;
  left: 30px;
  padding: 0;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  visibility: hidden;
  z-index: 3;
}
@media (max-width: 76.24em) {
  body.submenu-is-open .site-header__submenu-close-button {
    visibility: visible;
  }
}
.site-header__submenu-close-button .svg {
  fill: currentColor;
  margin-right: 5px;
}
.site-header__nav {
  padding-top: 15px;
  padding-left: 40px;
  width: 100%;
}
@media (max-width: 76.24em) {
  .site-header__nav {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 113px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  body.menu-is-open .site-header__nav {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-width: 51.74em) {
  .site-header__nav {
    padding-top: 79px;
  }
}
@media (max-width: 76.24em) {
  .site-header__nav-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  body.menu-is-open .site-header__nav-wrapper {
    visibility: visible;
  }
}
.site-header__nav-top, .site-header__nav-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 76.24em) {
  .site-header__nav-bottom {
    padding-bottom: 34px;
  }
}
.site-header__nav-top {
  margin-bottom: 23px;
}
@media (max-width: 76.24em) {
  .site-header__nav-top {
    flex-direction: column-reverse;
  }
}
.site-header__dropdown .dropdown__button {
  position: relative;
  padding: 7px 14px;
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 200px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  letter-spacing: 0.4px;
}
@media (max-width: 37.49em) {
  .site-header__dropdown .dropdown__button {
    width: 100%;
    background-color: #fff;
  }
}
.site-header__dropdown .dropdown__button .svg {
  flex-shrink: 0;
  fill: currentColor;
}
.site-header__dropdown .dropdown__button .svg.svg--caret-down {
  margin-left: auto;
}
.site-header__dropdown .dropdown__button .svg:not(.svg--caret-down) {
  margin-right: 10px;
}
.site-header__secondary-nav, .site-header__primary-nav {
  display: flex;
  align-items: center;
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav, .site-header__primary-nav {
    width: 100%;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:first-child {
    flex-direction: column;
    padding-top: 46px;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) {
    margin-top: 18px;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) .site-header__secondary-nav-item-button {
    position: relative;
    padding: 14px 16px 14px 38px;
    flex-shrink: 0;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: #fff;
  }
}
@media (max-width: 76.24em) and (max-width: 37.49em) {
  .site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) .site-header__secondary-nav-item-button {
    background: transparent;
    border: 0 !important;
  }
}
.site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) .site-header__secondary-nav-item-button .svg--blank {
  position: absolute;
  top: 16px;
  left: 13px;
}
@media (min-width: 76.25em) {
  .site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) .site-header__secondary-nav-item-button .svg--blank {
    display: none;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:last-child {
    flex-direction: column-reverse;
    padding-bottom: 40px;
    border-bottom: 1px solid transparent;
  }
}
@media (max-width: 37.49em) {
  .site-header__secondary-nav:last-child .site-header__secondary-nav-item-button,
  .site-header__secondary-nav:last-child .site-header__secondary-nav-item .button {
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:last-child .site-header__secondary-nav-item:not(:last-child) {
    margin-top: 18px;
  }
}
.site-header__secondary-nav-item {
  display: flex;
  align-items: center;
}
@media (max-width: 37.49em) {
  .site-header__secondary-nav-item {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .site-header__secondary-nav-item .button {
    width: 100%;
  }
}
@media (min-width: 76.25em) {
  .site-header__secondary-nav-item:not(:last-child) {
    margin-right: 30px;
  }
}
.site-header__secondary-nav-item-select {
  padding: 7px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-header__secondary-nav-item-select .svg {
  fill: currentColor;
  margin-right: 8px;
}
.site-header__secondary-nav-item-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-header__secondary-nav-item-button .svg {
  margin-right: 8px;
  fill: currentColor;
}
.site-header__secondary-nav-item-button + .site-header__secondary-nav-item-button {
  margin-left: 5px;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item {
    width: 100%;
  }
}
.site-header__primary-nav-item:not(:last-child) .site-header__primary-nav-item-button {
  padding-right: 12px;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item:not(:last-child) .site-header__primary-nav-item-button {
    padding-right: 0;
  }
}
.site-header__primary-nav-item:not(:last-child) + .site-header__primary-nav-item .site-header__primary-nav-item-button {
  padding-left: 12px;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item:not(:last-child) + .site-header__primary-nav-item .site-header__primary-nav-item-button {
    padding-left: 0;
  }
}
.site-header__primary-nav-item:first-child .site-header__primary-nav-item-button::before {
  left: 0;
  width: calc(100% - 12px);
}
.site-header__primary-nav-item:last-child .site-header__primary-nav-item-button::before {
  width: calc(100% - 12px);
}
.site-header__primary-nav-item-button {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 0;
  background: transparent;
  z-index: 2;
  text-align: left;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button {
    width: 100%;
  }
}
.site-header__primary-nav-item-button::before, .site-header__primary-nav-item-button:after {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 18px;
  content: "";
  transform: rotate(45deg);
  pointer-events: none;
  z-index: 5;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button::before, .site-header__primary-nav-item-button:after {
    display: none;
  }
}
.site-header__primary-nav-item-button::before {
  left: -65px;
  transform: rotate(-30deg);
}
.site-header__primary-nav-item-button::after {
  right: -65px;
  transform: rotate(30deg);
}
.site-header__primary-nav-item-button-label {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button-label {
    padding-bottom: 0;
  }
}
.site-header__primary-nav-item-button-label::before {
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button-label::before {
    display: none;
  }
}
.site-header__primary-nav-item-button .svg {
  margin-left: 8px;
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button .svg--caret-down {
    display: none;
  }
}
.site-header__primary-nav-item-button .svg--arrow-right {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
}
@media (min-width: 76.25em) {
  .site-header__primary-nav-item-button .svg--arrow-right {
    display: none;
  }
}
.no-touchevents .site-header__primary-nav-item-button:not(button):focus .site-header__primary-nav-item-button-label::before, .no-touchevents .site-header__primary-nav-item-button:not(button):hover .site-header__primary-nav-item-button-label::before {
  opacity: 1;
}
.site-header__primary-nav-item.is-current .site-header__primary-nav-item-button-label::before {
  opacity: 1;
}
@media (min-width: 76.25em) {
  .site-header__primary-nav-item.submenu-is-open .site-header__submenu {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.3s 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
@media (max-width: 76.24em) {
  body.menu-is-open .site-header__primary-nav-item.submenu-is-open .site-header__submenu {
    visibility: visible;
    transform: translateX(-100%);
  }
}
@media (min-width: 76.25em) {
  .site-header__primary-nav-item.submenu-is-open .site-header__primary-nav-item-button {
    z-index: 4;
  }
  .site-header__primary-nav-item.submenu-is-open .site-header__primary-nav-item-button::before, .site-header__primary-nav-item.submenu-is-open .site-header__primary-nav-item-button:after {
    pointer-events: auto;
    z-index: 6;
  }
}
.site-header__primary-nav-item.submenu-is-open .site-header__primary-nav-item-button .site-header__primary-nav-item-button-label::before {
  opacity: 1;
}
.site-header__submenu {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  height: auto;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 76.24em) {
  .site-header__submenu {
    position: fixed;
    top: 0;
    left: 100%;
    padding-top: 113px;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    z-index: 3;
    opacity: 1;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: auto;
  }
}
@media (max-width: 51.74em) {
  .site-header__submenu {
    padding-top: 79px;
  }
}
.site-header__submenu-wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-top: 65px;
  padding-bottom: 55px;
  display: flex;
  max-width: 960px;
}
@media (max-width: 76.24em) {
  .site-header__submenu-wrapper {
    padding: 55px 30px;
    max-width: 100%;
    flex-direction: column;
  }
}
.site-header__submenu-content {
  width: 100%;
  max-width: 300px;
}
@media (min-width: 76.25em) {
  .site-header__submenu-content {
    margin-right: 100px;
  }
}
@media (max-width: 76.24em) {
  .site-header__submenu-content {
    padding-bottom: 13px;
  }
}
@media (max-width: 51.74em) {
  .site-header__submenu-content {
    max-width: 560px;
  }
}
.site-header__submenu-content-text {
  margin-top: 14px;
}
.site-header__submenu-content .button  {
  margin-top: 18px;
}
.site-header__submenu-nav {
  width: 100%;
  max-width: 260px;
  margin-top: -17px;
}
@media (max-width: 76.24em) {
  .site-header__submenu-nav {
    margin-top: 40px;
  }
}
@media (max-width: 51.74em) {
  .site-header__submenu-nav {
    max-width: 100%;
  }
}
@media (min-width: 76.25em) {
  .site-header__submenu-nav:not(:last-child) {
    margin-right: 40px;
  }
}
.site-header__submenu-nav-item {
  display: inline-block;
  width: 100%;
}
.site-header__submenu-nav-item:not(:last-child) {
  border-bottom: 1px solid transparent;
}
.site-header__submenu-nav-item-label {
  padding-top: 11px;
  padding-bottom: 11px;
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  display: block;
  font-size: 0.625rem;
  line-height: 1.4;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.site-header__submenu-nav-item-button {
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  display: block;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-header__submenu-nav--single {
  max-width: 560px;
  column-count: 2;
  column-gap: 40px;
}
@media (max-width: 37.49em) {
  .site-header__submenu-nav--single {
    column-count: 1;
  }
}
@media (max-width: 76.24em) {
  .site-header .desktop-only {
    display: none;
  }
}
.site-header--minimal {
  background-color: #fff;
}
.site-header--minimal .site-header__bar {
  padding-right: 0;
}
.site-header--minimal .site-header__nav {
  padding-top: 45px;
  padding-right: 48px;
  padding-bottom: 44px;
  text-align: right;
}

.site-footer {
  padding-top: 100px;
  padding-bottom: 95px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 51.74em) {
  .site-footer {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.site-footer__row {
  display: flex;
  width: 100%;
}
@media (max-width: 51.74em) {
  .site-footer__row {
    flex-wrap: wrap;
  }
}
@media (max-width: 37.49em) {
  .site-footer__row {
    flex-direction: column;
  }
}
.site-footer__row:last-child {
  /* margin-top: 85px;*/
  justify-content: space-between;
}
@media (max-width: 51.74em) {
  .site-footer__row:last-child {
    margin-top: 45px;
  }
}
@media (max-width: 37.49em) {
  .site-footer__row:last-child {
    margin-top: 22px;
  }
}
.site-footer__column {
  width: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 37.5em) and (max-width: 51.74em) {
  .site-footer__column {
    width: calc(50% - 18px);
    flex-shrink: 0;
  }
  .site-footer__column:nth-child(odd) {
    margin-right: 36px;
  }
  .site-footer__column:nth-child(n+3) {
    margin-top: 40px;
  }
}
@media (max-width: 37.49em) {
  .site-footer__column {
    width: 100%;
  }
  .site-footer__column:nth-child(1) {
    margin-bottom: 40px;
  }
  .site-footer__column:nth-child(2) {
    margin-bottom: 20px;
  }
  .site-footer__column:nth-child(3), .site-footer__column:nth-child(4), .site-footer__column:nth-child(5) {
    border-bottom: 2px solid transparent;
  }
  .site-footer__column:last-child {
    border-bottom: 0;
  }
}
@media (min-width: 90.75em) {
  .site-footer__column {
    width: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 37.5em) {
  .site-footer__column-toggler {
    display: none;
  }
}
@media (max-width: 37.49em) {
  .site-footer__column-toggler {
    padding: 20px 0;
    height: auto;
    width: 100%;
    position: relative;
    font-family: "FF-Mark", serif;
    font-weight: 400;
    font-style: normal;
    background: transparent;
    border: 0;
    text-align: left;
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: -0.4px;
  }
  .site-footer__column-toggler .svg {
    position: absolute;
    top: calc(50% - 3px);
    right: 0;
    fill: currentColor;
  }
  .site-footer__column-toggler ~ .site-footer__eyebrow,
  .site-footer__column-toggler ~ .site-footer__secondary-nav {
    display: none;
  }
  .site-footer__column-toggler.is-active .svg {
    transform: rotate(180deg);
  }
  .site-footer__column-toggler.is-active ~ .site-footer__secondary-nav {
    display: block;
  }
}
@media (min-width: 51.75em) {
  .site-footer__column:first-child, .site-footer__column:nth-child(2) {
    width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  }
}
@media (min-width: 90.75em) {
  .site-footer__column:first-child, .site-footer__column:nth-child(2) {
    width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
@media (min-width: 51.75em) {
  .site-footer__column:not(:last-child) {
    margin-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .site-footer__column:not(:last-child) {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.site-footer__primary-nav-item:first-child .site-footer__primary-nav-item-button {
  padding-top: 0;
}
.site-footer__primary-nav-item-button {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
  color: #fff;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 37.49em) {
  .site-footer__secondary-nav {
    padding-bottom: 20px;
  }
}
.site-footer__secondary-nav-item:first-child .site-footer__secondary-nav-item-button {
  padding-top: 0;
}
.site-footer__secondary-nav-item-button {
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-footer__secondary-nav-item-button .svg {
  opacity: 0.6;
  margin-right: 8px;
  min-width: 16px;
  fill: currentColor;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-footer__secondary-nav-item-button .svg--facebook {
  margin-left: 2px;
  margin-right: 6px;
}
.no-touchevents .site-footer__secondary-nav-item-button:focus .svg, .no-touchevents .site-footer__secondary-nav-item-button:hover .svg {
  opacity: 1;
}
.site-footer__eyebrow {
  display: block;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.site-footer__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.site-footer__metas {
  display: flex;
  align-items: center;
}
@media (max-width: 37.49em) {
  .site-footer__metas {
    display: inline-flex;
    flex-wrap: wrap;
  }
}
.site-footer__metas-item, .site-footer__metas-item-button {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
@media (max-width: 37.49em) {
  .site-footer__metas-item {
    margin-right: 28px;
  }
  .site-footer__metas-item:first-child {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 37.49em) {
  .site-footer__metas-item--copyright {
    margin-bottom: 40px;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
}
.site-footer__metas-item-button {
  text-decoration: underline;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site-footer__metas-item + .site-footer__metas-item {
  margin-left: 30px;
}
@media (max-width: 37.49em) {
  .site-footer__metas-item + .site-footer__metas-item {
    margin-left: 0;
  }
}
.site-footer__dropdown .dropdown__button {
  padding: 13px 29px 13px 24px;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  border: 0;
}
@media (max-width: 37.49em) {
  .site-footer__dropdown .dropdown__button {
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    justify-content: space-between;
  }
}
.site-footer__partners {
  display: flex;
  align-items: flex-end;
  margin-top: -20px;
}
@media (max-width: 51.74em) {
  .site-footer__partners {
    margin-top: 30px;
    width: 100%;
  }
}
@media (max-width: 37.49em) {
  .site-footer__partners {
    margin-top: 0;
  }
}
.site-footer__partners-item {
  display: block;
}
.site-footer__partners-item:not(:last-child) {
  margin-right: 40px;
}
.site-footer__partners-item img {
  display: block;
  max-width: 100px;
  max-height: 60px;
}

.header-inner {
  width: 100%;
  text-align: center;
}
.header-inner__eyebrow {
  margin-bottom: 20px;
}
.header-inner__tags {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.header-inner__text {
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: -0.4px;
}
.header-inner__title {
  font-size: 3.75rem;
  line-height: 0.9666666667;
  letter-spacing: -1.3px;
}
@media (min-width: 90.75em) {
  .header-inner__title {
    font-size: 4.25rem;
    line-height: 0.9705882353;
    letter-spacing: -1.5px;
  }
}
@media (max-width: 51.74em) {
  .header-inner__title {
    padding-right: 30px;
    padding-left: 30px;
    font-size: 3rem;
    letter-spacing: -1px;
  }
  .header-inner__title br {
    display: none;
  }
}
@media (max-width: 37.49em) {
  .header-inner__title {
    font-size: 2.75rem;
    letter-spacing: -0.8px;
  }
}
@media (max-width: 28.115em) {
  .header-inner__title {
    padding-right: 0;
    padding-left: 0;
  }
}
.header-inner__metas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: -0.4px;
}
@media (max-width: 51.74em) {
  .header-inner__metas {
    font-size: 1.125rem;
    line-height: 1.3;
  }
}
.header-inner__metas li:not(:last-child) {
  display: flex;
  align-items: center;
}
.header-inner__metas li:not(:last-child)::after {
  height: 4px;
  width: 4px;
  margin-right: 12px;
  margin-left: 12px;
  content: "";
  display: block;
  border-radius: 50%;
  flex-shrink: 0;
}
.header-inner__form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
  margin-right: -12px;
  margin-left: -12px;
}
.header-inner__form .form-filter {
  flex: 1;
  padding-right: 12px;
  padding-left: 12px;
}
.header-inner__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
@media (max-width: 51.74em) {
  .header-inner__buttons {
    flex-direction: column;
  }
}
@media (min-width: 51.75em) {
  .header-inner__buttons {
    margin-right: -10px;
    margin-left: -10px;
  }
}
.header-inner__buttons .button-icon,
.header-inner__buttons .button-icon .button-icon__label, .header-inner__buttons .button-icon::before,
.header-inner__buttons .button-icon .svg--arrow-right {
  transition-delay: 0.4s;
}
@media (min-width: 51.75em) {
  .header-inner__buttons li {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 51.74em) {
  .header-inner__buttons li:not(:last-child) {
    margin-bottom: 20px;
  }
}

html:not(.mobile):not(.ie) .js-in-view .header-inner__eyebrow, html:not(.mobile):not(.ie) .js-in-view .header-inner__text {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.4s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
html:not(.mobile):not(.ie) .js-in-view .header-inner__text {
  transition-delay: 0.5s;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .header-inner__eyebrow, html:not(.mobile):not(.ie) .js-in-view.in-view .header-inner__text {
  opacity: 1;
  transform: translateY(0px);
}

.header {
  position: relative;
  padding-top: 220px;
  padding-bottom: 120px;
  z-index: 8;
}
@media (max-width: 67.74em) {
  .header {
    padding-top: 200px;
  }
}
@media (max-width: 51.74em) {
  .header {
    padding-top: 170px;
  }
}
.header-inner {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (7 * 40px));
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 51.74em) {
  .header-inner {
    max-width: 560px;
  }
}
@media (min-width: 90.75em) {
  .header-inner {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (7 * 48px));
  }
}
.header-inner__text {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 90.75em) {
  .header-inner__text {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
@media (max-width: 51.74em) {
  .header-inner__text {
    max-width: 400px;
  }
  .header-inner__text br {
    display: none;
  }
}
.header-filters {
  max-width: calc((8 * calc((1160px - 440px) / 12)) + (7 * 40px));
  margin-top: 65px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 51.74em) {
  .header-filters {
    max-width: 288px;
  }
}
@media (min-width: 90.75em) {
  .header-filters {
    max-width: calc((8 * calc((1392px - 528px) / 12)) + (7 * 48px));
  }
}
.header .module-image {
  z-index: -1;
}

.header-nav {
  position: absolute;
  top: 123px;
  left: 0;
  height: 74px;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
  overflow: auto;
}
@media (max-width: 67.74em) {
  .header-nav {
    height: 61px;
  }
}
@media (max-width: 51.74em) {
  .header-nav {
    padding-right: 44px;
    padding-left: 44px;
  }
}
@media (min-width: 76.25em) {
  .header-nav {
    padding-right: 50px;
    padding-left: 35px;
  }
}
@media (max-width: 72.5em) {
  .header-nav {
    top: 113px;
  }
}
@media (max-width: 51.74em) {
  .header-nav {
    top: 79px;
  }
}
.header-nav.has-menu {
  padding-right: 0px;
}
@media (max-width: 67.74em) {
  .header-nav.has-menu + .center {
    margin-top: 30px;
  }
}
@media (min-width: 67.75em) {
  .header-nav.has-menu + .center {
    margin-top: 55px;
  }
}
.header-nav__page-name {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: -0.4px;
}
.header-nav__menu {
  padding-right: 30px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
@media (max-width: 51.74em) {
  .header-nav__menu {
    padding-right: 44px;
  }
}
@media (min-width: 76.25em) {
  .header-nav__menu {
    padding-right: 50px;
  }
}
.header-nav__menu:not(:first-child) {
  margin-left: 40px;
}
.header-nav__menu-item:not(:first-child) {
  margin-left: 24px;
}
.header-nav__menu-item .svg {
  fill: currentColor;
}
.header-nav__menu-item .svg:first-child {
  margin-right: 6px;
}
.header-nav__menu-item .svg:last-child {
  margin-left: 6px;
}
.header-nav__menu-item a:not(.button) {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  letter-spacing: -0.35px;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 51.74em) {
  .header-nav__menu-item a:not(.button) {
    letter-spacing: -0.2px;
  }
}

.header-filters__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -12px;
  margin-left: -12px;
}
@media (max-width: 51.74em) {
  .header-filters__list {
    flex-direction: column;
  }
}
.header-filters__item {
  flex: 1;
  padding-right: 12px;
  padding-left: 12px;
}
.header-filters__item:first-child {
  z-index: 10;
}
.header-filters__item:nth-child(2) {
  z-index: 9;
}
.header-filters__item:nth-child(3) {
  z-index: 8;
}
.header-filters__item:nth-child(4) {
  z-index: 7;
}
@media (max-width: 51.74em) {
  .header-filters__item {
    width: 100%;
  }
  .header-filters__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.footer-join {
  padding-top: 60px;
}
.footer-join__inner {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  z-index: 0;
}
.footer-join__inner .module-header {
  max-width: calc((8 * calc((1160px - 440px) / 12)) + (7 * 40px));
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media (max-width: 51.74em) {
  .footer-join__inner .module-header {
    max-width: 560px;
  }
}
@media (min-width: 90.75em) {
  .footer-join__inner .module-header {
    max-width: calc((8 * calc((1392px - 528px) / 12)) + (7 * 48px));
  }
}
@media (min-width: 90.75em) {
  .footer-join__inner .module-header__title {
    font-size: 3.25rem;
  }
}
.footer-join__inner::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 152px;
  width: 100%;
  z-index: -1;
  background-position: 50%;
  background-repeat: repeat;
}

.button {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0 none;
  cursor: pointer;
  border-radius: 0;
  background: none;
  text-align: left;
  text-decoration: none;
}
.button[disabled] {
  pointer-events: none;
}

.button-back {
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: -0.4px;
}
.button-back span + span {
  margin-left: 10px;
}
.button-back .svg {
  fill: currentColor;
  margin-top: 1px;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.no-touchevents .button-back:hover .svg, .no-touchevents .button-back:focus .svg {
  transform: translateX(-5px);
}

.button-icon {
  position: relative;
  height: auto;
  width: auto;
  position: relative;
  padding: 9px 8px 9px 22px;
  min-height: 52px;
  display: inline-flex;
  align-items: center;
  border-radius: 26px;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: -0.3px;
  transition: clip-path 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  border: 3px solid transparent;
}
.button-icon .button-icon__label {
  transition: opacity 1.1s cubic-bezier(0.19, 1, 0.22, 1);
}
.button-icon::before {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-radius: 26px;
  z-index: -1;
  transition: transform 1.15s cubic-bezier(0.19, 1, 0.22, 1);
}
.button-icon span:not(.svg) {
  display: flex;
  align-items: center;
}
.button-icon span:not(.svg)::after {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  will-change: width;
  transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-icon .svg {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-left: 18px;
  border-radius: 50%;
  transition: transform 1.1s cubic-bezier(0.19, 1, 0.22, 1), fill 1.1s cubic-bezier(0.19, 1, 0.22, 1);
}
.button-icon .svg--play svg {
  margin-left: 3px;
}
.button-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  border-radius: 26px;
  background-color: transparent;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 67.75em) {
  .button-icon--l {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 1.25rem;
    border-radius: 30px;
  }
  .button-icon--l::before {
    border-radius: 30px;
  }
  .button-icon--l .svg {
    height: 40px;
    width: 40px;
  }
}
.no-touchevents .button-icon:hover span:not(.svg)::after, .no-touchevents .button-icon:focus span:not(.svg)::after {
  width: 8px;
}
.no-touchevents .button-icon:active::after {
  opacity: 1;
}
html:not(.mobile):not(.ie) .js-in-view .button-icon {
  clip-path: inset(50% 50%);
}
html:not(.mobile):not(.ie) .js-in-view .button-icon .button-icon__label {
  opacity: 0;
}
html:not(.mobile):not(.ie) .js-in-view .button-icon::before {
  transform: scale(0);
}
html:not(.mobile):not(.ie) .js-in-view .button-icon .svg--arrow-right {
  transform: translateX(-30px);
}
html:not(.mobile):not(.ie) .js-in-view:not(.in-view) .button-icon .svg {
  fill: transparent;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .button-icon {
  clip-path: inset(0% 0%);
}
html:not(.mobile):not(.ie) .js-in-view.in-view .button-icon .button-icon__label {
  opacity: 1;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .button-icon::before {
  transform: scale(1);
}
html:not(.mobile):not(.ie) .js-in-view.in-view .button-icon .svg--arrow-right {
  transform: translateX(0px);
}

.button-pill {
  position: relative;
  height: 40px;
  width: auto;
  padding: 16px 23px;
  border-radius: 30px;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-pill::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  border-radius: 30px;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-pill--bordered {
  padding-top: 14px;
  padding-bottom: 14px;
  border: 2px solid transparent;
}
.button-pill--bordered::after {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}
.no-touchevents .button-pill:active::after {
  opacity: 1;
}

.button-line {
  display: inline-flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  letter-spacing: 0.3px;
}
.button-line > span:not(.svg) {
  position: relative;
  padding-bottom: 12px;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-line > span:not(.svg)::before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100%;
  content: "";
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-line > span + span {
  margin-left: 10px;
}
.button-line .svg {
  margin-top: -9px;
}
.button-line--l {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.2px;
}
@media (max-width: 67.74em) {
  .button-line--l {
    font-size: 1rem;
    letter-spacing: -0.4px;
  }
}
.button-line--m {
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
}
.button-line--m span:not(.svg) {
  padding-bottom: 9px;
}
.button-line--m .svg {
  margin-top: -5px;
}
.button-line--s {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  letter-spacing: -0.2px;
}
.button-line--s span:not(.svg) {
  padding-bottom: 5px;
}
.button-line--s span:not(.svg)::before {
  height: 3px;
}
.button-line--s .svg {
  margin-top: -3px;
}
.no-touchevents .button-line:hover > span:not(.svg), .no-touchevents .button-line:focus > span:not(.svg) {
  transform: translateY(-4px);
}
.no-touchevents .button-line:hover > span:not(.svg)::before, .no-touchevents .button-line:focus > span:not(.svg)::before {
  transform: translateY(4px);
}

.button-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 3px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-circle .svg {
  transition: fill 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.button-circle--l {
  width: 72px;
  height: 72px;
}

.module-form__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: -20px;
  margin-left: -20px;
}
@media (min-width: 90.75em) {
  .module-form__row {
    margin-right: -24px;
    margin-left: -24px;
  }
}
@media (max-width: 51.74em) {
  .module-form__row {
    flex-direction: column;
  }
}
.module-form__row .module-form__radio,
.module-form__row .module-form__field,
.module-form__row .module-form__checkbox {
  flex: 1;
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 37.5em) {
  .module-form__row .module-form__radio .payment-methods,
  .module-form__row .module-form__field .payment-methods,
  .module-form__row .module-form__checkbox .payment-methods {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 51.74em) {
  .module-form__row .module-form__radio,
  .module-form__row .module-form__field,
  .module-form__row .module-form__checkbox {
    width: 100%;
  }
  .module-form__row .module-form__radio:not(:first-child),
  .module-form__row .module-form__field:not(:first-child),
  .module-form__row .module-form__checkbox:not(:first-child) {
    margin-top: 18px;
  }
}
@media (min-width: 90.75em) {
  .module-form__row .module-form__radio,
  .module-form__row .module-form__field,
  .module-form__row .module-form__checkbox {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.module-form__row + .module-form__row,
.module-form__row + .module-form__field:not(.module-form__field--no-space) {
  margin-top: 32px;
}
@media (max-width: 51.74em) {
  .module-form__row + .module-form__row,
  .module-form__row + .module-form__field:not(.module-form__field--no-space) {
    margin-top: 18px;
  }
}
.module-form__row .module-form__field .module-form__checkbox {
  padding-bottom: 5px;
}
.module-form__row .module-form__field .form-group ul li {
  padding-left: 0px;
}
.module-form__row .module-form__field .form-group ul li:before {
  background-color: transparent;
}
.module-form__row .module-form__inner .text-danger, .module-form__row .module-form__field .text-danger {
  color: red;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month { /*
/*rtl:begin:ignore*/
  left: 0;
} /*
/*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month { /*
/*rtl:begin:ignore*/
  right: 0;
} /*
/*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-days,
.flatpickr-weekdays,
.flatpickr-calendar,
.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-weekdays {
  margin-bottom: 5px;
}

.flatpickr-days .dayContainer {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
}

.flatpickr-calendar,
.flatpickr-calendar.inline {
  margin-top: 15px;
  padding: 20px 33px 18px;
  top: 100% !important;
  left: 50% !important;
  border-radius: 16px;
  font-size: initial;
  line-height: initial;
  min-width: 340px;
  transform: translateX(-50%);
}
.flatpickr-calendar.arrowTop::after, .flatpickr-calendar.arrowTop::before, .flatpickr-calendar.arrowLeft::after, .flatpickr-calendar.arrowLeft::before,
.flatpickr-calendar.inline.arrowTop::after,
.flatpickr-calendar.inline.arrowTop::before,
.flatpickr-calendar.inline.arrowLeft::after,
.flatpickr-calendar.inline.arrowLeft::before {
  content: none;
}
.flatpickr-calendar.animate.open,
.flatpickr-calendar.inline.animate.open {
  animation: none;
}
.flatpickr-calendar.is-visible,
.flatpickr-calendar.inline.is-visible {
  display: block;
}

.flatpickr-months,
.flatpickr-innerContainer {
  max-width: 294px;
  margin-right: auto;
  margin-left: auto;
}

.flatpickr-months {
  align-items: center;
  margin-bottom: 10px;
}
.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flatpickr-months .flatpickr-month {
  fill: initial;
  height: auto;
  color: initial;
  overflow: visible;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  height: 34px;
  width: 34px;
  padding: 0;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 6.4px;
  height: 10.3px;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: flex;
  opacity: 0;
  pointer-events: none;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: initial;
}

.flatpickr-current-month {
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  width: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  letter-spacing: -0.5px;
}
.flatpickr-current-month span.cur-month {
  margin-left: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.flatpickr-current-month span.cur-month:hover {
  background-color: transparent;
}
.flatpickr-current-month .numInputWrapper {
  display: none;
}

.flatpickr-day {
  width: 40px;
  height: 40px;
  border: 0 none;
  border-radius: 50%;
  font-size: 0.9375rem;
  line-height: 40px;
  letter-spacing: -0.8px;
}
.flatpickr-day.today, .flatpickr-day.selected, .flatpickr-day:hover, .flatpickr-day:focus {
  border-color: transparent;
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  opacity: 0;
  pointer-events: none;
}

span.flatpickr-weekday {
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  letter-spacing: -0.4;
}

.module-form__list .module-form__radio + .module-form__radio,
.module-form__list .module-form__radio + .module-form__checkbox,
.module-form__list .module-form__checkbox + .module-form__radio,
.module-form__list .module-form__checkbox + .module-form__checkbox {
  margin-top: 24px;
}

.module-form__field label,
.module-form__radio label,
.module-form__checkbox label {
  display: block;
  margin-bottom: 12px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  letter-spacing: -0.5px;
  text-align: left;
}
.module-form__field label a,
.module-form__radio label a,
.module-form__checkbox label a {
  text-decoration: underline;
}
.module-form__field small,
.module-form__radio small,
.module-form__checkbox small {
  margin-top: 14px;
  font-size: 0.8125rem;
  line-height: 1.2307692308;
  letter-spacing: -0.4px;
}

select {
  background-color: #fff;
  appearance: none;
}
select::-ms-expand {
  display: none;
}

.module-form__field {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.module-form__field > *:not(.tooltip) {
  width: 100%;
}
.module-form__field textarea {
  min-height: 260px;
  resize: vertical;
}
.module-form__field input[type=text],
.module-form__field input[type=email],
.module-form__field input[type=password],
.module-form__field textarea {
  -webkit-appearance: none;
}
.module-form__field .payment-methods {
  position: absolute;
  top: 2px;
  right: 0;
  justify-content: flex-end;
}
@media (max-width: 37.49em) {
  .module-form__field .payment-methods {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 10px;
  }
}
.module-form__field .tooltip {
  position: absolute;
  top: 2px;
  right: 24px;
}
@media (max-width: 51.74em) {
  .module-form__field .tooltip {
    right: 20px;
  }
}
.module-form__field .module-form__radio--s,
.module-form__field .module-form__checkbox--s,
.module-form__field .module-form__checkbox-radio--s {
  margin-top: 14px;
  padding-right: 0;
  padding-left: 0;
}
.module-form__field .module-form__input {
  height: 65px;
  width: 100%;
  padding: 21px 24px;
  border-radius: 12px;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-form__field .module-form__input:disabled {
  background-color: #fff;
  opacity: 1;
}
.module-form__field .module-form__inner {
  position: relative;
}
.module-form__field .module-form__inner::after {
  position: absolute;
  top: 50%;
  right: 30px;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 10px auto;
}
.module-form__field-link {
  font-size: 0.8125rem;
  line-height: 16px;
  letter-spacing: -0.4px;
}
.module-form__field .module-form__password {
  position: relative;
}
.module-form__field .module-form__password-show {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 20px;
  padding-left: 10px;
  height: 100%;
  width: auto;
  background: transparent;
  border: 0;
}
.module-form__field--date .module-form__inner .module-form__input {
  padding-right: 75px;
}
.module-form__field--date .module-form__inner::after {
  height: 20px;
  width: 18px;
  margin-top: -10px;
  content: "";
  background-size: 18px auto;
}
.module-form__field--narrow {
  max-width: 50%;
}
@media (max-width: 51.74em) {
  .module-form__field--narrow {
    max-width: 100%;
  }
}

.module-form__select {
  position: relative;
}
.module-form__select .module-form__input {
  padding-right: 60px;
}
.module-form__select::after {
  position: absolute;
  top: 50%;
  right: 30px;
  height: 5px;
  width: 10px;
  margin-top: -3px;
  content: "";
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 10px auto;
  pointer-events: none;
}

.module-form__radio label,
.module-form__checkbox label,
.module-form__checkbox-radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 36px;
  line-height: 24px;
}
.module-form__radio label::after, .module-form__radio label::before,
.module-form__checkbox label::after,
.module-form__checkbox label::before,
.module-form__checkbox-radio label::after,
.module-form__checkbox-radio label::before {
  content: "";
}
.module-form__radio label::before,
.module-form__checkbox label::before,
.module-form__checkbox-radio label::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid transparent;
  background-color: #fff;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.module-form__radio label::after,
.module-form__checkbox label::after,
.module-form__checkbox-radio label::after {
  opacity: 0;
  transition: opacity 0.3s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body.keyboard-user .module-form__radio input:focus + label,
body.keyboard-user .module-form__checkbox input:focus + label,
body.keyboard-user .module-form__checkbox-radio input:focus + label {
  outline: auto 1px -webkit-focus-ring-color;
}
.module-form__radio input:checked + label::after,
.module-form__checkbox input:checked + label::after,
.module-form__checkbox-radio input:checked + label::after {
  opacity: 1;
}
.module-form__radio--s label,
.module-form__checkbox--s label,
.module-form__checkbox-radio--s label {
  padding-left: 24px;
  font-size: 0.8125rem;
  line-height: 16px;
  letter-spacing: -0.4px;
}
.module-form__radio--s label::before,
.module-form__checkbox--s label::before,
.module-form__checkbox-radio--s label::before {
  width: 16px;
  height: 16px;
}

.module-form__radio label::after, .module-form__radio label::before {
  border-radius: 50%;
}
.module-form__radio label::after {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 10px;
  width: 10px;
}
.module-form__radio--s label::after {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 8px;
  width: 8px;
}

.module-form__checkbox-radio label {
  padding-left: 38px;
}
.module-form__checkbox-radio label::before {
  height: 16px;
  width: 32px;
  border-radius: 8px;
  border: 0;
  background-color: rgba(0, 224, 172, 0.3);
}
.module-form__checkbox-radio label::after {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(16px);
}
.module-form__checkbox-radio input:checked + label::before {
  background-color: #00e0ac;
}
.module-form__checkbox-radio input:checked + label::after {
  transform: translateX(0);
}

.module-form__checkbox label::before {
  border-radius: 6px;
}
.module-form__checkbox label::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-size: 14px auto;
  background-repeat: no-repeat;
  background-position: center center;
}
.module-form__checkbox--s label::before {
  border-radius: 4px;
}
.module-form__checkbox--s label::after {
  width: 16px;
  height: 16px;
  background-size: 9px auto;
}

.module-form__submit {
  flex-basis: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (max-width: 51.74em) {
  .module-form__submit {
    flex-basis: auto;
  }
}
@media (min-width: 90.75em) {
  .module-form__submit {
    flex-basis: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.module-form__submit .module-form__radio--s,
.module-form__submit .module-form__checkbox--s {
  margin-bottom: 24px;
}

.form-filter {
  position: relative;
  z-index: 4;
}
.form-filter.is-open {
  z-index: 5;
}
.form-filter.is-open .form-filter__button::before {
  opacity: 1;
}
.form-filter.is-open .form-filter__button::after {
  opacity: 1;
}
.form-filter:not(:first-child) {
  margin-left: 25px;
}
.form-filter__button {
  position: relative;
  height: auto;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid transparent;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.6px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter__button::before {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 1px solid transparent;
}
.form-filter__button::after  {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
}
.form-filter__button::before, .form-filter__button::after {
  content: "";
  border-radius: 16px;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter__button .svg:not(.svg-caret-down) {
  margin-right: 9px;
}
.form-filter__button .svg {
  fill: currentColor;
}
.form-filter__button .svg--caret-down {
  margin-left: auto;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter__button[aria-expanded=true] + .form-filter__dropdown {
  visibility: visible;
  opacity: 1;
}
.form-filter__button[aria-expanded=true] .svg--caret-down {
  transform: rotate(180deg);
}
.no-touchevents .form-filter__button:hover::after, .no-touchevents .form-filter__button:focus::after {
  opacity: 1;
}
.form-filter__dropdown {
  position: absolute;
  top: calc(100% + 16px);
  left: 50%;
  height: auto;
  width: 100%;
  min-width: 230px;
  visibility: hidden;
  opacity: 0;
  border-radius: 16px;
  transform: translateX(-50%);
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter__dropdown-items {
  overflow: auto;
  max-height: 280px;
  padding: 32px 32px 30px;
}
.form-filter__dropdown-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 32px;
  border-top: 1px solid transparent;
}
.form-filter__dropdown-clear {
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  letter-spacing: -0.8px;
  text-decoration: underline;
}
.form-filter .module-form__checkbox label {
  font-size: 1rem;
}
.form-filter .module-form__checkbox + .module-form__checkbox {
  margin-top: 10px;
}

.form-filter-date {
  position: relative;
  z-index: 4;
}
.form-filter-date:not(:first-child) {
  margin-left: 25px;
}
.form-filter-date input {
  display: none;
}
.form-filter-date__button {
  position: relative;
  height: auto;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid transparent;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.6px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter-date__button::before {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 1px solid transparent;
}
.form-filter-date__button::after  {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
}
.form-filter-date__button::before, .form-filter-date__button::after {
  content: "";
  border-radius: 16px;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter-date__button .svg:not(.svg-caret-down) {
  margin-right: 9px;
}
.form-filter-date__button .svg {
  fill: currentColor;
}
.form-filter-date__button .svg--caret-down {
  margin-left: auto;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.form-filter-date__button[aria-expanded=true] .svg--caret-down {
  transform: rotate(180deg);
}
.no-touchevents .form-filter-date__button:hover::after, .no-touchevents .form-filter-date__button:focus::after {
  opacity: 1;
}

.module-form__messages {
  flex-basis: calc((5 * calc((1160px - 440px) / 12)) + (4 * 40px));
  padding-bottom: 10px;
}
@media (max-width: 51.74em) {
  .module-form__messages {
    flex-basis: auto;
  }
}
@media (min-width: 90.75em) {
  .module-form__messages {
    flex-basis: calc((5 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}

.module-form__message {
  height: auto;
  width: 100%;
  padding: 16px 16px 14px;
  border-radius: 12px;
  border: 1px solid transparent;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.module-form__message + .module-form__message {
  margin-top: 16px;
}

.module-capture__list {
  height: auto;
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  z-index: 0;
  max-width: 100%;
  overflow: hidden;
}
.module-capture__list:before {
  padding-top: 66.6666666667%;
  display: block;
  content: "";
}
@media (max-width: 51.74em) {
  .module-capture__list {
    display: block;
    max-width: 560px;
    margin-right: auto;
    margin-left: auto;
  }
}
.module-capture__list::before {
  padding-top: 72% !important;
}
.module-capture__item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 0;
}
.module-capture__item img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.object-fit .module-capture__item img {
  object-fit: cover;
}
.no-object-fit .module-capture__item img {
  font-family: "object-fit: cover";
}
.module-capture__item:nth-child(1) {
  opacity: 1;
  z-index: 10;
}
.module-capture__item.is-active {
  opacity: 1;
  z-index: 10;
}
.module-capture__item.is-inactive {
  opacity: 0;
  z-index: 0;
}
.module-capture__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.module-capture__toggle {
  margin-right: 16px;
}
.module-capture__toggle .svg--play {
  display: none;
}
.module-capture__toggle .svg--pause {
  display: block;
}
.module-capture__toggle.is-paused .svg--play {
  display: block;
}
.module-capture__toggle.is-paused .svg--pause {
  display: none;
}
.module-capture__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
.module-capture__dots li {
  min-height: 8px;
  max-height: 8px;
}
.module-capture__dots li + li {
  margin-left: 16px;
}
.module-capture__dots li.is-active svg {
  animation: 5s linear both;
  animation-name: urcgh2t;
}
@keyframes urcgh2t {
  0% {
    stroke-dashoffset: 25.1327412288;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.module-capture__dots li.is-inactive svg {
  animation: 1s cubic-bezier(0.19, 1, 0.22, 1) both;
  animation-name: urcgh3g;
}
@keyframes urcgh3g {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -25.1327412288;
  }
}
.module-capture__dots button {
  position: relative;
  height: 8px;
  width: 8px;
  display: block;
  border-radius: 50%;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-capture__dots svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  margin-top: -6px;
  margin-left: -6px;
  fill: transparent;
  stroke-width: 2px;
  stroke-dasharray: 25.1327412288;
  stroke-dashoffset: 25.1327412288;
  transform: rotate(-90deg);
}
.module-capture__captions {
  margin-top: 24px;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  letter-spacing: -0.4px;
  text-align: center;
}
.module-capture__captions-item.is-inactive {
  display: none;
}
.module-capture__captions-item.is-active {
  display: block;
}

.module-faq__list-item {
  border-top: 1px solid transparent;
}
.module-faq__list-item-head {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 51.74em) {
  .module-faq__list-item-head {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.module-faq__list-item-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  background: transparent;
}
.module-faq__list-item-button .svg {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  transform: rotate(-135deg);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-faq__list-item-title, .module-faq__list-item-text {
  padding-right: calc((1 * calc((1160px - 440px) / 12)) + (0 * 40px));
}
@media (min-width: 90.75em) {
  .module-faq__list-item-title, .module-faq__list-item-text {
    padding-right: calc((1 * calc((1392px - 528px) / 12)) + (0 * 48px));
  }
}
.module-faq__list-item-title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.7px;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 51.74em) {
  .module-faq__list-item-title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
  }
}
.module-faq__list-item-text {
  display: none;
  padding-bottom: 32px;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-faq__list-item-text p + p {
  margin-top: 20px;
}
.module-faq__list-item-text p + ul,
.module-faq__list-item-text p + ol {
  margin-top: 32px;
}
.module-faq__list-item-text ul li,
.module-faq__list-item-text ol li {
  position: relative;
  padding-left: 26px;
}
.module-faq__list-item-text ul li + li,
.module-faq__list-item-text ol li + li {
  margin-top: 15px;
}
.module-faq__list-item-text ul + p,
.module-faq__list-item-text ul + ul,
.module-faq__list-item-text ul + ol,
.module-faq__list-item-text ol + p,
.module-faq__list-item-text ol + ul,
.module-faq__list-item-text ol + ol {
  margin-top: 32px;
}
.module-faq__list-item-text ul li::before {
  position: absolute;
  top: 10px;
  left: 0;
  height: 6px;
  width: 6px;
  content: "";
  border-radius: 50%;
}
.module-faq__list-item-text ol {
  counter-reset: count;
}
.module-faq__list-item-text ol li {
  counter-increment: count;
}
.module-faq__list-item-text ol li::before {
  content: counter(count);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9375rem;
  line-height: 1.8666666667;
  letter-spacing: -0.47px;
}
@media (max-width: 67.74em) {
  .module-faq__list-item-text {
    padding-right: 0;
  }
}
.module-faq__list-item.is-open .module-faq__list-item-button .svg {
  transform: rotate(0deg);
}
.module-faq__list-item.is-open .module-faq__list-item-text {
  display: block;
}
.module-faq--l .module-faq__list-item-text, .module-faq--l .module-faq__list-item-title {
  padding-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .module-faq--l .module-faq__list-item-text, .module-faq--l .module-faq__list-item-title {
    padding-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-faq--l .module-faq__list-item-text {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
@media (max-width: 67.74em) {
  .module-faq--l .module-faq__list-item-text {
    padding-right: 0;
  }
}

.module-form {
  position: relative;
}
.module-form__toggle {
  font-size: 0.8125rem;
  letter-spacing: -0.4px;
  text-decoration: underline;
  border: 0;
  background: transparent;
}
.module-form__toggle:not(.module-form__toggle--inline) {
  position: absolute;
  top: 10px;
  right: 0;
}
@media (max-width: 51.74em) {
  .module-form__toggle:not(.module-form__toggle--inline) {
    top: 5px;
  }
}
.module-form__toggle--inline {
  display: inline-block;
  margin-bottom: 40px;
}
.module-form__group legend {
  height: auto;
  width: 100%;
  margin-bottom: 32px;
  padding-bottom: 25px;
  border-bottom: 1px solid transparent;
  font-size: 1.75rem;
  line-height: 1.1428571429;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-form__group legend {
    font-size: 1.375rem;
    letter-spacing: -0.5px;
  }
}
@media (max-width: 51.74em) {
  .module-form__group legend br {
    display: none;
  }
}
.module-form__group p {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-bottom: 40px;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-form__group p {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -0.5px;
  }
}
@media (min-width: 90.75em) {
  .module-form__group p {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
.module-form__group + .module-form__group {
  margin-top: 80px;
}
.module-form__footer {
  margin-top: 65px;
  padding-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid transparent;
}
@media (max-width: 51.74em) {
  .module-form__footer {
    margin-top: 40px;
    flex-direction: column;
  }
  .module-form__footer > div:not(:last-child) {
    margin-bottom: 30px;
  }
}
.module-form + .module-form {
  margin-top: 80px;
}
.module-form--togglable legend {
  position: relative;
  padding-right: 40px;
}
.module-form--togglable .module-form__footer {
  margin-top: 0;
  border-top: 0;
}
.module-form--togglable.module-form--disabled label {
  cursor: initial;
}
.module-form--togglable.module-form--disabled .module-form__footer {
  display: none;
}
.module-form--togglable.module-form--disabled .module-form__enabled-only {
  display: none;
}
.module-form--togglable.module-form--disabled .module-form__enabled-only + .module-form__disabled-only {
  margin-top: 0;
}
.module-form--togglable:not(.module-form--disabled) .module-form__disabled-only {
  display: none;
}
.module-form--togglable:not(.module-form--disabled) .module-form__disabled-only + .module-form__enabled-only {
  margin-top: 0;
}

.module-text__head {
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
}
.module-text__head .eyebrow + .tag {
  margin-left: 10px;
}
.module-text__title {
  margin-bottom: 30px;
  font-size: 2.75rem;
  line-height: 1;
  letter-spacing: -1px;
}
@media (min-width: 90.75em) {
  .module-text__title {
    font-size: 3rem;
  }
}
@media (max-width: 51.74em) {
  .module-text__title {
    font-size: 2.25rem;
  }
}
.module-text__lead {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  letter-spacing: -0.7px;
}
.module-text__content {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-text__content {
    font-size: 1rem;
    letter-spacing: -0.4px;
  }
}
.module-text__button {
  margin-top: 50px;
}

sup {
  vertical-align: super;
  font-size: 11px;
}

@media (min-width: 76.25em) {
  .module-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
    padding-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .module-tabs {
    padding-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
    padding-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-tabs__list {
  display: flex;
  flex: 1;
  flex-basis: calc((7 * calc((1160px - 440px) / 12)) + (6 * 40px));
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}
@media (min-width: 90.75em) {
  .module-tabs__list {
    flex-basis: calc((7 * calc((1392px - 528px) / 12)) + (6 * 48px));
  }
}
@media (max-width: 51.74em) {
  .module-tabs__list {
    flex-basis: auto;
    width: calc(100% + 44px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.module-tabs__list li {
  text-align: center;
  flex-shrink: 0;
}
@media (max-width: 76.24em) {
  .module-tabs__list li + li {
    margin-left: 20px;
  }
}
@media (max-width: 76.24em) {
  .module-tabs__list li:first-child {
    padding-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (max-width: 51.74em) {
  .module-tabs__list li:first-child {
    padding-left: 25px;
  }
}
@media (min-width: 90.75em) {
  .module-tabs__list li:first-child {
    padding-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (max-width: 76.24em) {
  .module-tabs__list li:last-child {
    padding-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (max-width: 51.74em) {
  .module-tabs__list li:last-child {
    padding-right: 25px;
    position: relative;
    padding-right: 94px;
  }
  .module-tabs__list li:last-child .button {
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }
  .module-tabs__list li:last-child::before, .module-tabs__list li:last-child::after {
    position: absolute;
    top: 0;
    content: "";
    height: 100%;
  }
  .module-tabs__list li:last-child::before {
    right: 44px;
    width: 25px;
    z-index: 1;
  }
  .module-tabs__list li:last-child::after {
    right: 0;
    width: 54px;
    z-index: 0;
  }
}
@media (min-width: 90.75em) {
  .module-tabs__list li:last-child {
    padding-right: 0;
  }
}
.module-tabs__list button {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 29px;
  display: block;
  width: 120px;
}
@media (max-width: 76.24em) {
  .module-tabs__list button {
    width: 100px;
  }
}
@media (max-width: 51.74em) {
  .module-tabs__list button {
    padding-top: 29px;
  }
}
.module-tabs__list button::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  content: "";
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.no-touchevents .module-tabs__list button:hover::after, .no-touchevents .module-tabs__list button:focus::after, .module-tabs__list button.is-active::after {
  opacity: 1;
}
.module-tabs__list-day, .module-tabs__list-date {
  display: block;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-tabs__list-day {
  margin-bottom: 6px;
  font-size: 0.625rem;
  line-height: 1.4;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.module-tabs__list-date {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  letter-spacing: -0.2px;
}
@media (max-width: 51.74em) {
  .module-tabs__list-date {
    font-size: 1rem;
  }
}
.module-tabs__button {
  flex-basis: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  flex-shrink: 0;
  text-align: right;
}
@media (max-width: 76.24em) {
  .module-tabs__button {
    margin-top: 40px;
    text-align: center;
  }
}
@media (min-width: 90.75em) {
  .module-tabs__button {
    flex-basis: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    padding-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}

@media (max-width: 51.74em) {
  .module-dates {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}
.module-dates__list-hidden {
  margin-top: -1px;
}
.module-dates__list-hidden .module-card-date:last-child {
  border-bottom: none;
}
@media (max-width: 51.74em) {
  .module-dates__list .module-card-date:first-child {
    padding-top: 0;
  }
}
@media (max-width: 51.74em) {
  .module-dates__list .module-card-date:last-child {
    padding-bottom: 0;
  }
}
.module-dates__list-expand {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;
  text-align: center;
}
.module-dates__list-expand::before {
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 290px;
  width: 100%;
  content: "";
  z-index: -1;
  pointer-events: none;
  background-image: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
}
.module-dates__list-expand.is-open::before {
  display: none;
}
.module-dates__list-expand [aria-expanded=false] .module-dates__list-expand-expanded {
  display: none;
}
.module-dates__list-expand [aria-expanded=true] .module-dates__list-expand-unexpanded {
  display: none;
}
.module-dates__list-expand [aria-expanded=true] .svg--caret-down {
  transform: rotate(180deg);
}
.module-dates__list-hidden {
  display: none;
}
.module-dates__list-hidden.is-visible {
  display: block;
}

.module-quote {
  position: relative;
  height: auto;
  width: 100%;
  display: block;
}
@media (max-width: 51.74em) {
  .module-quote {
    text-align: center;
  }
}
.module-quote__inner {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 40px;
}
.module-quote__image {
  position: absolute;
  top: -40px;
  left: calc(50% - 40px);
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
@media (min-width: 51.75em) {
  .module-quote__image {
    display: none;
  }
}
.module-quote__image img {
  width: 100%;
  display: block;
  border-radius: 50%;
}
.module-quote__image + cite {
  margin-top: 46px;
}
.module-quote cite {
  display: block;
  margin-bottom: 15px;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  letter-spacing: 0.7px;
}
.module-quote p {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.4px;
}
@media (max-width: 51.74em) {
  .module-quote p {
    font-size: 1.125rem;
  }
}
.module-quote .button {
  margin-top: 20px;
}

.module-perks {
  margin-top: -35px;
  margin-bottom: -32px;
}
.module-perks__item {
  padding-top: 35px;
  padding-bottom: 32px;
}
@media (min-width: 90.75em) {
  .module-perks__item {
    padding-right: calc((1 * calc((1392px - 528px) / 12)) + (0 * 48px));
  }
}
.module-perks__item-title {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-perks__item-title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
  }
}
.module-perks__item-title .svg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.module-perks__item-title .svg--check {
  top: 5px;
}
.module-perks__item-text {
  margin-top: 16px;
  padding-right: calc((1 * calc((1160px - 440px) / 12)) + (0 * 40px));
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-perks__item + .module-perks__item {
  border-top: 1px solid transparent;
}
.module-perks__item:first-child {
  padding-top: 0;
}
.module-perks--l .module-perks__item-text, .module-perks--l .module-perks__item-title {
  padding-left: 40px;
}
.module-perks--l .module-perks__item-text {
  padding-right: 0;
}
.module-perks--l .module-perks__item-title .svg {
  opacity: 1;
}

.module-header__image, .module-header__content {
  position: relative;
}
@media (max-width: 51.74em) {
  .module-header__images {
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    width: 260px;
  }
}
.module-header__content {
  z-index: 1;
}
.module-header__eyebrow {
  margin-bottom: 20px;
}
.module-header__title {
  font-size: 2.75rem;
  line-height: 1;
  letter-spacing: -0.8px;
}
@media (min-width: 90.75em) {
  .module-header__title {
    font-size: 3rem;
    letter-spacing: -1px;
  }
}
@media (max-width: 51.74em) {
  .module-header__title {
    font-size: 2.5rem;
  }
}
@media (max-width: 37.49em) {
  .module-header__title {
    font-size: 2.25rem;
  }
}
.module-header__text {
  margin-top: 32px;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-header__text {
    font-size: 1rem;
  }
}
.module-header__text a {
  text-decoration: underline;
}
.module-header__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}
.module-header__buttons .button:not(:last-child) {
  margin-bottom: 20px;
}
.module-header__caption {
  max-width: 240px;
  margin-top: 17px;
  margin-right: auto;
  margin-left: auto;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  letter-spacing: -0.41px;
}
.module-header--s .module-header__title {
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -1px;
}
@media (max-width: 51.74em) {
  .module-header--s .module-header__title {
    font-size: 1.75rem;
    letter-spacing: -0.6px;
  }
}
@media (min-width: 51.75em) {
  .module-header--m .module-header__text, .module-header--l .module-header__text {
    font-size: 1.25rem;
    line-height: 1.4;
    letter-spacing: -0.4px;
  }
}
.module-header--l .module-header__title {
  font-size: 3.75rem;
  line-height: 0.9666666667;
  letter-spacing: -1.3px;
}

html:not(.mobile):not(.ie) .js-in-view .module-header .module-header__eyebrow,
html:not(.mobile):not(.ie) .js-in-view .module-header .module-header__text,
html:not(.mobile):not(.ie) .js-in-view .module-header .module-header__caption,
html:not(.mobile):not(.ie) .js-in-view .module-header .button-line {
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.4s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.4s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
html:not(.mobile):not(.ie) .js-in-view .module-header .button-icon,
html:not(.mobile):not(.ie) .js-in-view .module-header .button-icon .button-icon__label, html:not(.mobile):not(.ie) .js-in-view .module-header .button-icon::before,
html:not(.mobile):not(.ie) .js-in-view .module-header .button-icon .svg--arrow-right {
  transition-delay: 0.6s;
}
html:not(.mobile):not(.ie) .js-in-view .module-header .module-header__eyebrow {
  transition-delay: 0.1s;
}
html:not(.mobile):not(.ie) .js-in-view .module-header .module-header__caption,
html:not(.mobile):not(.ie) .js-in-view .module-header .button-line {
  transition-delay: 0.7s;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .module-header .module-header__eyebrow,
html:not(.mobile):not(.ie) .js-in-view.in-view .module-header .module-header__text,
html:not(.mobile):not(.ie) .js-in-view.in-view .module-header .module-header__caption,
html:not(.mobile):not(.ie) .js-in-view.in-view .module-header .button-line {
  transform: translateY(0);
  opacity: 1;
}
html:not(.mobile):not(.ie) .js-in-view:not(.in-view) .module-header .module-image:first-child:last-child .module-image__item--1 {
  clip-path: circle(100px);
}

.module-partners__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: calc((0 * calc((1160px - 440px) / 12)) + (-1 * 40px));
  margin-left: calc((0 * calc((1160px - 440px) / 12)) + (-1 * 40px));
}
@media (max-width: 51.74em) {
  .module-partners__list {
    margin-right: -44px;
    margin-left: -44px;
  }
}
@media (min-width: 90.75em) {
  .module-partners__list {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (-1 * 48px));
    margin-left: calc((0 * calc((1392px - 528px) / 12)) + (-1 * 48px));
  }
}
.module-partners__item {
  height: auto;
  width: 20%;
  position: relative;
  padding-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  padding-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  min-height: 80px;
}
.module-partners__item:before {
  padding-top: 100%;
  display: block;
  content: "";
}
@media (max-width: 51.74em) {
  .module-partners__item {
    padding-right: 44px;
    padding-left: 44px;
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .module-partners__item {
    width: 25%;
  }
  .module-partners__item:nth-child(n+9) {
    display: none;
  }
}
@media (min-width: 28.125em) and (max-width: 51.74em) {
  .module-partners__item {
    width: 33.33%;
    min-height: 80px;
  }
  .module-partners__item:nth-child(n+7) {
    display: none;
  }
}
@media (max-width: 28.115em) {
  .module-partners__item {
    width: 50%;
  }
  .module-partners__item:nth-child(odd) img {
    margin-left: 20px;
  }
  .module-partners__item:nth-child(even) img {
    margin-left: -20px;
  }
  .module-partners__item:nth-child(n+7) {
    display: none;
  }
}
@media (min-width: 90.75em) {
  .module-partners__item {
    padding-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
    padding-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-partners__item a {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.module-partners__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 80%;
  max-height: 80px;
  transform: translate(-50%, -50%);
}
.module-partners__button {
  margin-top: 64px;
  text-align: center;
}

@media (max-width: 51.74em) {
  .module-schedule {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}
@media (max-width: 51.74em) {
  .module-schedule .module-tabs {
    margin-bottom: 10px;
  }
}
@media (min-width: 51.75em) {
  .module-schedule .module-tabs {
    margin-bottom: 30px;
  }
}
.module-schedule__list {
  display: none;
}
.module-schedule__list.is-active {
  display: block;
}
.module-schedule__button {
  margin-top: 10px;
  text-align: center;
}

.alert-bar {
  position: relative;
  text-align: center;
  transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
}
.alert-bar p {
  padding: 26px 70px;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -0.5px;
}
@media (max-width: 67.74em) {
  .alert-bar p {
    padding-right: 40px;
    padding-left: 40px;
    font-size: 0.9375rem;
  }
}
@media (max-width: 37.49em) {
  .alert-bar p {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 0.875rem;
  }
}
.alert-bar p a {
  margin-left: 7px;
}
.alert-bar__button {
  position: absolute;
  top: 0;
  right: 40px;
  height: 100%;
  width: 40px;
  padding: 0;
  background: transparent;
  border: 0;
}
@media (max-width: 67.74em) {
  .alert-bar__button {
    right: 20px;
  }
}
@media (max-width: 37.49em) {
  .alert-bar__button {
    right: 0;
  }
}
.alert-bar__button .svg {
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.alert-bar__button:hover .svg {
  transform: rotate(90deg);
}
.alert-bar.is-hidden {
  height: 0px !important;
}

.module-pagination {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  border-top: 1px solid transparent;
}
.module-pagination__button {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  letter-spacing: -0.8px;
  fill: currentColor;
}
.module-pagination__button .svg {
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-pagination__button--prev {
  margin-right: auto;
}
.no-touchevents .module-pagination__button--prev:hover .svg, .no-touchevents .module-pagination__button--prev:focus .svg {
  transform: translateX(-5px);
}
.module-pagination__button--next {
  margin-left: auto;
}
.no-touchevents .module-pagination__button--next:hover .svg, .no-touchevents .module-pagination__button--next:focus .svg {
  transform: translateX(5px);
}
@media (max-width: 37.49em) {
  .module-pagination__button {
    display: none;
  }
}
.module-pagination__list {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
.module-pagination__list-item {
  padding: 5px 11px;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-pagination__list span {
  position: relative;
  top: -4px;
}

.module-filters-bar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid transparent;
}
@media (max-width: 51.74em) {
  .module-filters-bar {
    flex-direction: column-reverse;
    border-bottom: 0;
    align-items: flex-start;
  }
}
.module-filters-bar__tags, .module-filters-bar__sorting {
  display: flex;
  align-items: flex-start;
}
.module-filters-bar__text, .module-filters-bar__sorting-select {
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  letter-spacing: -0.8px;
}
.module-filters-bar__tags {
  width: 100%;
  margin-top: -16px;
  padding-right: 30px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 51.74em) {
  .module-filters-bar__tags {
    padding-top: 24px;
    border-top: 1px solid transparent;
    margin-top: 15px;
  }
}
.module-filters-bar__tags-item {
  padding: 10px 14px;
  margin-top: 16px;
  margin-right: 16px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 0.9375rem;
  line-height: 1.2;
  letter-spacing: -0.3px;
}
.module-filters-bar__tags-item .svg {
  margin-left: 6px;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.no-touchevents .module-filters-bar__tags-item:hover .svg, .no-touchevents .module-filters-bar__tags-item:focus .svg {
  transform: rotate(90deg);
}
.module-filters-bar__tags .module-filters-bar__text {
  margin-top: 16px;
}
@media (max-width: 51.74em) {
  .module-filters-bar__tags .module-filters-bar__text:first-child {
    margin-top: 0;
  }
}
.module-filters-bar__sorting {
  position: relative;
  flex-shrink: 0;
}
.module-filters-bar__sorting-select {
  padding: 10px 35px 10px 11px;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 10px;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;
}
.no-touchevents .module-filters-bar__sorting-select:hover, .no-touchevents .module-filters-bar__sorting-select:focus {
  box-shadow: 0 3px 12px 0 rgba(6, 41, 78, 0.08);
}
.module-filters-bar__sorting .svg--caret-down {
  position: absolute;
  top: 19px;
  right: 17px;
}
.module-filters-bar__sorting .module-filters-bar__text {
  margin-top: 10px;
}

.module-image-basic {
  position: relative;
}
.module-image-basic:before {
  padding-top: 100%;
  display: block;
  content: "";
}
@media (max-width: 51.74em) {
  .module-image-basic {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}
.module-image-basic img {
  display: block;
  width: 100%;
}
.module-image-basic__item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  width: 100%;
}
.object-fit .module-image-basic__item {
  object-fit: contain;
  object-position: center;
}
.no-object-fit .module-image-basic__item {
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
}

.module-facts-slider__list-item {
  position: relative;
  padding-bottom: 35px;
}
.module-facts-slider__list-item-head {
  position: relative;
}
.module-facts-slider__list-item-body {
  padding-bottom: 20px;
}
@media (min-width: 51.75em) {
  .module-facts-slider__list-item-body {
    display: none;
  }
}
.module-facts-slider__list-item-eyebrow {
  margin-bottom: 15px;
}
.module-facts-slider__list-item-title {
  margin-bottom: 18px;
  font-size: 1.75rem;
  line-height: 1.1428571429;
  letter-spacing: -0.5px;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (max-width: 51.74em) {
  .module-facts-slider__list-item-title {
    font-size: 1.25rem;
  }
}
.module-facts-slider__list-item-text {
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-facts-slider__list-item-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  background: transparent;
}
@media (max-width: 51.74em) {
  .module-facts-slider__list-item-button {
    pointer-events: none;
  }
}
.module-facts-slider__list-item__nav {
  position: absolute;
  bottom: 20px;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 51.74em) {
  .module-facts-slider__list-item__nav {
    bottom: 10px;
  }
}
.module-facts-slider__list-item__nav-button {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 0;
  z-index: 2;
  flex-shrink: 0;
  border: 0;
  background: #fff;
  transform: translateY(-50%);
  visibility: hidden;
}
@media (max-width: 51.74em) {
  .module-facts-slider__list-item__nav-button {
    display: none;
  }
}
.module-facts-slider__list-item__nav-progress {
  height: 2px;
  width: 100%;
  position: relative;
  border-radius: 1px;
}
.module-facts-slider__list-item__nav-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  transform: scale(0);
  transform-origin: 0 50%;
}
.module-facts-slider__list-item-image {
  margin-top: 30px;
  display: block;
  width: 100%;
}
.module-facts-slider__list-item-image img {
  display: block;
  width: 100%;
}
.module-facts-slider__list-item.is-active .module-facts-slider__list-item-body {
  display: block;
}
.module-facts-slider__list-item.is-active .module-facts-slider__list-item__nav-button {
  visibility: visible;
}
.module-facts-slider__list-item:not(.is-active) .module-facts-slider__list-item__nav-progress-bar {
  animation: none;
}
.module-facts-slider__list-item:not(:last-child) {
  margin-bottom: 35px;
}

@keyframes factSliderBarAnimation {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
.module-card__link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  z-index: 5;
  background: transparent;
}
.no-touchevents .module-card__link:focus ~ .module-card__inner .module-card__image-overlay, .no-touchevents .module-card__link:hover ~ .module-card__inner .module-card__image-overlay {
  opacity: 1;
}
.module-card__inner-wrapper {
  position: relative;
  height: 100%;
  width: auto;
}
.module-card__inner {
  height: 100%;
  width: auto;
  padding: 38px 40px;
  display: block;
  overflow: hidden;
}
@media (max-width: 51.74em) {
  .module-card__inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
.module-card__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.module-card__image-overlay::before, .module-card__image-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 2;
}
.module-card__image-overlay::before {
  background: rgba(0, 181, 247, 0.6);
}
.module-card__image-overlay::after {
  opacity: 0.6;
  background-image: linear-gradient(198deg, rgba(0, 103, 226, 0) 0%, #0A0870 98%);
}

.module-card-icon .module-card__inner {
  padding-bottom: 70px;
}
.module-card-icon__title {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 18px;
  font-size: 1.75rem;
  letter-spacing: -0.6px;
  line-height: 1.1428571429;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (min-width: 90.75em) {
  .module-card-icon__title {
    font-size: 2rem;
    letter-spacing: -0.7px;
  }
}
@media (max-width: 51.74em) {
  .module-card-icon__title {
    font-size: 1.5rem;
  }
}
.module-card-icon__text {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.module-card-icon__tag {
  position: absolute;
  top: 45px;
  right: 40px;
  font-size: 0.625rem;
  letter-spacing: 1px;
}
.module-card-icon__icon {
  height: 100px;
  width: 100px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media (max-width: 51.74em) {
  .module-card-icon__icon {
    height: 80px;
    width: 80px;
    margin-bottom: 25px;
  }
}
.module-card-icon__icon img {
  display: block;
  width: 32px;
}
.module-card-icon__button {
  position: absolute;
  bottom: 40px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.module-card-icon--m, .module-card-icon--s {
  text-align: center;
}
.module-card-icon--m .module-card-icon__icon, .module-card-icon--s .module-card-icon__icon {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 51.75em) {
  .module-card-icon--m .module-card__inner {
    padding: 64px 56px 94px;
  }
}
.module-card-icon--m .module-card-icon__title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.5px;
}
.module-card-icon--m .module-card-icon__icon {
  height: 64px;
  width: 64px;
  margin-bottom: 48px;
}
@media (max-width: 51.74em) {
  .module-card-icon--m .module-card-icon__icon {
    margin-bottom: 25px;
  }
}
.module-card-icon--s .module-card-icon__icon {
  width: 80px;
  height: 80px;
}
.module-card-icon--s .module-card-icon__title {
  font-size: 1.25rem;
}
.no-touchevents .module-card-icon--with-button .module-card__link:hover ~ .module-card__inner .module-card-icon__button, .no-touchevents .module-card-icon--with-button .module-card__link:focus ~ .module-card__inner .module-card-icon__button {
  opacity: 1;
}
.module-card-icon--with-button .module-card__inner {
  padding-bottom: 110px;
}

.module-card-text .module-card__inner {
  padding: 0;
  border-radius: 0;
}
.module-card-text .module-card__inner::before {
  content: none;
}
.module-card-text__eyebrow {
  margin-bottom: 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid transparent;
}
.module-card-text__title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.5px;
}
.module-card-text__text {
  margin-top: 16px;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}

.module-card-date {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 36px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
@media (max-width: 51.74em) {
  .module-card-date {
    flex-direction: column;
  }
}
.module-card-date__metas {
  flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
  flex-shrink: 0;
}
@media (max-width: 51.74em) {
  .module-card-date__metas {
    flex-basis: auto;
  }
}
@media (min-width: 90.75em) {
  .module-card-date__metas {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-date__date {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.2px;
}
.module-card-date__hour {
  margin-top: 7px;
  font-size: 0.6875rem;
  line-height: 1.4545454545;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.module-card-date__content {
  flex: 1;
  margin-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  margin-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (max-width: 51.74em) {
  .module-card-date__content {
    margin-top: 12px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 90.75em) {
  .module-card-date__content {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-date__text {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.module-card-date__caption {
  margin-top: 17px;
  font-size: 0.8125rem;
  line-height: 1.2307692308;
  letter-spacing: -0.4px;
}
.module-card-date__button {
  flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
  flex-shrink: 0;
  margin-top: -3px;
  text-align: right;
}
@media (max-width: 51.74em) {
  .module-card-date__button {
    margin-top: 14px;
    text-align: left;
    flex-basis: auto;
  }
}
@media (min-width: 90.75em) {
  .module-card-date__button {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-date + .module-card-date {
  margin-top: -1px;
}

@media (max-width: 67.74em) {
  .module-card-small {
    min-height: 88px;
  }
}
@media (max-width: 67.74em) {
  .module-card-small .module-card__link + .module-card__inner {
    padding-right: 80px;
  }
}
.module-card-small .module-card__inner {
  display: flex;
  flex-direction: column;
}
@media (max-width: 67.74em) {
  .module-card-small .module-card__inner {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.module-card-small__head {
  display: flex;
  align-items: center;
  height: 100%;
}
.module-card-small__icon {
  height: 48px;
  width: 48px;
  margin-right: 20px;
  margin-left: -20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media (max-width: 51.74em) {
  .module-card-small__icon {
    margin-left: -15px;
    margin-right: 15px;
  }
}
.module-card-small__icon img {
  display: block;
  width: 24px;
}
.module-card-small__title {
  font-size: 1.25rem;
  letter-spacing: -0.6px;
  line-height: 1.2;
}
@media (min-width: 67.75em) {
  .module-card-small__title {
    margin-top: auto;
    padding-top: 75px;
  }
}
@media (max-width: 51.74em) {
  .module-card-small__title {
    font-size: 1.125rem;
    letter-spacing: -0.4px;
  }
}
@media (min-width: 90.75em) {
  .module-card-small__title {
    font-size: 1.375rem;
  }
}
.module-card-small__text {
  margin-top: 16px;
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: -0.5px;
}
@media (max-width: 67.74em) {
  .module-card-small .module-card__dot {
    top: 28px;
  }
}
.module-card-small--narrow .module-card__inner {
  padding-top: 20px;
  padding-bottom: 20px;
}
.module-card-small--narrow .module-card__link + .module-card__inner {
  padding-top: 28px;
  padding-right: 80px;
  padding-bottom: 28px;
}
@media (min-width: 67.75em) {
  .module-card-small--narrow .module-card__dot {
    top: 36px;
  }
  .module-card-small--narrow .module-card__dot::before {
    top: -13px;
    right: 7px;
    transform: rotate(45deg);
  }
}
@media (max-width: 67.74em) {
  .module-card-small--narrow .module-card__dot {
    top: 22px;
  }
}
.module-card-small--narrow .module-card-small__icon + .module-card-small__title {
  margin-top: auto;
}
.module-card-small--narrow .module-card-small__title {
  margin-top: 0;
  margin-bottom: auto;
  padding-top: 0;
}

.module-card-press .module-card__inner {
  position: relative;
  padding: 40px 36px 33px 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 51.75em) {
  .module-card-press .module-card__inner {
    min-height: 400px;
  }
}
.module-card-press__title {
  padding-bottom: 30px;
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.5px;
}
@media (max-width: 67.74em) {
  .module-card-press__title {
    font-size: 1.25rem;
  }
}
.module-card-press__tag {
  align-self: flex-start;
  margin-bottom: 24px;
  border-radius: 4px;
  text-transform: uppercase;
}
.module-card-press__date {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid transparent;
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  letter-spacing: -0.8px;
}

.module-card-habit .module-card__inner {
  padding-top: 0;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
}
.module-card-habit .module-card__inner::before {
  content: none;
}
.module-card-habit__title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.5px;
}
.module-card-habit__text {
  margin-top: 12px;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-card-habit__icon {
  position: relative;
  height: 64px;
  width: 64px;
  margin: -32px auto 8px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.module-card-habit__icon img {
  display: block;
  width: 27px;
}
.module-card-habit__image {
  position: relative;
  height: auto;
  width: calc(100% + 80px);
  margin-left: -40px;
  display: block;
  overflow: hidden;
  border-radius: 16px;
}
.module-card-habit__image:before {
  padding-top: 66.6666666667%;
  display: block;
  content: "";
}
@media (max-width: 51.74em) {
  .module-card-habit__image {
    margin-left: -32px;
    width: calc(100% + 64px);
  }
}
.module-card-habit__image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
}
.object-fit .module-card-habit__image img {
  object-fit: cover;
}
.no-object-fit .module-card-habit__image img {
  font-family: "object-fit: cover";
}

.module-card-program .module-card__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  overflow: visible;
  border-radius: 0;
  background-color: transparent;
}
@media (max-width: 37.49em) {
  .module-card-program .module-card__inner {
    flex-direction: column;
  }
}
.module-card-program .module-card__inner::after, .module-card-program .module-card__inner::before {
  content: none;
}
@media (max-width: 51.74em) {
  .module-card-program__column:nth-child(1), .module-card-program__column:nth-child(3) {
    margin-bottom: 30px;
  }
}
@media (min-width: 51.75em) {
  .module-card-program__column:nth-child(1), .module-card-program__column:nth-child(3) {
    flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (2 * 40px));
  }
}
@media (min-width: 90.75em) {
  .module-card-program__column:nth-child(1), .module-card-program__column:nth-child(3) {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
.module-card-program__column:nth-child(2) {
  flex: 1;
}
@media (min-width: 37.5em) and (max-width: 67.74em) {
  .module-card-program__column:nth-child(2) {
    margin-left: 30px;
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .module-card-program__column:nth-child(2) {
    margin-right: 30px;
  }
}
@media (min-width: 67.75em) {
  .module-card-program__column:nth-child(2) {
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .module-card-program__column:nth-child(2) {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (max-width: 51.74em) {
  .module-card-program__column:nth-child(3) {
    display: none;
  }
}
.module-card-program__location, .module-card-program__elligibility {
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  letter-spacing: 0.4px;
}
.module-card-program__location {
  margin-bottom: 4px;
}
.module-card-program__title {
  margin-bottom: 16px;
  font-size: 1.75rem;
  line-height: 1.1428571429;
  letter-spacing: -0.5;
}
.module-card-program__tag {
  margin-bottom: 32px;
}
.module-card-program__text {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.5;
}
.module-card-program__button {
  margin-top: 16px;
}
.module-card-program__logo {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #fff;
}
.module-card-program__logo:before {
  padding-top: 70%;
  display: block;
  content: "";
}
.module-card-program__logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: 100%;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  transform: translate(-50%, -50%);
}

.module-card-activity .module-card__inner {
  padding: 0;
}
.module-card-activity__image {
  position: relative;
  display: block;
  overflow: hidden;
}
.module-card-activity__image:before {
  padding-top: 138.4615384615%;
  display: block;
  content: "";
}
.module-card-activity__image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
}
.object-fit .module-card-activity__image img {
  object-fit: cover;
  object-position: center;
}
.no-object-fit .module-card-activity__image img {
  font-family: "object-fit: cover; object-position: center";
  object-position: center;
}
.module-card-activity__tags {
  margin-bottom: 10px;
}
.module-card-activity__tag {
  margin-top: 8px;
  margin-right: 3px;
}
.module-card-activity__title {
  font-size: 1.375rem;
  line-height: 1.1818181818;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .module-card-activity__title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
  }
}
.module-card-activity__text {
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 1rem;
  letter-spacing: -0.7px;
}
@media (max-width: 76.24em) {
  .module-card-activity__text {
    margin-top: 10px;
  }
}
.module-card-activity__text:not(.is-shown) {
  max-height: 0;
  opacity: 0;
}
.module-card-activity__text.is-ready {
  transition: max-height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.module-card-activity__content {
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  padding: 40px;
  z-index: 3;
}
.module-card-activity .module-card__link:not(:hover) ~ .module-card__inner .module-card-activity__text:not(.is-shown) {
  max-height: 0 !important;
}
.module-card-activity .module-card__link:hover ~ .module-card__inner {
  border-radius: 36px;
}
.module-card-activity .module-card__link:hover ~ .module-card__inner .module-card-activity__text:not(.is-shown) {
  opacity: 1;
  max-height: 100%;
  margin-bottom: 0;
}

.module-card-schedule {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 51.74em) {
  .module-card-schedule {
    flex-direction: column;
  }
}
.module-card-schedule__metas {
  flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
  flex-shrink: 0;
}
@media (max-width: 51.74em) {
  .module-card-schedule__metas {
    flex-basis: 100%;
    margin-bottom: 11px;
  }
}
@media (min-width: 90.75em) {
  .module-card-schedule__metas {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-schedule__hour {
  font-size: 1rem;
  line-height: 1.5;
}
.module-card-schedule__content {
  flex: 1;
  margin-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
  margin-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (max-width: 51.74em) {
  .module-card-schedule__content {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 90.75em) {
  .module-card-schedule__content {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-schedule__text {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.module-card-schedule__caption {
  margin-top: 12px;
  font-size: 0.8125rem;
  line-height: 1.2307692308;
  letter-spacing: -0.4px;
}
.module-card-schedule__button {
  flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
  flex-shrink: 0;
  margin-top: -3px;
  text-align: right;
}
@media (max-width: 51.74em) {
  .module-card-schedule__button {
    margin-top: 14px;
    text-align: left;
    flex-basis: auto;
  }
}
@media (min-width: 90.75em) {
  .module-card-schedule__button {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.module-card-schedule + .module-card-schedule {
  margin-top: -1px;
  border-top: 1px solid transparent;
}

.content {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}
.content .module-header {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 80px;
  text-align: center;
}
@media (min-width: 90.75em) {
  .content .module-header {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (7 * 48px));
  }
}
@media (max-width: 51.74em) {
  .content .module-header {
    max-width: 560px;
    margin-bottom: 50px;
    text-align: left;
  }
}
.content .module-header + .content__inner .content__column {
  margin-top: 0;
}
.content .module-header--s {
  margin-bottom: 50px;
}
.content__inner {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 51.74em) {
  .content__inner {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
  }
}
.content__column {
  flex-basis: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  min-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
}
@media (min-width: 90.75em) {
  .content__column {
    flex-basis: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
    min-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
@media (max-width: 76.24em) {
  .content__column {
    flex-basis: calc(50% - 20px);
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
@media (max-width: 51.74em) {
  .content__column {
    flex-basis: auto;
    min-width: 100%;
    max-width: 100%;
  }
}

.modules > .content:not(.content--curvy):last-child {
  padding-bottom: 120px;
}

.content-faq {
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-faq .module-header {
  margin-bottom: 65px;
}
@media (min-width: 51.75em) {
  .content-faq .module-faq {
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-faq .module-faq {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (max-width: 51.74em) {
  .content-faq .module-faq {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}

.content-dates {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (min-width: 51.75em) {
  .content-dates .module-dates {
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-dates .module-dates {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 90.75em) {
  .content-dates .module-dates {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}

.content-cards {
  padding-top: 120px;
  padding-bottom: 120px;
}
.content-cards .module-header {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 51.75em) {
  .content-cards .module-header {
    text-align: center;
  }
}
@media (min-width: 90.75em) {
  .content-cards .module-header {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
.content-cards .module-header__text {
  margin-right: auto;
  margin-left: auto;
  margin-top: 24px;
  max-width: 560px;
}
.content-cards .module-card {
  width: 100%;
}
@media (max-width: 67.74em) {
  .content-cards .module-card {
    flex-shrink: 0;
    width: 336px;
  }
}
.content-cards__list {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 67.74em) {
  .content-cards__list {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 67.74em) {
  .content-cards__list.js-cards-slider {
    flex-wrap: nowrap;
  }
}
@media (max-width: 67.74em) {
  .content-cards__list:not(.js-cards-slider) .module-card {
    width: 100%;
  }
  .content-cards__list:not(.js-cards-slider) .module-card:not(:last-child) {
    margin-bottom: 12px;
  }
}
@media (max-width: 51.74em) {
  .content-cards__list:not(.js-cards-slider) {
    max-width: 560px;
  }
}
.content-cards__footer {
  margin-top: 50px;
  text-align: center;
}
@media (max-width: 51.74em) {
  .content-cards__footer {
    margin-top: 40px;
  }
}
.content-cards--text {
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-cards--icon, .content-cards--press, .content-cards--small {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 51.74em) {
  .content-cards--icon, .content-cards--press, .content-cards--small {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.content-cards--topic, .content-cards--habit, .content-cards--icon-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 51.74em) {
  .content-cards--topic, .content-cards--habit, .content-cards--icon-medium {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.content-cards--icon-small .module-header {
  margin-bottom: 55px;
}
.content-cards--grades .module-card__inner:hover {
  transform: none;
}
@media (min-width: 67.75em) {
  .content-cards[data-column="3"] .module-card {
    width: calc(33.33% - 27px);
  }
}
@media (min-width: 90.75em) {
  .content-cards[data-column="3"] .module-card {
    width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
@media (min-width: 67.75em) {
  .content-cards[data-column="3"] .module-card:not(:nth-child(3n)) {
    margin-right: 40px;
  }
}
@media (min-width: 90.75em) {
  .content-cards[data-column="3"] .module-card:not(:nth-child(3n)) {
    margin-right: 48px;
  }
}
@media (min-width: 67.75em) {
  .content-cards[data-column="3"] .module-card:nth-child(1n+4) {
    margin-top: 40px;
  }
}
@media (min-width: 67.75em) {
  .content-cards[data-column="3"] .module-card-text:nth-child(1n+4) {
    margin-top: 64px;
  }
}
@media (min-width: 76.25em) {
  .content-cards[data-column="4"] .module-card {
    width: calc(25% - 30px);
  }
}
@media (min-width: 67.75em) and (max-width: 76.24em) {
  .content-cards[data-column="4"] .module-card {
    width: calc(33.33% - 27px);
  }
  .content-cards[data-column="4"] .module-card:not(:nth-child(3n)) {
    margin-right: 40px;
  }
  .content-cards[data-column="4"] .module-card:nth-child(1n+4) {
    margin-top: 40px;
  }
}
@media (min-width: 90.75em) {
  .content-cards[data-column="4"] .module-card {
    width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
@media (min-width: 76.25em) {
  .content-cards[data-column="4"] .module-card:not(:nth-child(4n)) {
    margin-right: 28px;
  }
}
@media (min-width: 90.75em) {
  .content-cards[data-column="4"] .module-card:not(:nth-child(4n)) {
    margin-right: 28px;
  }
}
@media (min-width: 76.25em) {
  .content-cards[data-column="4"] .module-card:nth-child(1n+5) {
    margin-top: 40px;
  }
}

[data-view=template-dashboard] .content-cards .content-cards--icon .content-cards--tiles .content {
  padding-top: 0px;
}
[data-view=template-dashboard] .content-cards .content-cards--icon .content-cards--tiles .content .header {
  padding-top: 0px;
}
[data-view=template-dashboard] .content-cards .content-cards--icon .content-cards--tiles .content .header .header-inner {
  margin-left: 0px;
}

.content-column {
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 51.75em) {
  .content-column .module-faq,
  .content-column .module-text,
  .content-column .module-perks,
  .content-column .module-facts-slider {
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-column .module-faq,
  .content-column .module-text,
  .content-column .module-perks,
  .content-column .module-facts-slider {
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .content-column .module-faq,
  .content-column .module-text,
  .content-column .module-perks,
  .content-column .module-facts-slider {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 51.74em) {
  .content-column .module-faq,
  .content-column .module-text,
  .content-column .module-perks,
  .content-column .module-facts-slider {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}
.content-column .module-text__header {
  margin-bottom: -25px;
}
@media (min-width: 51.75em) {
  .content-column .module-text__content {
    margin-top: 55px;
  }
}
@media (min-width: 51.75em) {
  .content-column .module-faq {
    margin-top: 25px;
  }
}
@media (min-width: 51.75em) {
  .content-column .module-perks,
  .content-column .module-capture {
    margin-top: 40px;
  }
}
@media (min-width: 51.75em) {
  .content-column .module-video {
    margin-top: -70px;
  }
}
@media (min-width: 76.25em) {
  .content-column .module-video {
    margin-top: -215px;
  }
}
@media (min-width: 51.75em) {
  .content-column .module-image-basic {
    margin-top: 60px;
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (0 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (0 * 40px));
  }
}
@media (min-width: 51.75em) {
  .content-column:not(.content-quotes) .content__column {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (min-width: 67.75em) {
  .content-column:not(.content-quotes) .content__column {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (max-width: 51.74em) {
  .content-column:not(.content-quotes) .content__column:first-child {
    margin-bottom: 5px;
  }
}
@media (max-width: 51.74em) {
  .content-column .content__inner {
    flex-direction: column;
  }
}
@media (min-width: 51.75em) {
  .content-column--reflect .content__inner {
    flex-direction: row-reverse;
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .content-column--reflect .content__column:first-child .module-faq,
  .content-column--reflect .content__column:first-child .module-text,
  .content-column--reflect .content__column:first-child .module-perks,
  .content-column--reflect .content__column:first-child .module-facts-slider {
    padding-left: 30px;
  }
}
@media (min-width: 51.75em) {
  .content-column--reflect .content__column:first-child .module-image,
  .content-column--reflect .content__column:first-child .module-video,
  .content-column--reflect .content__column:first-child .module-quote {
    margin-left: auto;
  }
}
.content-column--reflect .content__column:first-child .module-quote {
  margin-right: auto;
}
@media (min-width: 76.25em) {
  .content-column--reflect .content__column:first-child .module-video {
    margin-left: 160px;
  }
}
@media (max-width: 51.74em) {
  .content-column--reflect .content__column:first-child .module-video {
    margin-bottom: -40px;
  }
}
.content-column--reflect .content__column:first-child .module-capture {
  margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-column--reflect .content__column:first-child .module-capture {
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-column--reflect .content__column:first-child .module-capture {
    margin-right: 0px;
    margin-left: 30px;
  }
}
@media (max-width: 51.74em) {
  .content-column--reflect .content__column:first-child .module-capture {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .content-column--reflect .content__column:last-child .module-faq,
  .content-column--reflect .content__column:last-child .module-text,
  .content-column--reflect .content__column:last-child .module-perks,
  .content-column--reflect .content__column:last-child .module-facts-slider {
    padding-right: 30px;
  }
}
@media (min-width: 51.75em) {
  .content-column--reflect .content__column:last-child .module-image,
  .content-column--reflect .content__column:last-child .module-video {
    margin-right: auto;
  }
}
.content-column--reflect .content__column:last-child .module-quote {
  margin-left: auto;
}
@media (min-width: 51.75em) {
  .content-column--reflect .content__column:last-child .module-video {
    margin-left: -20px;
  }
}
@media (min-width: 76.25em) {
  .content-column--reflect .content__column:last-child .module-video {
    margin-left: -250px;
  }
}
@media (max-width: 51.74em) {
  .content-column--reflect .content__column:last-child .module-video {
    margin-bottom: -40px;
  }
}
.content-column--reflect .content__column:last-child .module-capture {
  margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-column--reflect .content__column:last-child .module-capture {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-column--reflect .content__column:last-child .module-capture {
    margin-left: 0;
    margin-right: 30px;
  }
}
@media (max-width: 51.74em) {
  .content-column--reflect .content__column:last-child .module-capture {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 51.74em) {
  .content-column--mobile-reflect .content__inner {
    flex-direction: column-reverse;
  }
}
@media (max-width: 51.74em) {
  .content-column--mobile-reflect .content__column:first-child {
    margin-bottom: 0;
  }
}
@media (max-width: 51.74em) {
  .content-column--mobile-reflect .module-image {
    margin-top: -30px;
    margin-bottom: -20px;
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-faq,
  .content-column:not(.content-column--reflect) .content__column:first-child .module-text,
  .content-column:not(.content-column--reflect) .content__column:first-child .module-perks,
  .content-column:not(.content-column--reflect) .content__column:first-child .module-facts-slider {
    padding-right: 30px;
  }
}
@media (min-width: 51.75em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-image,
  .content-column:not(.content-column--reflect) .content__column:first-child .module-video {
    margin-right: auto;
  }
}
.content-column:not(.content-column--reflect) .content__column:first-child .module-quote {
  margin-left: auto;
}
@media (min-width: 76.25em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-video {
    margin-left: -250px;
  }
}
@media (max-width: 51.74em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-video {
    margin-bottom: -40px;
  }
}
.content-column:not(.content-column--reflect) .content__column:first-child .module-capture {
  margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-capture {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-capture {
    margin-left: 0;
    margin-right: 30px;
  }
}
@media (max-width: 51.74em) {
  .content-column:not(.content-column--reflect) .content__column:first-child .module-capture {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-faq,
  .content-column:not(.content-column--reflect) .content__column:last-child .module-text,
  .content-column:not(.content-column--reflect) .content__column:last-child .module-perks,
  .content-column:not(.content-column--reflect) .content__column:last-child .module-facts-slider {
    padding-left: 30px;
  }
}
@media (min-width: 51.75em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-image,
  .content-column:not(.content-column--reflect) .content__column:last-child .module-video {
    margin-left: auto;
  }
}
.content-column:not(.content-column--reflect) .content__column:last-child .module-quote {
  margin-right: auto;
}
@media (min-width: 76.25em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-video {
    margin-left: -250px;
  }
}
.content-column:not(.content-column--reflect) .content__column:last-child .module-capture {
  margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-capture {
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-capture {
    margin-right: 0px;
    margin-left: 30px;
  }
}
@media (max-width: 51.74em) {
  .content-column:not(.content-column--reflect) .content__column:last-child .module-capture {
    margin-right: 0;
    margin-left: 0;
  }
}

.content-archive {
  padding-top: 0;
}
.content-archive .module-filters-bar {
  margin-bottom: 74px;
}
@media (max-width: 51.74em) {
  .content-archive .module-filters-bar {
    margin-bottom: 45px;
  }
}
.content-archive__items {
  display: flex;
  flex-wrap: wrap;
}
.content-archive .module-card {
  width: 100%;
}
.content-archive .content-join {
  width: 100%;
  margin-top: 140px;
  margin-bottom: 120px;
  padding-top: 0;
  padding-bottom: 0;
}
.content-archive .content-join:last-child {
  margin-bottom: 25px;
}
.content-archive .module-pagination {
  margin-top: 72px;
}
.content-archive[data-column="1"] .module-card {
  width: 100%;
  margin-bottom: 25px;
}
.content-archive[data-column="1"] .module-card-program {
  margin-bottom: 0;
}
.content-archive[data-column="1"] .module-card-program + .module-card-program {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid transparent;
}
.content-archive[data-column="3"] .module-card {
  width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (max-width: 76.24em) {
  .content-archive[data-column="3"] .module-card {
    width: calc(33.33% - 14px);
  }
}
@media (max-width: 67.74em) {
  .content-archive[data-column="3"] .module-card {
    width: calc(50% - 10px);
  }
}
@media (max-width: 47.75em) {
  .content-archive[data-column="3"] .module-card {
    width: 100%;
  }
}
@media (min-width: 90.75em) {
  .content-archive[data-column="3"] .module-card {
    width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
@media (min-width: 47.75em) and (max-width: 67.74em) {
  .content-archive[data-column="3"] .module-card:not(:nth-of-type(2n)) {
    margin-right: 20px;
  }
}
@media (max-width: 47.75em) {
  .content-archive[data-column="3"] .module-card:not(:last-child) {
    margin-bottom: 44px;
  }
}
@media (min-width: 67.75em) {
  .content-archive[data-column="3"] .module-card:not(:nth-of-type(3n)) {
    margin-right: 20px;
  }
}
@media (min-width: 76.25em) {
  .content-archive[data-column="3"] .module-card:not(:nth-of-type(3n)) {
    margin-right: 40px;
  }
}
@media (min-width: 47.75em) and (max-width: 67.74em) {
  .content-archive[data-column="3"] .module-card:nth-of-type(3) {
    margin-top: 40px;
  }
}
@media (min-width: 47.75em) {
  .content-archive[data-column="3"] .module-card:nth-of-type(1n + 4) {
    margin-top: 40px;
  }
}
.content-archive[data-column="4"] .module-card {
  width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
}
@media (min-width: 90.75em) {
  .content-archive[data-column="4"] .module-card {
    width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
.content-archive[data-column="4"] .module-card:not(:nth-of-type(4n)) {
  margin-right: 40px;
}
.content-archive[data-column="4"] .module-card:nth-of-type(1n + 5) {
  margin-top: 40px;
}

.content-callout {
  padding-top: 20px;
  padding-bottom: 80px;
}
.content-callout .module-image {
  margin-right: auto;
  margin-bottom: -15px;
  margin-left: auto;
}
@media (max-width: 51.74em) {
  .content-callout .module-image {
    margin-bottom: 15px;
  }
}
.content-callout .module-header {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 90.75em) {
  .content-callout .module-header {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
.content-callout--orbits {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 51.74em) {
  .content-callout--orbits {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.content-facts-slider {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 51.74em) {
  .content-facts-slider:not(.content-quotes) .content__column:first-child {
    margin-bottom: 0;
  }
}
@media (max-width: 51.74em) {
  .content-facts-slider .module-facts-slider__list-item:last-child {
    padding-bottom: 0;
  }
  .content-facts-slider .module-facts-slider__list-item:last-child .module-facts-slider__list-item__nav-progress {
    display: none;
  }
}
@media (max-width: 51.74em) {
  .content-facts-slider .module-image-basic {
    display: none;
  }
}
@media (min-width: 51.75em) {
  .content-facts-slider .module-facts-slider__list-item-image {
    display: none;
  }
}
.content-facts-slider .module-image-basic__item {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}
.content-facts-slider .module-image-basic__item.is-active {
  opacity: 1;
}
.content-facts-slider.is-playing .svg--play {
  display: none;
}
.content-facts-slider:not(.is-playing) .svg--pause {
  display: none;
}
.content-facts-slider:not(.is-playing) .module-facts-slider__list-item__nav-progress-bar {
  animation: none;
}

.content-flexible {
  padding-top: 0;
}
.content-flexible__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 67.74em) {
  .content-flexible__inner {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.content-flexible.content .content-flexible__aside .module-header {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}
@media (max-width: 67.74em) {
  .content-flexible.content .content-flexible__aside .module-header {
    margin-left: 0;
  }
}
.content-flexible.content .content-flexible__aside .module-header__text {
  margin-bottom: 0;
}
.content-flexible__aside {
  flex-shrink: 0;
  flex-basis: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  margin-right: calc((1 * calc((1160px - 440px) / 12)) + (2 * 40px));
}
@media (max-width: 67.74em) {
  .content-flexible__aside {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    margin-bottom: 60px;
    margin-right: 0;
    width: 100%;
  }
}
@media (max-width: 51.74em) {
  .content-flexible__aside {
    width: 100%;
    margin-right: 0;
  }
}
@media (min-width: 90.75em) {
  .content-flexible__aside {
    flex-basis: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
@media (max-width: 67.74em) {
  .content-flexible__aside .sidebar-author {
    order: 1;
    margin-top: 0px;
  }
}
@media (max-width: 67.74em) {
  .content-flexible__aside .sidebar-callout {
    margin-top: 50px;
    order: 2;
  }
}
@media (max-width: 67.74em) {
  .content-flexible__aside .sidebar-menu {
    order: 3;
    margin-top: 50px;
  }
}
@media (max-width: 67.74em) {
  .content-flexible__aside .sidebar-text {
    display: none;
  }
}
.content-flexible__content {
  flex: 1;
  flex-basis: 100%;
}
@media (max-width: 67.74em) {
  .content-flexible__content {
    width: 100%;
  }
}
.content-flexible__content .module-form {
  margin-top: 10px;
}
.content-flexible__content .module-form + * {
  margin-top: 80px;
}

.content-partners {
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-partners .module-header {
  margin-bottom: 65px;
}
.content-partners .module-header__text {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
}
.content-partners .module-partners {
  margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (max-width: 51.74em) {
  .content-partners .module-partners {
    margin-right: auto;
    margin-left: auto;
    max-width: 560px;
  }
}
@media (min-width: 90.75em) {
  .content-partners .module-partners {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}

.content-schedule {
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-schedule .module-header {
  margin-bottom: 55px;
}
@media (min-width: 51.75em) {
  .content-schedule .module-schedule {
    margin-right: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
    margin-left: calc((1 * calc((1160px - 440px) / 12)) + (1 * 40px));
  }
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-schedule .module-schedule {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 90.75em) {
  .content-schedule .module-schedule {
    margin-right: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
    margin-left: calc((1 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (min-width: 67.75em) {
  .content-schedule .module-schedule .module-tabs {
    margin-right: calc((0 * calc((1160px - 440px) / 12)) + (-1 * 40px));
    margin-left: calc((0 * calc((1160px - 440px) / 12)) + (-1 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-schedule .module-schedule .module-tabs {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (-1 * 48px));
    margin-left: calc((0 * calc((1392px - 528px) / 12)) + (-1 * 48px));
  }
}

.content-keypoints {
  padding-top: 0;
  padding-bottom: 0;
}
.content-keypoints .content__column {
  margin-top: 60px;
  margin-bottom: 60px;
  min-width: initial;
  max-width: initial;
}
@media (max-width: 67.74em) {
  .content-keypoints .content__column {
    margin-top: 0;
    margin-bottom: 0;
  }
  .content-keypoints .content__column:last-child .content-keypoints__list .content-keypoints__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 67.74em) {
  .content-keypoints .content__inner {
    padding-top: 110px;
    padding-bottom: 110px;
    flex-direction: column;
  }
}
@media (max-width: 51.74em) {
  .content-keypoints .content__inner {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.content-keypoints__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 67.74em) {
  .content-keypoints__list {
    flex-direction: column;
  }
}
.content-keypoints__item {
  text-align: center;
}
@media (min-width: 51.75em) and (max-width: 67.74em) {
  .content-keypoints__item {
    text-align: center;
  }
}
@media (max-width: 67.74em) {
  .content-keypoints__item {
    height: auto;
    width: 100%;
    margin-bottom: 22px;
  }
}
@media (max-width: 51.74em) {
  .content-keypoints__item {
    text-align: left;
  }
}
.content-keypoints__index {
  margin-top: -8px;
  margin-bottom: 15px;
  font-size: 0.6875rem;
  line-height: 1.2727272727;
  letter-spacing: 3px;
  text-transform: uppercase;
}
@media (max-width: 67.74em) {
  .content-keypoints__index {
    margin-top: 0;
    flex-shrink: 0;
  }
}
.content-keypoints__text {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
}
@media (min-width: 67.75em) and (max-width: 76.24em) {
  .content-keypoints__text {
    font-size: 1.125rem;
  }
}
@media (max-width: 67.74em) {
  .content-keypoints__text {
    position: relative;
    top: -3px;
    height: auto;
    width: 100%;
    margin-left: 13px;
  }
  .content-keypoints__text br {
    display: none;
  }
}
.content-keypoints__line {
  display: none;
}

.content-key-numbers {
  padding-top: 0;
  padding-bottom: 0;
}
.content-key-numbers .content__column {
  margin-top: 60px;
  margin-bottom: 60px;
  min-width: initial;
  max-width: initial;
}
@media (max-width: 67.74em) {
  .content-key-numbers .content__column {
    margin-top: 0;
    margin-bottom: 0;
  }
  .content-key-numbers .content__column:last-child .content-key-numbers__list .content-key-numbers__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 67.74em) {
  .content-key-numbers .content__inner {
    padding-top: 110px;
    padding-bottom: 110px;
    flex-direction: column;
  }
}
@media (max-width: 51.74em) {
  .content-key-numbers .content__inner {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.content-key-numbers__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 67.74em) {
  .content-key-numbers__list {
    flex-direction: column;
  }
}
.content-key-numbers__item {
  text-align: center;
}
@media (min-width: 76.25em) {
  .content-key-numbers__item {
    flex-basis: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  }
}
@media (max-width: 67.74em) {
  .content-key-numbers__item {
    height: auto;
    width: 100%;
    margin-bottom: 22px;
  }
}
.content-key-numbers__value {
  margin-bottom: 6px;
  font-size: 2.625rem;
  line-height: 1.4285714286;
  letter-spacing: -0.5px;
}
@media (max-width: 67.74em) {
  .content-key-numbers__value {
    font-size: 2rem;
    line-height: 1.5625;
  }
}
.content-key-numbers__text {
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
@media (max-width: 67.74em) {
  .content-key-numbers__text br {
    display: none;
  }
}
.content-key-numbers__line {
  display: none;
}

.content-cards-slider__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.content-cards-slider__header--centered {
  justify-content: center;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
  max-width: 800px;
  text-align: center;
}
.content-cards-slider .content-cards-slider__header .module-header {
  max-width: 100%;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: left;
}
.content-cards-slider .content-cards-slider__header .module-header + .content-cards-slider__controls {
  margin-left: 10px;
}
.content-cards-slider .content-cards-slider__header--centered .module-header {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media (min-width: 90.75em) {
  .content-cards-slider .content-cards-slider__header--centered .module-header {
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (5 * 48px));
  }
}
.content-cards-slider__title {
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -1px;
}
@media (min-width: 90.75em) {
  .content-cards-slider__title {
    font-size: 2.5rem;
  }
}
.content-cards-slider__text {
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: -0.4px;
}
.content-cards-slider__controls {
  display: flex;
  align-items: center;
}
.content-cards-slider__controls .button {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.content-cards-slider__controls .button:last-child {
  margin-left: 12px;
}
.content-cards-slider__controls .button[disabled] {
  opacity: 0;
  cursor: not-allowed;
}
.content-cards-slider__list {
  position: relative;
}
.content-cards-slider__list .content-cards-slider__controls {
  position: absolute;
  top: 50%;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 4;
  pointer-events: none;
  transform: translateY(-50%);
  justify-content: space-between;
}
@media (max-width: 51.74em) {
  .content-cards-slider__list .content-cards-slider__controls {
    display: none;
  }
}
.content-cards-slider__list .content-cards-slider__controls .button {
  pointer-events: auto;
  box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);
}
.content-cards-slider__list .content-cards-slider__controls .button:first-child {
  transform: translateX(-50%);
}
.content-cards-slider__list .content-cards-slider__controls .button:last-child {
  transform: translateX(50%);
}
.content-cards-slider__footer {
  margin-top: 50px;
  text-align: center;
}
.content-cards-slider .tns-slider {
  display: flex;
}
.content-cards-slider .tns-item {
  transition: opacity 0.4s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.4s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (min-width: 67.75em) {
  .content-cards-slider .tns-item[aria-hidden=true] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
@media (max-width: 67.74em) {
  .content-cards-slider .module-card {
    width: 336px;
    flex-shrink: 0;
  }
}
.content-cards-slider--activity {
  padding-top: 120px;
  padding-bottom: 120px;
}
.content-cards-slider--disabled .content-cards-slider__title {
  width: 100%;
  text-align: center;
}

.content-quotes {
  --positionTop: 0px;
  --positionBottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
}
.content-quotes .content__column {
  min-width: initial;
  max-width: initial;
  margin-top: 60px;
  margin-bottom: 12px;
}
.content-quotes .content__column:first-child {
  margin-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes .content__column:first-child {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.content-quotes .content__column:last-child {
  margin-left: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes .content__column:last-child {
    margin-left: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
@media (max-width: 51.74em) {
  .content-quotes .content__column:last-child {
    margin-top: 0px;
  }
  .content-quotes .content__column:last-child .module-quote {
    margin-top: 0;
  }
}
@media (min-width: 51.75em) {
  .content-quotes__mobile {
    display: none;
  }
}
.content-quotes__mobile .content-quotes__button {
  margin-top: 30px;
  text-align: center;
}
@media (max-width: 51.74em) {
  .content-quotes__desktop {
    display: none;
  }
}
.content-quotes__desktop .module-quote {
  margin-bottom: 48px;
}
.content-quotes__desktop .module-quote--3 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes__desktop .module-quote--3 {
    max-width: calc((3 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes__desktop .module-quote--2 {
  margin-top: 120px;
}
.content-quotes__images {
  position: absolute;
  left: 50%;
  height: 140px;
  width: 140px;
  margin-top: 60px;
  margin-left: -70px;
  top: var(--positionTop);
  bottom: var(--positionBottom);
}
.content-quotes__images-list {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin-top: -40px;
  margin-left: -40px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid transparent;
}
.content-quotes__images-list img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 50%;
}
.content-quotes__images-list img.is-active {
  opacity: 1;
}
.content-quotes__mobile {
  margin-top: 90px;
}
.content-quotes__slider {
  display: flex;
}
.content-quotes__expand [aria-expanded=false] .content-quotes__expand-expanded {
  display: none;
}
.content-quotes__expand [aria-expanded=true] .content-quotes__expand-unexpanded {
  display: none;
}
.content-quotes__expand [aria-expanded=true] .svg--caret-down {
  transform: rotate(180deg);
}
.content-quotes__expand, .content-quotes__button {
  display: block;
  margin-bottom: 48px;
}
.content-quotes__hidden {
  display: none;
}
.content-quotes__hidden.is-visible {
  display: block;
}
.content-quotes--multiple .module-quote {
  max-width: 100%;
  max-width: 100%;
  max-width: 100%;
  max-width: 100%;
  max-width: 100%;
  max-width: 100%;
}
.content-quotes--multiple .module-quote--1, .content-quotes--multiple .module-quote--4 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--1, .content-quotes--multiple .module-quote--4 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--5 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--5 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes--multiple .module-quote--6, .content-quotes--multiple .module-quote--9 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--6, .content-quotes--multiple .module-quote--9 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--10 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--10 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes--multiple .module-quote--11, .content-quotes--multiple .module-quote--14 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--11, .content-quotes--multiple .module-quote--14 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--15 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--15 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes--multiple .module-quote--16, .content-quotes--multiple .module-quote--19 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--16, .content-quotes--multiple .module-quote--19 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--20 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--20 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes--multiple .module-quote--21, .content-quotes--multiple .module-quote--24 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--21, .content-quotes--multiple .module-quote--24 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--25 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--25 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
.content-quotes--multiple .module-quote--26, .content-quotes--multiple .module-quote--29 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (4 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--26, .content-quotes--multiple .module-quote--29 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
.content-quotes--multiple .module-quote--30 {
  max-width: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes--multiple .module-quote--30 {
    max-width: calc((4 * calc((1392px - 528px) / 12)) + (3 * 48px));
  }
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view .content-quotes__images svg {
  transform: rotate(90deg) scale(0);
  transition: transform 0.8s 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view .content-quotes__images-list {
  clip-path: circle(8px);
  transition: clip-path 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 0.2s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view .content-quotes__images-list img {
  opacity: 0;
  transform: scale(1.15);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.5s 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view.in-view .content-quotes__images svg {
  transform: rotate(0deg) scale(1);
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view.in-view .content-quotes__images-list {
  clip-path: circle(50%);
  background-color: #fff;
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view.in-view .content-quotes__images-list img {
  transform: scale(1);
}
html:not(.mobile):not(.ie) .content-quotes.js-in-view.in-view .content-quotes__images-list img.is-active {
  opacity: 1;
}

@media (max-width: 67.74em) {
  .sidebar-author {
    display: flex;
    align-items: center;
  }
}
.sidebar-author__image {
  height: 100px;
  width: 100px;
  margin-bottom: 32px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  flex-shrink: 0;
}
.sidebar-author__image img {
  height: 100%;
  width: 100%;
  display: block;
}
.object-fit .sidebar-author__image img {
  object-fit: cover;
}
.no-object-fit .sidebar-author__image img {
  font-family: "object-fit: cover";
}
@media (max-width: 67.74em) {
  .sidebar-author__image {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
@media (max-width: 51.74em) {
  .sidebar-author__image {
    height: 72px;
    width: 72px;
  }
}
.sidebar-author__content {
  width: 100%;
}
.sidebar-author__title {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
}
.sidebar-author__text {
  margin-top: 12px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.sidebar-author__text a {
  text-decoration: underline;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sidebar-author + * {
  margin-top: 48px;
}

.widget__title {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  font-size: 0.6875rem;
  line-height: 2;
  letter-spacing: 3px;
  text-transform: uppercase;
}
@media (max-width: 51.74em) {
  .widget__title {
    margin-bottom: 30px;
  }
}
.widget__title::after {
  content: "";
  flex: 1;
  width: auto;
  height: 1px;
  margin-left: 12px;
}
.widget + .widget {
  margin-top: 80px;
}
@media (max-width: 51.74em) {
  .widget + .widget {
    margin-top: 60px;
  }
}

.widget-text__lead {
  margin-bottom: 32px;
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -1.1px;
}
@media (max-width: 51.74em) {
  .widget-text__lead {
    font-size: 1.75rem;
    letter-spacing: -0.6px;
  }
}
.widget-text__typeset {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .widget-text__typeset {
    font-size: 1rem;
    letter-spacing: -0.6px;
  }
}
.widget-text__typeset h2:not(.widget__title),
.widget-text__typeset h3 {
  font-size: 1.75rem;
  line-height: 1.1428571429;
  letter-spacing: -0.6px;
}
.widget-text__typeset h2:not(.widget__title) + *,
.widget-text__typeset h3 + * {
  margin-top: 24px;
}
.widget-text__typeset h4 {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.7px;
}
.widget-text__typeset h4 + * {
  margin-top: 16px;
}
.widget-text__typeset h5,
.widget-text__typeset h6 {
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  line-height: 2;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.widget-text__typeset h5::after,
.widget-text__typeset h6::after {
  content: "";
  flex: 1;
  width: auto;
  height: 1px;
  margin-left: 12px;
}
.widget-text__typeset h5 + *,
.widget-text__typeset h6 + * {
  margin-top: 48px;
}
.widget-text__typeset p + p {
  margin-top: 20px;
}
.widget-text__typeset p + ul,
.widget-text__typeset p + ol {
  margin-top: 32px;
}
.widget-text__typeset p + h2,
.widget-text__typeset p + h3,
.widget-text__typeset p + h4,
.widget-text__typeset p + h5,
.widget-text__typeset p + h6 {
  margin-top: 80px;
}
.widget-text__typeset a:not(.button) {
  display: inline-block;
  line-height: 1.2;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.widget-text__typeset ul li,
.widget-text__typeset ol li {
  position: relative;
  padding-left: 26px;
}
.widget-text__typeset ul li + li,
.widget-text__typeset ol li + li {
  margin-top: 15px;
}
.widget-text__typeset ul + p,
.widget-text__typeset ul + ul,
.widget-text__typeset ul + ol,
.widget-text__typeset ol + p,
.widget-text__typeset ol + ul,
.widget-text__typeset ol + ol {
  margin-top: 32px;
}
.widget-text__typeset ul + h2,
.widget-text__typeset ul + h3,
.widget-text__typeset ul + h4,
.widget-text__typeset ul + h5,
.widget-text__typeset ul + h6,
.widget-text__typeset ol + h2,
.widget-text__typeset ol + h3,
.widget-text__typeset ol + h4,
.widget-text__typeset ol + h5,
.widget-text__typeset ol + h6 {
  margin-top: 80px;
}
.widget-text__typeset ul li::before {
  position: absolute;
  top: 10px;
  left: 0;
  height: 6px;
  width: 6px;
  content: "";
  border-radius: 50%;
}
.widget-text__typeset ol {
  counter-reset: count;
}
.widget-text__typeset ol li {
  counter-increment: count;
}
.widget-text__typeset ol li::before {
  content: counter(count);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9375rem;
  line-height: 1.8666666667;
  letter-spacing: -0.47px;
}
.widget-text__button {
  margin-top: 16px;
}

.widget-quote {
  padding-left: 40px;
  border-left: 6px solid transparent;
}
@media (max-width: 51.74em) {
  .widget-quote {
    padding-left: 24px;
  }
}
.widget-quote p {
  font-size: 1.75rem;
  line-height: 1.1428571429;
  letter-spacing: -0.6px;
}
@media (max-width: 51.74em) {
  .widget-quote p {
    font-size: 1.5rem;
    letter-spacing: -0.5px;
  }
}
.widget-quote p + cite {
  margin-top: 24px;
}
.widget-quote cite {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  letter-spacing: 0.7px;
}

.widget-image {
  display: block;
  overflow: hidden;
}
.widget-image img,
.widget-image picture {
  display: block;
  width: 100%;
  height: auto;
}

.widget-video {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.widget-video:before {
  padding-top: 56.25%;
  display: block;
  content: "";
}
.widget-video__embed, .widget-video__poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.widget-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 72px;
  width: 72px;
  margin-top: -36px;
  margin-left: -36px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (max-width: 51.74em) {
  .widget-video__play {
    height: 56px;
    width: 56px;
    margin-top: -28px;
    margin-left: -28px;
  }
}
.widget-video__play .svg {
  transition: fill 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.widget-video__poster img,
.widget-video__poster picture {
  display: block;
  width: 100%;
  height: 100%;
}
.object-fit .widget-video__poster img {
  object-fit: cover;
}
.no-object-fit .widget-video__poster img {
  font-family: "object-fit: cover";
}
.widget-video__poster .button-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -36px;
}
.no-touchevents .widget-video__poster:hover .svg, .no-touchevents .widget-video__poster:focus .svg {
  fill: #fff;
}

.widget-videos__item {
  display: flex;
  align-items: center;
  padding: 40px;
  border: 1px solid transparent;
}
@media (max-width: 37.49em) {
  .widget-videos__item {
    padding: 32px;
    flex-direction: column-reverse;
  }
}
.widget-videos__item-content {
  flex: 1;
  margin-right: calc((0 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .widget-videos__item-content {
    margin-right: calc((0 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.widget-videos__item-title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.7px;
}
@media (max-width: 51.74em) {
  .widget-videos__item-title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
  }
}
.widget-videos__item-text {
  margin-top: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.widget-videos__item-video {
  flex-basis: calc((4 * calc((1160px - 440px) / 12)) + (3 * 40px));
}
@media (max-width: 51.74em) {
  .widget-videos__item-video {
    flex-basis: 50%;
  }
}
@media (max-width: 37.49em) {
  .widget-videos__item-video {
    flex-basis: auto;
    width: 100%;
    margin-bottom: 35px;
  }
}
@media (min-width: 37.5em) {
  .widget-videos__item-video .widget-video {
    min-height: 240px;
  }
}
.widget-videos__item-video .widget-video .button-circle {
  height: 56px;
  width: 56px;
  margin-top: -28px;
  margin-left: -28px;
}
.widget-videos__item-video .widget-video .button-circle svg {
  width: 10px;
  height: 12px;
}
.widget-videos__item + .widget-videos__item {
  margin-top: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.popup__close {
  z-index: 5;
}
.popup__close.button-circle {
  position: absolute;
  top: 40px;
  right: 40px;
}
@media (max-width: 51.74em) {
  .popup__close.button-circle {
    top: 20px;
    right: 27px;
  }
}
.popup__inner {
  height: 100%;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media (max-width: 37.49em) {
  .popup__inner {
    padding-right: 28px;
    padding-left: 28px;
  }
}
.popup__spacer {
  flex: 1;
  flex-shrink: 0;
  min-height: 50px;
}
@media (max-width: 51.74em) {
  .popup__spacer {
    min-height: 86px;
  }
}
.popup__content {
  height: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  flex-shrink: 0;
  overflow: hidden;
  max-width: 840px;
  border-radius: 24px;
  background-color: #fff;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.popup__content .button {
  position: relative;
  z-index: 2;
}
.popup__content:not(.is-ready) {
  opacity: 0;
}
.popup.js-popup-video .popup__content {
  max-width: 1160px;
}
@media (max-width: 51.74em) {
  .popup.popup-subscription .popup__content {
    max-width: 560px;
    margin-right: auto;
    margin-left: auto;
  }
}
.popup.is-visible {
  visibility: visible;
  opacity: 1;
}

.module-popup-form {
  padding: 75px 110px;
  text-align: center;
}
@media (max-width: 51.74em) {
  .module-popup-form {
    padding-right: 32px;
    padding-left: 32px;
  }
}
.module-popup-form .module-header {
  margin-bottom: 45px;
}
.module-popup-form .module-header__title {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  letter-spacing: -1px;
}
@media (max-width: 51.74em) {
  .module-popup-form .module-header__title {
    font-size: 1.5rem;
    letter-spacing: -0.6px;
  }
}
.module-popup-form .module-form__footer {
  flex-direction: column;
  align-items: center;
}
.module-popup-form .module-form__submit {
  margin-bottom: 30px;
}
.module-popup-form .module-form__messages,
.module-popup-form .module-form__submit {
  flex-basis: auto;
}

.js-popup-form .popup__content {
  max-width: 760px;
}

.module-popup-video {
  --plyr-control-radius: 0px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-track-height: 4px;
}
.module-popup-video > div[class=""] {
  position: relative;
}
.module-popup-video > div[class=""]:before {
  padding-top: 56.25%;
  display: block;
  content: "";
}
.module-popup-video > div[class=""] iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.module-popup-video .plyr__controls {
  flex-wrap: wrap;
  padding-right: 40px;
  padding-bottom: 35px;
  padding-left: 40px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
}
@media (max-width: 51.74em) {
  .module-popup-video .plyr__controls {
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
}
.module-popup-video .plyr__progress__container {
  order: 0;
  flex: none;
  width: 100%;
  margin-right: auto;
  margin-bottom: 10px;
  padding-left: 0 !important;
}
.module-popup-video [data-plyr=play] {
  order: 1;
  margin-right: 0 !important;
}
.module-popup-video .plyr__volume {
  order: 2;
}
.module-popup-video .plyr__time {
  margin-right: auto;
  order: 3;
}
.module-popup-video [data-plyr=captions] {
  order: 4;
}
.module-popup-video .plyr__menu {
  order: 5;
}
.module-popup-video [data-plyr=pip] {
  order: 6;
}
.module-popup-video [data-plyr=fullscreen] {
  order: 7;
}
.module-popup-video .plyr__control--overlaid,
.module-popup-video .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: none;
}

.module-popup-general {
  padding: 75px 110px;
  text-align: center;
}
@media (max-width: 51.74em) {
  .module-popup-general {
    padding-right: 32px;
    padding-left: 32px;
  }
}
.module-popup-general .module-header__title {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  letter-spacing: -1px;
}
@media (max-width: 51.74em) {
  .module-popup-general .module-header__title {
    font-size: 1.5rem;
    letter-spacing: -0.6px;
  }
}

.js-popup-general .popup__content {
  max-width: 760px;
}

.module-popup-activity {
  display: flex;
}
@media (max-width: 51.74em) {
  .module-popup-activity {
    flex-direction: column;
  }
}
.module-popup-activity__image {
  position: relative;
  height: auto;
  width: 380px;
  flex-shrink: 0;
}
@media (max-width: 51.74em) {
  .module-popup-activity__image {
    position: relative;
    height: auto;
    width: 100%;
    max-height: 360px;
  }
  .module-popup-activity__image:before {
    padding-top: 66.875%;
    display: block;
    content: "";
  }
}
.module-popup-activity__image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.object-fit .module-popup-activity__image img {
  object-fit: cover;
  object-position: center;
}
.no-object-fit .module-popup-activity__image img {
  font-family: "object-fit: cover; object-position: center";
  object-position: center;
}
.module-popup-activity__content {
  padding: 85px 60px;
}
@media (max-width: 51.74em) {
  .module-popup-activity__content {
    padding: 55px 32px;
    text-align: center;
  }
}
.module-popup-activity__content-title {
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -1px;
}
@media (max-width: 51.74em) {
  .module-popup-activity__content-title {
    font-size: 1.75rem;
    letter-spacing: -0.6px;
  }
}
.module-popup-activity__content-text {
  max-width: 460px;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.module-popup-activity__content-text a {
  text-decoration: underline;
}
.module-popup-activity__content-text--s {
  margin-top: 12px;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  letter-spacing: -0.4px;
}
.module-popup-activity__content .button {
  margin-top: 46px;
}
@media (max-width: 51.74em) {
  .module-popup-activity__content .button {
    margin-top: 36px;
  }
}

.module-popup-subscription {
  display: flex;
}
@media (max-width: 51.74em) {
  .module-popup-subscription {
    flex-direction: column;
  }
}
.module-popup-subscription__perks, .module-popup-subscription__content {
  padding: 85px 60px;
}
@media (max-width: 51.74em) {
  .module-popup-subscription__perks, .module-popup-subscription__content {
    padding: 50px 32px;
  }
}
@media (max-width: 51.74em) {
  .module-popup-subscription__content {
    width: 100%;
    text-align: center;
  }
}
.module-popup-subscription__content-title {
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -0.8px;
}
@media (max-width: 51.74em) {
  .module-popup-subscription__content-title {
    font-size: 1.75rem;
    letter-spacing: -0.6px;
  }
}
.module-popup-subscription__content-text {
  margin-top: 32px;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.6px;
}
.module-popup-subscription__content-text a {
  text-decoration: underline;
}
.module-popup-subscription__content-text--s {
  margin-top: 12px;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  letter-spacing: -0.4px;
}
.module-popup-subscription__content .button {
  margin-top: 46px;
}
.module-popup-subscription__perks {
  width: 45.3%;
  flex-shrink: 0;
}
@media (max-width: 51.74em) {
  .module-popup-subscription__perks {
    width: 100%;
  }
}
.module-popup-subscription__perks-title {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.7px;
}
@media (max-width: 51.74em) {
  .module-popup-subscription__perks-title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    text-align: center;
  }
}
.module-popup-subscription__perks-list {
  margin-top: 40px;
}
.module-popup-subscription__perks-list-item {
  display: flex;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.5px;
}
.module-popup-subscription__perks-list-item .svg {
  flex-shrink: 0;
  margin-top: 4px;
  margin-right: 16px;
  fill: currentColor;
}
.module-popup-subscription__perks-list-item:not(:first-child) {
  margin-top: 23px;
}

.tag {
  padding-top: 1px;
  padding-bottom: 0;
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  line-height: 24px;
}
.tag .svg {
  margin-top: -2px;
}
.tag--purple-faded {
  color: #a51890;
  background-color: rgba(165, 24, 144, 0.1);
}
.tag--purple-faded::before {
  display: none;
}
.tag--purple-bright {
  color: #a51890;
  background-color: #edd0e8;
}
.tag--orange-bright {
  color: #ff5f24;
  background-color: rgba(255, 95, 36, 0.1);
}
.tag--white {
  color: #a51890;
  background-color: #fff;
}

.circle {
  position: relative;
  display: block;
  pointer-events: none;
  backface-visibility: hidden;
}
.circle::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-image: url("../assets/images/decorations/deco-ellipse.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media (max-width: 37.49em) {
  .circle::before {
    height: 70%;
    width: 70%;
  }
}

.eyebrow {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #5f71a5;
}
.eyebrow--blue {
  color: #5f71a5;
}
.eyebrow--white {
  color: #fff;
}
.eyebrow--blue-bright {
  color: #0067e2;
}
.eyebrow--blue-brighter {
  color: #003fce;
}
.eyebrow--purple-bright {
  color: #a51890;
}

.dropdown__list-item-link {
  color: #a51890;
}

.header-inner__title {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  letter-spacing: -2px;
}
.header-inner__text, .header-inner__metas {
  color: #5f71a5;
}
.header-inner__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
}
.header-inner__metas {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
}

.site-header__bar {
  transition: border-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-color: #FCD70E;
}
body.submenu-is-open .site-header__bar {
  border-color: rgba(226, 225, 210, 0.2);
}
.site-header__bar::before {
  background-color: #ffe20e;
}
.site-header__logo {
  padding-right: 36px;
  padding-left: 35px;
  border-color: rgba(6, 41, 78, 0.05);
}
@media (max-width: 80em) {
  .site-header__logo {
    padding-right: 32px;
    padding-left: 30px;
  }
}
.site-header__logo-icw {
  width: 164px;
  flex-shrink: 0;
  margin-bottom: -5px;
}
@media (max-width: 80em) {
  .site-header__logo-icw {
    width: 132px;
    margin-bottom: 0;
  }
}
.site-header__logo-icw-text {
  fill: #231f20;
}
.site-header__logo-icw-back {
  fill: #fff;
}
.site-header__logo-thf {
  width: 34px;
  margin-left: 8px;
  flex-shrink: 0;
}
@media (max-width: 80em) {
  .site-header__logo-thf {
    width: 28px;
    margin-bottom: 6px;
  }
}
.site-header__logo-thf-bg {
  fill: #231f20;
}
.site-header__logo-thf-text {
  fill: #fff;
}
.site-header__menu-button {
  border-color: #FCD70E;
}
.site-header__menu-button-bar {
  background-color: #231f20;
}
.site-header__desktop-submenu-backdrop {
  background-color: #fff;
}
.site-header__dropdown .dropdown__button {
  border-color: #d6eaff;
  color: rgba(6, 41, 78, 0.4);
}
.site-header__submenu-close-button {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #231f20;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: 0.5px;
}
@media (max-width: 76.24em) {
  .site-header__nav {
    background-color: #fff;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav .button {
    padding-top: 21px;
    padding-bottom: 21px;
    height: 52px;
    font-size: 0.6875rem;
  }
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav:last-child {
    border-bottom: 1px solid rgba(6, 41, 78, 0.05);
  }
}
@media (max-width: 76.24em) and (max-width: 76.24em) {
  .site-header__secondary-nav:first-child .site-header__secondary-nav-item:not(:first-child) .site-header__secondary-nav-item-button {
    border: 1px solid #d6eaff;
  }
}
.site-header__secondary-nav-item-select-wrapper .svg {
  fill: rgba(6, 41, 78, 0.4);
}
.site-header__secondary-nav-item-select {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  letter-spacing: 0.4px;
  color: #A27702;
  border: 1px solid rgba(162, 119, 2, 0.2);
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav-item-select {
    color: #5f71a5;
    border-color: #e2e1d2;
  }
}
.no-touchevents .site-header__secondary-nav-item-select:hover, .no-touchevents .site-header__secondary-nav-item-select:focus {
  border-color: #003fce;
  color: #003fce;
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav-item-select {
    font-size: 0.875rem;
  }
}
.site-header__secondary-nav-item-button {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  letter-spacing: 0.4px;
  color: #A27702;
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav-item-button {
    color: #5f71a5;
  }
}
.no-touchevents .site-header__secondary-nav-item-button:hover, .no-touchevents .site-header__secondary-nav-item-button:focus {
  color: #003fce;
}
@media (max-width: 76.24em) {
  .site-header__secondary-nav-item-button {
    font-size: 0.875rem;
  }
}
.site-header__primary-nav-item-button {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #231f20;
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  letter-spacing: -0.3px;
}
@media (max-width: 85em) {
  .site-header__primary-nav-item-button {
    font-size: 1rem;
  }
}
@media (max-width: 80em) {
  .site-header__primary-nav-item-button {
    font-size: 0.9375rem;
  }
}
@media (max-width: 76.24em) {
  .site-header__primary-nav-item-button {
    font-family: "Nunito", serif;
    font-weight: 600;
    font-style: normal;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 1.5rem;
  }
}
@media (max-width: 51.74em) {
  .site-header__primary-nav-item-button {
    font-size: 1.25rem;
  }
}
.site-header__primary-nav-item-button-label::before {
  background-color: #a51890;
}
.site-header__primary-nav-item-button .svg {
  fill: #a51890;
}
.site-header__primary-nav-item-button .svg--arrow-right {
  background-color: rgba(226, 225, 210, 0.5);
}
@media (max-width: 76.24em) {
  .site-header__submenu {
    background-color: #fff;
  }
}
.site-header__submenu-content-title {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
  font-size: 1.375rem;
  line-height: 1.1818181818;
  letter-spacing: -0.6px;
}
.site-header__submenu-content-text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.site-header__submenu-nav-item:not(:last-child) {
  border-color: #d6eaff;
}
.site-header__submenu-nav-item-label {
  color: #5f71a5;
}
.site-header__submenu-nav-item-button {
  color: #003fce;
}
.no-touchevents .site-header__submenu-nav-item-button:hover, .no-touchevents .site-header__submenu-nav-item-button:focus {
  color: #a51890;
}

.site-footer {
  background-color: #44173e;
}
.site-footer__primary-nav-item-button {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.2px;
}
@media (min-width: 90.75em) {
  .site-footer__primary-nav-item-button {
    font-size: 1.375rem;
  }
}
.no-touchevents .site-footer__primary-nav-item-button:hover, .no-touchevents .site-footer__primary-nav-item-button:focus {
  color: #E96398;
}
.no-touchevents .site-footer__secondary-nav-item-button:hover, .no-touchevents .site-footer__secondary-nav-item-button:focus {
  color: #E96398;
}
.site-footer__text {
  color: #E96398;
}
.site-footer__metas {
  display: flex;
  align-items: center;
}
.site-footer__metas-item, .site-footer__metas-item-button {
  color: #E96398;
}
.no-touchevents .site-footer__metas-item-button:hover, .no-touchevents .site-footer__metas-item-button:focus {
  color: #fff;
}
.site-footer .eyebrow {
  color: #E96398;
}
@media (max-width: 37.49em) {
  .site-footer__column-toggler {
    color: #E96398;
  }
}
.site-footer__column:nth-child(3), .site-footer__column:nth-child(4), .site-footer__column:nth-child(5) {
  border-color: rgba(165, 24, 144, 0.4);
}
.site-footer__dropdown .dropdown__button {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 900;
  font-style: normal;
  color: #fff;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 1.5px;
  background: #a51890;
}

.module-form__field label,
.module-form__radio label,
.module-form__checkbox label {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-form__field label a,
.module-form__radio label a,
.module-form__checkbox label a {
  color: #a51890;
}
.module-form__field label b,
.module-form__radio label b,
.module-form__checkbox label b {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.module-form__field small,
.module-form__radio small,
.module-form__checkbox small {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-form__field--s label b,
.module-form__radio--s label b,
.module-form__checkbox--s label b {
  color: #5f71a5;
}
.module-form__field--error > label,
.module-form__radio--error > label,
.module-form__checkbox--error > label {
  color: #ff5f24;
}
.module-form__field--error > label b,
.module-form__radio--error > label b,
.module-form__checkbox--error > label b {
  color: inherit;
}

.module-form__field .module-form__input {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  border-color: #e2e1d2;
  color: #5f71a5;
}
.module-form__field .module-form__input:focus {
  box-shadow: 0 0 0 3px rgba(226, 225, 210, 0.4);
}
.module-form__field .margion-top-25 {
  margin-top: 25px;
}
.module-form__field .module-form__inner::after {
  background-image: url("../assets/images/icons/input-error@2x.png");
}
.module-form__field .module-form__password-show .svg {
  fill: #ff5f24;
}
.module-form__field--date .module-form__inner::after {
  background-image: url("../assets/images/icons/calendar-blue@2x.png");
}
.module-form__field--error .module-form__inner .module-form__input {
  padding-right: 60px;
  border-color: #ff5f24;
  color: #ff5f24;
}
.module-form__field--error .module-form__inner::after {
  content: "";
}

.module-form__select::after {
  background-image: url("../assets/images/icons/select-arrow@2x.png");
}

.module-form__radio label,
.module-form__checkbox label {
  cursor: pointer;
}
.module-form__radio label::before,
.module-form__checkbox label::before {
  border-color: #e2e1d2;
}
.module-form__radio input:checked + label::before,
.module-form__checkbox input:checked + label::before {
  border-color: #a51890;
}

.module-form__checkbox label::after {
  background-image: url("../assets/images/icons/checked-purple@2x.png");
}

.module-form__radio label::after {
  background-color: #a51890;
}

::placeholder {
  opacity: 1;
  color: rgba(95, 113, 165, 0.5);
}

:-ms-input-placeholder {
  color: rgba(95, 113, 165, 0.5);
}

::-ms-input-placeholder {
  color: rgba(95, 113, 165, 0.5);
}

.form-filter__button {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  background: #fff;
}
.form-filter__dropdown {
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(6, 41, 78, 0.08);
}
.form-filter__dropdown-footer {
  border-color: #d6eaff;
}
.form-filter__dropdown-clear {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #5f71a5;
}
.form-filter .module-form__checkbox label {
  color: #a51890;
}
.form-filter .module-form__checkbox label::after {
  background-image: url("../assets/images/icons/checked-white@2x.png");
}
.form-filter .module-form__checkbox input:checked + label::before {
  border-color: #a51890;
  background-color: #a51890;
}

.header {
  background-color: #ffe20e;
}
@media (max-width: 51.74em) {
  .header {
    padding-bottom: 150px;
  }
}
.header .header-inner {
  max-width: calc((6 * calc((1160px - 440px) / 12)) + (5 * 40px));
}
.header .header-inner__text {
  max-width: 440px;
}
.header .module-image {
  pointer-events: none;
}
.header .module-image .module-image__video-button {
  pointer-events: auto;
}
.header--curvy {
  background-color: transparent;
}
.header--curvy::after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  height: 3280px;
  width: 3280px;
  margin-left: -1640px;
  content: "";
  z-index: -2;
  border-radius: 50%;
  background-color: #ffe20e;
}

.header-nav.has-menu {
  border-color: #FCD70E;
}
.header-nav__page-name {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #06294e;
}
.header-nav__menu-item a:not(.button) {
  position: relative;
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #a51890;
}
.no-touchevents .header-nav__menu-item a:not(.button):hover, .no-touchevents .header-nav__menu-item a:not(.button):focus {
  color: #003fce;
}
.no-touchevents .header-nav__menu-item a:not(.button):hover::before, .no-touchevents .header-nav__menu-item a:not(.button):focus::before {
  background-color: #003fce;
}
.header-nav__menu-item a:not(.button)::before {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -4px;
  left: 0;
  content: "";
  background-color: #a51890;
  border-radius: 2px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.header--home .module-image {
  z-index: 3;
}
.header--home .module-image:nth-child(1) {
  position: absolute;
  top: 120px;
  left: 50%;
  margin-left: -610px;
}
@media (max-width: 67.74em) {
  .header--home .module-image:nth-child(1) {
    margin-left: -450px;
    top: auto;
    bottom: 180px;
  }
}
@media (max-width: 51.74em) {
  .header--home .module-image:nth-child(1) {
    margin-left: -290px;
    bottom: 10px;
  }
}
@media (max-width: 37.49em) {
  .header--home .module-image:nth-child(1) {
    margin-left: -130px;
    top: auto;
    bottom: 0px;
  }
}
@media (max-width: 28.115em) {
  .header--home .module-image:nth-child(1) {
    margin-left: -150px;
    bottom: 10px;
  }
}
.header--home .module-image:nth-child(1) picture {
  height: 286px;
  width: 286px;
  clip-path: url(#header-1);
}
@media (max-width: 67.74em) {
  .header--home .module-image:nth-child(1) picture {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 51.74em) {
  .header--home .module-image:nth-child(1) picture {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 37.49em) {
  .header--home .module-image:nth-child(1) picture {
    height: 112px;
    width: 112px;
  }
}
.header--home .module-image:nth-child(1) .circle--1 {
  position: absolute;
  bottom: 35px;
  left: 0;
  height: 66px;
  width: 66px;
}
@media (max-width: 67.74em) {
  .header--home .module-image:nth-child(1) .circle--1 {
    height: 40px;
    width: 40px;
    bottom: -10px;
  }
}
@media (max-width: 28.115em) {
  .header--home .module-image:nth-child(1) .circle--1 {
    bottom: 0px;
  }
}
.header--home .module-image:nth-child(1) .circle--1::before {
  background-image: url("../assets/images/decorations/deco-ellipse-header.png");
}
.header--home .module-image:nth-child(2) {
  position: absolute;
  bottom: -85px;
  left: 50%;
  margin-left: 170px;
}
@media (max-width: 67.74em) {
  .header--home .module-image:nth-child(2) {
    margin-left: 155px;
  }
}
@media (max-width: 51.74em) {
  .header--home .module-image:nth-child(2) {
    margin-left: 110px;
  }
}
@media (max-width: 37.49em) {
  .header--home .module-image:nth-child(2) {
    margin-left: 20px;
    bottom: -75px;
  }
}
@media (max-width: 28.115em) {
  .header--home .module-image:nth-child(2) {
    margin-left: -30px;
    bottom: -50px;
  }
}
.header--home .module-image:nth-child(2) picture {
  height: 500px;
  width: 500px;
  clip-path: url(#header-2);
}
@media (max-width: 67.74em) {
  .header--home .module-image:nth-child(2) picture {
    height: 340px;
    width: 340px;
  }
}
@media (max-width: 51.74em) {
  .header--home .module-image:nth-child(2) picture {
    height: 240px;
    width: 240px;
  }
}
@media (max-width: 28.115em) {
  .header--home .module-image:nth-child(2) picture {
    height: 200px;
    width: 200px;
  }
}

.header--list {
  margin-bottom: 80px;
  padding-bottom: 30px;
}
.header--list .header-filters {
  max-width: 100%;
  margin-right: initial;
  margin-left: initial;
  padding-top: 30px;
  border-top: 2px solid rgba(255, 95, 36, 0.1);
}
.header--list .header-filters form {
  max-width: calc((8 * calc((1160px - 440px) / 12)) + (7 * 40px));
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 90.75em) {
  .header--list .header-filters form {
    max-width: calc((8 * calc((1392px - 528px) / 12)) + (7 * 48px));
  }
}
.header--list ~ .modules {
  position: relative;
  z-index: 1;
}

.header--basic {
  margin-bottom: 80px;
  padding-bottom: 105px;
}

.header--detail {
  margin-bottom: 80px;
  padding-bottom: 70px;
}
@media (max-width: 67.74em) {
  .header--detail {
    padding-bottom: 45px;
  }
}
.header--detail .header-inner {
  max-width: 70%;
  margin-left: 0;
  text-align: left;
}
@media (max-width: 51.74em) {
  .header--detail .header-inner {
    max-width: 100%;
  }
}
@media (max-width: 51.74em) {
  .header--detail .header-inner__title {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 51.74em) {
  .header--detail .header-inner__text {
    margin-right: 0;
    margin-left: 0;
  }
}

.header--landing-main .module-image:nth-child(1) {
  position: absolute;
  bottom: 215px;
  left: 50%;
  margin-left: -680px;
}
@media (max-width: 76.24em) {
  .header--landing-main .module-image:nth-child(1) {
    margin-left: -580px;
  }
}
@media (max-width: 67.74em) {
  .header--landing-main .module-image:nth-child(1) {
    margin-left: -500px;
    bottom: 180px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-main .module-image:nth-child(1) {
    margin-left: -370px;
    bottom: 60px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-main .module-image:nth-child(1) {
    margin-left: -140px;
    top: auto;
    bottom: -20px;
  }
}
.header--landing-main .module-image:nth-child(1) picture {
  height: 280px;
  width: 280px;
  clip-path: url(#header-3);
}
@media (max-width: 67.74em) {
  .header--landing-main .module-image:nth-child(1) picture {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-main .module-image:nth-child(1) picture {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-main .module-image:nth-child(1) picture {
    height: 112px;
    width: 112px;
  }
}
.header--landing-main .module-image:nth-child(1) .circle--1 {
  position: absolute;
  top: -105px;
  right: -9px;
  height: 108px;
  width: 78px;
}
@media (max-width: 67.74em) {
  .header--landing-main .module-image:nth-child(1) .circle--1 {
    height: 75px;
    width: 50px;
    top: -70px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-main .module-image:nth-child(1) .circle--1 {
    top: -60px;
    right: -60px;
  }
}
.header--landing-main .module-image:nth-child(1) .circle--1::before {
  background-image: url("../assets/images/decorations/deco-ellipse-header.png");
  transform: scaleX(-1);
}
.header--landing-main .module-image:nth-child(2) {
  position: absolute;
  bottom: -65px;
  left: 50%;
  margin-left: 170px;
}
@media (max-width: 67.74em) {
  .header--landing-main .module-image:nth-child(2) {
    margin-left: 155px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-main .module-image:nth-child(2) {
    margin-left: 110px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-main .module-image:nth-child(2) {
    margin-left: 0px;
    bottom: -45px;
  }
}
.header--landing-main .module-image:nth-child(2) picture {
  height: 500px;
  width: 500px;
  clip-path: url(#header-2);
}
@media (max-width: 67.74em) {
  .header--landing-main .module-image:nth-child(2) picture {
    height: 340px;
    width: 340px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-main .module-image:nth-child(2) picture {
    height: 240px;
    width: 240px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-main .module-image:nth-child(2) picture {
    height: 160px;
    width: 160px;
  }
}

.header--home-affiliate {
  z-index: initial;
}
.header--home-affiliate .header-inner {
  margin-left: 0;
  text-align: left;
}
@media (max-width: 51.74em) {
  .header--home-affiliate .header-inner__title {
    padding: 0;
  }
}
.header--home-affiliate .header-inner__text {
  margin-left: 0;
}
.header--home-affiliate .header-inner__buttons {
  justify-content: flex-start;
}
@media (max-width: 51.74em) {
  .header--home-affiliate .header-inner__buttons {
    align-items: flex-start;
  }
}
.header--home-affiliate .module-image:nth-child(1) {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -30px;
}
@media (max-width: 76.24em) {
  .header--home-affiliate .module-image:nth-child(1) {
    top: auto;
    bottom: -110px;
    margin-left: 0;
  }
}
@media (max-width: 28.115em) {
  .header--home-affiliate .module-image:nth-child(1) {
    bottom: -90px;
    margin-left: -100px;
  }
}
.header--home-affiliate .module-image:nth-child(1) picture {
  height: 880px;
  width: 880px;
  clip-path: url(#header-affiliate);
}
@media (max-width: 76.24em) {
  .header--home-affiliate .module-image:nth-child(1) picture {
    height: 60vw;
    width: 60vw;
    min-width: 250px;
    min-height: 250px;
  }
}
.header--home-affiliate.header--curvy::after {
  height: 1000px;
  width: 3000px;
  margin-left: -1500px;
}

.header--landing-video .module-image {
  z-index: 3;
}
.header--landing-video .module-image:nth-child(1) {
  position: absolute;
  top: 140px;
  left: 50%;
  margin-left: -640px;
}
@media (max-width: 67.74em) {
  .header--landing-video .module-image:nth-child(1) {
    margin-left: -460px;
    top: auto;
    bottom: 80px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-video .module-image:nth-child(1) {
    margin-left: -330px;
    bottom: 30px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-video .module-image:nth-child(1) {
    margin-left: -130px;
    top: auto;
    bottom: 20px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-video .module-image:nth-child(1) {
    margin-left: -160px;
  }
}
.header--landing-video .module-image:nth-child(1) picture {
  height: 286px;
  width: 286px;
  clip-path: url(#header-1);
}
@media (max-width: 67.74em) {
  .header--landing-video .module-image:nth-child(1) picture {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-video .module-image:nth-child(1) picture {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-video .module-image:nth-child(1) picture {
    height: 112px;
    width: 112px;
  }
}
.header--landing-video .module-image:nth-child(1) .circle--1 {
  position: absolute;
  bottom: 35px;
  left: 0;
  height: 66px;
  width: 66px;
}
@media (max-width: 67.74em) {
  .header--landing-video .module-image:nth-child(1) .circle--1 {
    height: 40px;
    width: 40px;
    bottom: -10px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-video .module-image:nth-child(1) .circle--1 {
    bottom: 0px;
  }
}
.header--landing-video .module-image:nth-child(1) .circle--1::before {
  background-image: url("../assets/images/decorations/deco-ellipse-header.png");
}
.header--landing-video .module-image:nth-child(2) {
  position: absolute;
  bottom: -65px;
  left: 50%;
  margin-left: 170px;
}
@media (max-width: 67.74em) {
  .header--landing-video .module-image:nth-child(2) {
    margin-left: 155px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-video .module-image:nth-child(2) {
    margin-left: 110px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-video .module-image:nth-child(2) {
    margin-left: 20px;
    bottom: -75px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-video .module-image:nth-child(2) {
    margin-left: -40px;
    bottom: -50px;
  }
}
.header--landing-video .module-image:nth-child(2) picture {
  height: 500px;
  width: 500px;
  clip-path: url(#header-2);
}
@media (max-width: 67.74em) {
  .header--landing-video .module-image:nth-child(2) picture {
    height: 340px;
    width: 340px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-video .module-image:nth-child(2) picture {
    height: 240px;
    width: 240px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-video .module-image:nth-child(2) picture {
    height: 200px;
    width: 200px;
  }
}

.header--landing-detail .module-image:nth-child(1) {
  position: absolute;
  bottom: 215px;
  left: 50%;
  margin-left: -680px;
}
@media (max-width: 76.24em) {
  .header--landing-detail .module-image:nth-child(1) {
    margin-left: -580px;
  }
}
@media (max-width: 67.74em) {
  .header--landing-detail .module-image:nth-child(1) {
    margin-left: -480px;
    bottom: 100px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-detail .module-image:nth-child(1) {
    margin-left: -370px;
    bottom: 60px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-detail .module-image:nth-child(1) {
    margin-left: -140px;
    top: auto;
    bottom: -20px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-detail .module-image:nth-child(1) {
    margin-left: -160px;
  }
}
.header--landing-detail .module-image:nth-child(1) picture {
  height: 280px;
  width: 280px;
  clip-path: url(#header-3);
}
@media (max-width: 67.74em) {
  .header--landing-detail .module-image:nth-child(1) picture {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-detail .module-image:nth-child(1) picture {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-detail .module-image:nth-child(1) picture {
    height: 112px;
    width: 112px;
  }
}
.header--landing-detail .module-image:nth-child(1) .circle--1 {
  position: absolute;
  top: -105px;
  right: -9px;
  height: 108px;
  width: 78px;
}
@media (max-width: 67.74em) {
  .header--landing-detail .module-image:nth-child(1) .circle--1 {
    height: 75px;
    width: 50px;
    top: -70px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-detail .module-image:nth-child(1) .circle--1 {
    top: -60px;
    right: -60px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-detail .module-image:nth-child(1) .circle--1 {
    right: -30px;
  }
}
.header--landing-detail .module-image:nth-child(1) .circle--1::before {
  background-image: url("../assets/images/decorations/deco-ellipse-header.png");
  transform: scaleX(-1);
}
.header--landing-detail .module-image:nth-child(2) {
  position: absolute;
  bottom: -65px;
  left: 50%;
  margin-left: 170px;
}
@media (max-width: 67.74em) {
  .header--landing-detail .module-image:nth-child(2) {
    margin-left: 155px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-detail .module-image:nth-child(2) {
    margin-left: 110px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-detail .module-image:nth-child(2) {
    margin-left: 30px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-detail .module-image:nth-child(2) {
    margin-left: -40px;
  }
}
.header--landing-detail .module-image:nth-child(2) picture {
  height: 500px;
  width: 500px;
  clip-path: url(#header-2);
}
@media (max-width: 67.74em) {
  .header--landing-detail .module-image:nth-child(2) picture {
    height: 340px;
    width: 340px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-detail .module-image:nth-child(2) picture {
    height: 240px;
    width: 240px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-detail .module-image:nth-child(2) picture {
    height: 200px;
    width: 200px;
  }
}

.header--landing-alternative .module-image:nth-child(1) {
  position: absolute;
  top: 120px;
  left: 50%;
  margin-left: 360px;
}
@media (max-width: 76.24em) {
  .header--landing-alternative .module-image:nth-child(1) {
    margin-left: 320px;
  }
}
@media (max-width: 67.74em) {
  .header--landing-alternative .module-image:nth-child(1) {
    top: auto;
    margin-left: 220px;
    bottom: -30px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-alternative .module-image:nth-child(1) {
    margin-left: 140px;
    bottom: -50px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-alternative .module-image:nth-child(1) {
    margin-left: 50px;
  }
}
.header--landing-alternative .module-image:nth-child(1) picture {
  height: 264px;
  width: 264px;
  clip-path: url(#header-4);
}
@media (max-width: 67.74em) {
  .header--landing-alternative .module-image:nth-child(1) picture {
    height: 184px;
    width: 184px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-alternative .module-image:nth-child(1) picture {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-alternative .module-image:nth-child(1) picture {
    height: 112px;
    width: 112px;
  }
}
.header--landing-alternative .module-image:nth-child(2) {
  position: absolute;
  bottom: -65px;
  left: 50%;
  margin-left: -650px;
}
@media (max-width: 67.74em) {
  .header--landing-alternative .module-image:nth-child(2) {
    bottom: -120px;
    margin-left: -420px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-alternative .module-image:nth-child(2) {
    bottom: -50px;
    margin-left: -320px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-alternative .module-image:nth-child(2) {
    margin-left: -200px;
  }
}
@media (max-width: 28.115em) {
  .header--landing-alternative .module-image:nth-child(2) {
    margin-left: -170px;
  }
}
.header--landing-alternative .module-image:nth-child(2) picture {
  height: 440px;
  width: 440px;
  clip-path: url(#large-small);
}
@media (max-width: 67.74em) {
  .header--landing-alternative .module-image:nth-child(2) picture {
    height: 340px;
    width: 340px;
  }
}
@media (max-width: 51.74em) {
  .header--landing-alternative .module-image:nth-child(2) picture {
    height: 240px;
    width: 240px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-alternative .module-image:nth-child(2) picture {
    height: 200px;
    width: 200px;
  }
}
.header--landing-alternative .module-image:nth-child(2) .circle--1 {
  position: absolute;
  top: -60px;
  left: 164px;
  height: 84px;
  width: 76px;
}
@media (max-width: 67.74em) {
  .header--landing-alternative .module-image:nth-child(2) .circle--1 {
    height: 58px;
    width: 50px;
    left: 90px;
  }
}
@media (max-width: 37.49em) {
  .header--landing-alternative .module-image:nth-child(2) .circle--1 {
    left: 200px;
    top: 10px;
  }
}
.header--landing-alternative .module-image:nth-child(2) .circle--1::before {
  background-image: url("../assets/images/decorations/deco-ellipse-header.png");
}

.footer-join .module-header__title {
  color: #fff;
}
.footer-join__inner {
  background-color: #0067e2;
}
.footer-join__inner::before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 1500px;
  width: 3500px;
  margin-left: -1750px;
  z-index: -2;
  border-radius: 50%;
  background-color: #0067e2;
}
.footer-join__inner::after {
  content: "";
  opacity: 0.25;
  background-image: url("../assets/images/decorations/dot-pattern-3@2x.png");
  background-size: 38px auto;
}
.footer-join--affiliate .module-header__title {
  color: #003fce;
}
.footer-join--affiliate .footer-join__inner {
  background-color: #facc00;
}
.footer-join--affiliate .footer-join__inner::before {
  background-color: #facc00;
}
.footer-join--affiliate .footer-join__inner::after {
  content: none;
}
.footer-join--curvy .footer-join__inner {
  overflow: hidden;
  padding-top: 144px;
  background-color: transparent;
}
.footer-join--curvy .footer-join__inner::before {
  content: "";
}

.button-back {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #a51890;
}

.button-icon {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
}
.button-icon--purple .svg, .button-icon--purple-bright .svg {
  fill: #a51890;
  background-color: #fff;
}
.button-icon--purple {
  color: #a51890;
}
.button-icon--purple::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.4);
}
.button-icon--purple::before {
  background-color: #edd0e8;
}
.button-icon--purple-bright {
  color: #fff;
}
.button-icon--purple-bright::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.3);
}
.button-icon--purple-bright::before {
  background-color: #a51890;
}
.button-icon--blue, .button-icon--blue-brighter {
  color: #003fce;
}
.button-icon--blue .svg, .button-icon--blue-brighter .svg {
  fill: #003fce;
  background-color: #fff;
}
.button-icon--blue::before, .button-icon--blue-brighter::before {
  background-color: #d6eaff;
}
.button-icon--blue::after, .button-icon--blue-brighter::after {
  box-shadow: 0 0 0 3px rgba(0, 63, 206, 0.3);
}
.button-icon--white {
  color: #a51890;
}
.button-icon--white .svg {
  fill: #fff;
  background-color: #a51890;
}
.button-icon--white::before {
  background-color: #fff;
}
.button-icon--white::after {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.6);
}
.button-icon[disabled] {
  color: #65769F;
}
.button-icon[disabled]::before {
  background-color: #dadee7;
}
.button-icon[disabled] .svg {
  fill: #65769F;
  background-color: #fff;
}

.button-pill {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
}
.button-pill--blue, .button-pill--blue-bright {
  color: #fff;
  background-color: #0067e2;
}
.button-pill--blue::after, .button-pill--blue-bright::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.3);
}
.no-touchevents .button-pill--blue:focus, .no-touchevents .button-pill--blue:hover, .no-touchevents .button-pill--blue-bright:focus, .no-touchevents .button-pill--blue-bright:hover {
  background-color: #a51890;
}
.button-pill--purple-bright {
  color: #fff;
  background-color: #a51890;
}
.button-pill--purple-bright::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.4);
}
.no-touchevents .button-pill--purple-bright:focus, .no-touchevents .button-pill--purple-bright:hover {
  color: #a51890;
  background-color: rgba(165, 24, 144, 0.2);
}
.button-pill--white {
  color: #a51890;
  background-color: #fff;
}
.button-pill--white::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.3);
}
.no-touchevents .button-pill--white:focus, .no-touchevents .button-pill--white:hover {
  color: #fff;
  background-color: #a51890;
}
.button-pill--bordered.button-pill--purple-bright {
  color: #a51890;
  border-color: #a51890;
  background: transparent;
}
.button-pill--bordered.button-pill--purple-bright::after {
  box-shadow: 0 0 0 3px rgba(165, 24, 144, 0.3);
}
.no-touchevents .button-pill--bordered.button-pill--purple-bright:focus, .no-touchevents .button-pill--bordered.button-pill--purple-bright:hover {
  color: #fff;
  border-color: transparent;
  background-color: #a51890;
}
.button-pill[disabled] {
  color: #65769f;
  border-color: transparent;
  background-color: #dadee7;
}

.button-line {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
}
.button-line--purple-bright {
  color: #a51890;
}
.button-line--purple-bright span {
  fill: rgba(165, 24, 144, 0.2);
}
.button-line--purple-bright span:not(.svg)::before {
  background-color: rgba(165, 24, 144, 0.2);
}
.no-touchevents .button-line--purple-bright:active span:not(.svg)::before {
  background-color: #a51890;
}
.button-line[disabled] {
  color: #4d5a7c;
}
.button-line[disabled] span:not(.svg)::before {
  background-color: rgba(77, 90, 124, 0.2);
}

.button-circle--blue, .button-circle--blue-bright {
  border-color: #0067e2;
}
.button-circle--blue .svg, .button-circle--blue-bright .svg {
  fill: #0067e2;
}
.no-touchevents .button-circle--blue:not([disabled]):hover, .no-touchevents .button-circle--blue:not([disabled]):focus, .no-touchevents .button-circle--blue-bright:not([disabled]):hover, .no-touchevents .button-circle--blue-bright:not([disabled]):focus {
  background-color: #0067e2;
}
.no-touchevents .button-circle--blue:not([disabled]):hover .svg, .no-touchevents .button-circle--blue:not([disabled]):focus .svg, .no-touchevents .button-circle--blue-bright:not([disabled]):hover .svg, .no-touchevents .button-circle--blue-bright:not([disabled]):focus .svg {
  fill: #fff;
}
.button-circle--white {
  border: #fff;
  background-color: #fff;
}
.button-circle--white .svg {
  fill: #a51890;
}
.no-touchevents .button-circle--white:not([disabled]):hover, .no-touchevents .button-circle--white:not([disabled]):focus {
  border-color: #a51890;
  background-color: #a51890;
}
.no-touchevents .button-circle--white:not([disabled]):hover .svg, .no-touchevents .button-circle--white:not([disabled]):focus .svg {
  fill: #fff;
}

.flatpickr-calendar {
  box-shadow: 0 10px 40px rgba(6, 41, 78, 0.08);
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: #0067e2;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #a51890;
}

.flatpickr-current-month {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #06294e;
}

.flatpickr-day {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #0067e2;
}
.flatpickr-day.today {
  background-color: rgba(0, 103, 226, 0.2);
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background-color: #0067e2;
}
.flatpickr-day.selected {
  color: #fff;
  background-color: #0067e2;
}
.flatpickr-day.selected:hover, .flatpickr-day.selected:focus {
  color: #fff;
  background-color: #0067e2;
}
.flatpickr-day:hover, .flatpickr-day:focus {
  color: #fff;
  background-color: #0067e2;
}
.flatpickr-day.flatpickr-disabled {
  color: rgba(0, 103, 226, 0.2);
}

span.flatpickr-weekday {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #06294e;
}

.module-form__field label,
.module-form__radio label,
.module-form__checkbox label {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-form__field label a,
.module-form__radio label a,
.module-form__checkbox label a {
  color: #a51890;
}
.module-form__field label b,
.module-form__radio label b,
.module-form__checkbox label b {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.module-form__field small,
.module-form__radio small,
.module-form__checkbox small {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-form__field--s label b,
.module-form__radio--s label b,
.module-form__checkbox--s label b {
  color: #5f71a5;
}
.module-form__field--error > label,
.module-form__radio--error > label,
.module-form__checkbox--error > label {
  color: #ff5f24;
}
.module-form__field--error > label b,
.module-form__radio--error > label b,
.module-form__checkbox--error > label b {
  color: inherit;
}

.module-form__field .module-form__input {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  border-color: #e2e1d2;
  color: #5f71a5;
}
.module-form__field .module-form__input:focus {
  box-shadow: 0 0 0 3px rgba(226, 225, 210, 0.4);
}
.module-form__field .margion-top-25 {
  margin-top: 25px;
}
.module-form__field .module-form__inner::after {
  background-image: url("../assets/images/icons/input-error@2x.png");
}
.module-form__field .module-form__password-show .svg {
  fill: #ff5f24;
}
.module-form__field--date .module-form__inner::after {
  background-image: url("../assets/images/icons/calendar-blue@2x.png");
}
.module-form__field--error .module-form__inner .module-form__input {
  padding-right: 60px;
  border-color: #ff5f24;
  color: #ff5f24;
}
.module-form__field--error .module-form__inner::after {
  content: "";
}

.module-form__select::after {
  background-image: url("../assets/images/icons/select-arrow@2x.png");
}

.module-form__radio label,
.module-form__checkbox label {
  cursor: pointer;
}
.module-form__radio label::before,
.module-form__checkbox label::before {
  border-color: #e2e1d2;
}
.module-form__radio input:checked + label::before,
.module-form__checkbox input:checked + label::before {
  border-color: #a51890;
}

.module-form__checkbox label::after {
  background-image: url("../assets/images/icons/checked-purple@2x.png");
}

.module-form__radio label::after {
  background-color: #a51890;
}

::placeholder {
  opacity: 1;
  color: rgba(95, 113, 165, 0.5);
}

:-ms-input-placeholder {
  color: rgba(95, 113, 165, 0.5);
}

::-ms-input-placeholder {
  color: rgba(95, 113, 165, 0.5);
}

.form-filter__button {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  background: #fff;
}
.form-filter__dropdown {
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(6, 41, 78, 0.08);
}
.form-filter__dropdown-footer {
  border-color: #d6eaff;
}
.form-filter__dropdown-clear {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #5f71a5;
}
.form-filter .module-form__checkbox label {
  color: #a51890;
}
.form-filter .module-form__checkbox label::after {
  background-image: url("../assets/images/icons/checked-white@2x.png");
}
.form-filter .module-form__checkbox input:checked + label::before {
  border-color: #a51890;
  background-color: #a51890;
}

.module-form__message {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
}
.module-form__message--valid {
  border-color: #a51890;
  background-color: #f5ecfa;
  color: #a51890;
}
.module-form__message--error {
  border-color: #ff5f24;
  background-color: #fff4ee;
  color: #ff5f24;
}

.module-faq__list-item:not(:first-child) {
  border-color: #e2e1d2;
  border-width: 2px;
}
.module-faq__list-item-title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #a51890;
}
.module-faq__list-item-button .svg {
  fill: #a51890;
}
.no-touchevents .module-faq__list-item-button:hover ~ .module-faq__list-item-title, .no-touchevents .module-faq__list-item-button:focus ~ .module-faq__list-item-title {
  color: #003fce;
}
.module-faq__list-item-text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-faq__list-item-text b,
.module-faq__list-item-text strong {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
}
.module-faq__list-item-text i,
.module-faq__list-item-text em {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: italic;
}
.module-faq__list-item-text ul li::before {
  background-color: #5f71a5;
}
.module-faq__list-item-text ol li::before {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #5f71a5;
}

.module-text__title {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  line-height: 1.0454545455;
  letter-spacing: -1.5px;
}
.module-text__lead {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  font-size: 2.25rem;
  line-height: 1.1111111111;
  letter-spacing: -1px;
}
.module-text__content {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}

.module-form__group legend {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  border-width: 2px;
  border-color: #e2e1d2;
  color: #003fce;
}
.module-form__group p {
  color: #5f71a5;
}
.module-form__footer {
  border-width: 2px;
  border-color: #e2e1d2;
}

@media (min-width: 76.25em) {
  .module-tabs {
    border-radius: 36px;
    background-color: #fff;
  }
}
@media (max-width: 76.24em) {
  .module-tabs__list {
    background-color: #fff;
  }
}
@media (min-width: 51.75em) and (max-width: 76.24em) {
  .module-tabs__list {
    border-radius: 36px;
  }
}
@media (max-width: 51.74em) {
  .module-tabs__list {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
.module-tabs__list button::after {
  background-color: #a51890;
}
.no-touchevents .module-tabs__list button:hover .module-tabs__list-day,
.no-touchevents .module-tabs__list button:hover .module-tabs__list-date, .no-touchevents .module-tabs__list button:focus .module-tabs__list-day,
.no-touchevents .module-tabs__list button:focus .module-tabs__list-date, .module-tabs__list button.is-active .module-tabs__list-day,
.module-tabs__list button.is-active .module-tabs__list-date {
  color: #a51890;
}
.module-tabs__list li:last-child::before {
  background-color: #fff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.module-tabs__list li:last-child::after {
  background-color: #f7f6ec;
}
.module-tabs__list-day, .module-tabs__list-date {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}

.module-dates__list-expand::before {
  background-image: linear-gradient(to top, #f7f6ec 0%, rgba(247, 246, 236, 0) 100%);
}

.module-image {
  position: relative;
}
.module-image__video-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.module-image__video-button-play {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 72px;
  width: 72px;
  margin-top: -36px;
  margin-left: -36px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  fill: #a51890;
}
@media (max-width: 51.74em) {
  .module-image__video-button-play {
    height: 56px;
    width: 56px;
    margin-top: -28px;
    margin-left: -28px;
  }
}
.module-image__video-button-play .svg {
  transition: fill 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.no-touchevents .module-image__video-button:hover .module-image__video-button-play, .no-touchevents .module-image__video-button:focus .module-image__video-button-play {
  fill: #fff;
  background-color: #a51890;
}
.module-image__elements {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.module-image__item {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.module-image__item picture {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.module-image__item img {
  height: 100%;
  width: 100%;
  display: block;
}
.object-fit .module-image__item img {
  object-fit: cover;
}
.no-object-fit .module-image__item img {
  font-family: "object-fit: cover";
}
.module-image__item--1 {
  transition-delay: 0.2s;
}
.module-image__item--2 {
  transition-delay: 0.35s;
}
.module-image--large {
  width: 415px;
  margin-left: auto;
}
@media (max-width: 51.74em) {
  .module-image--large {
    width: 375px;
  }
}
@media (max-width: 37.49em) {
  .module-image--large {
    width: 285px;
  }
}
@media (min-width: 51.75em) {
  .module-image--large .module-image__items {
    margin-top: -40px;
  }
}
.module-image--large .module-image__item--1 picture {
  height: 414px;
  width: 414px;
  clip-path: url(#large-large);
}
@media (max-width: 76.24em) {
  .module-image--large .module-image__item--1 picture {
    height: 360px;
    width: 360px;
  }
}
@media (max-width: 51.74em) {
  .module-image--large .module-image__item--1 picture {
    height: 300px;
    width: 300px;
  }
}
@media (max-width: 37.49em) {
  .module-image--large .module-image__item--1 picture {
    height: 252px;
    width: 252px;
  }
}
.module-image--large .module-image__item--2 picture {
  height: 200px;
  width: 200px;
  margin-top: -60px;
  margin-left: 260px;
  clip-path: url(#large-small);
}
@media (max-width: 76.24em) {
  .module-image--large .module-image__item--2 picture {
    height: 170px;
    width: 170px;
    margin-left: 210px;
    margin-top: -20px;
  }
}
@media (max-width: 37.49em) {
  .module-image--large .module-image__item--2 picture {
    height: 126px;
    width: 126px;
  }
}
@media (max-width: 28.115em) {
  .module-image--large .module-image__item--2 picture {
    margin-left: 160px;
  }
}
.module-image--large .circle--1 {
  position: absolute;
  right: 20px;
  bottom: 250px;
  height: 35px;
  width: 28px;
}
@media (max-width: 76.24em) {
  .module-image--large .circle--1 {
    bottom: 200px;
    right: 50px;
  }
}
@media (max-width: 37.49em) {
  .module-image--large .circle--1 {
    right: 0;
    bottom: 170px;
  }
}
.module-image--medium {
  width: 375px;
}
@media (max-width: 51.74em) {
  .module-image--medium {
    top: -20px;
    width: 300px;
  }
}
@media (max-width: 37.49em) {
  .module-image--medium {
    width: 200px;
  }
}
.module-image--medium .module-image__item--1 {
  margin-left: -60px;
}
.module-image--medium .module-image__item--1 picture {
  height: 460px;
  width: 460px;
  clip-path: url(#medium-large);
}
@media (max-width: 76.24em) {
  .module-image--medium .module-image__item--1 picture {
    height: 360px;
    width: 360px;
  }
}
@media (max-width: 51.74em) {
  .module-image--medium .module-image__item--1 picture {
    height: 300px;
    width: 300px;
  }
}
@media (max-width: 37.49em) {
  .module-image--medium .module-image__item--1 picture {
    height: 290px;
    width: 290px;
  }
}
.module-image--medium .module-image__item--2 picture {
  position: absolute;
  top: -20px;
  right: -30px;
  height: 140px;
  width: 140px;
  clip-path: url(#small-small);
}
@media (max-width: 51.74em) {
  .module-image--medium .module-image__item--2 picture {
    height: 110px;
    width: 110px;
  }
}
@media (max-width: 37.49em) {
  .module-image--medium .module-image__item--2 picture {
    height: 85px;
    width: 85px;
  }
}
.module-image--medium .circle--1 {
  position: absolute;
  top: -44px;
  right: 100px;
  height: 36px;
  width: 28px;
}
@media (max-width: 37.49em) {
  .module-image--medium .circle--1 {
    right: 70px;
  }
}
.module-image--medium .circle--1::before {
  transform: scaleX(-1);
}
.module-image--small {
  width: 370px;
}
@media (max-width: 37.49em) {
  .module-image--small {
    width: 250px;
  }
}
.module-image--small .module-image__item--1 picture {
  height: 367px;
  width: 367px;
  clip-path: url(#small-large);
}
@media (max-width: 76.24em) {
  .module-image--small .module-image__item--1 picture {
    height: 300px;
    width: 300px;
  }
}
@media (max-width: 51.74em) {
  .module-image--small .module-image__item--1 picture {
    height: 260px;
    width: 260px;
  }
}
@media (max-width: 37.49em) {
  .module-image--small .module-image__item--1 picture {
    height: 240px;
    width: 240px;
  }
}
.module-image--small .module-image__item--2 {
  position: absolute;
  top: -40px;
  right: -30px;
}
.module-image--small .module-image__item--2 picture {
  height: 127px;
  width: 127px;
  margin-left: 390px;
  clip-path: url(#small-small);
}
@media (max-width: 37.49em) {
  .module-image--small .module-image__item--2 picture {
    height: 90px;
    width: 90px;
  }
}
.module-image--small .circle--1 {
  position: absolute;
  top: -20px;
  right: 120px;
  height: 35px;
  width: 26px;
}
@media (max-width: 37.49em) {
  .module-image--small .circle--1 {
    right: 70px;
  }
}
.module-image--small .circle--1::before {
  transform: scaleX(-1);
}

html.ie .module-image__item picture {
  transform: scale(0.7);
  overflow: hidden;
  border-radius: 50%;
}

html:not(.mobile):not(.ie) .js-in-view .module-image__item {
  opacity: 0;
}
html:not(.mobile):not(.ie) .js-in-view.in-view .module-image__item {
  opacity: 1;
}

.module-perks__item-title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #a51890;
}
.module-perks__item-title .svg {
  fill: #a51890;
}
.module-perks__item-text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-perks__item + .module-perks__item {
  border-color: #e2e1d2;
  border-width: 2px;
}

.module-header__title {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  line-height: 1.0454545455;
  letter-spacing: -1.5px;
}
.module-header__text, .module-header__caption {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-header__text a, .module-header__caption a {
  color: #0067e2;
}

.alert-bar {
  padding-right: 30px;
  padding-left: 30px;
  background-color: #44173e;
}
.alert-bar p {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #FF5F14;
}
.alert-bar p a {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}
.alert-bar__button {
  fill: #FF5F14;
}

.module-pagination {
  border-width: 2px;
  border-color: #e2e1d2;
}
.module-pagination__button {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #003fce;
}
.module-pagination__button .svg {
  position: relative;
  top: 1px;
}
.module-pagination__button .svg--arrow-right {
  margin-left: 13px;
}
.module-pagination__button .svg--arrow-left {
  margin-right: 13px;
}
.module-pagination__list-item {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #003fce;
}
.module-pagination__list-item--active {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  background-color: #fff;
}
.no-touchevents .module-pagination__list-item:hover, .no-touchevents .module-pagination__list-item:focus {
  color: #a51890;
}

@media (min-width: 51.75em) {
  .module-filters-bar {
    border-width: 2px;
    border-color: #e2e1d2;
  }
}
.module-filters-bar__text, .module-filters-bar__sorting-select {
  font-family: "Nunito", serif;
  font-weight: 600;
  font-style: normal;
  color: #5f71a5;
}
@media (max-width: 51.74em) {
  .module-filters-bar__tags {
    border-color: #d6eaff;
  }
}
.module-filters-bar__tags-item {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  background-color: #fff;
  color: #0067e2;
}
.module-filters-bar__sorting-select {
  border-color: #e2e1d2;
  background-color: #fff;
}
.module-filters-bar__sorting .svg--caret-down {
  fill: #003fce;
}

.module-capture__list {
  border-radius: 16px;
  background-color: #e5f7fe;
  box-shadow: -1px 0 15px rgba(6, 41, 78, 0.1), 0px 20px 30px rgba(0, 103, 226, 0.1);
}
.module-capture__toggle svg {
  fill: #0067e2;
}
.module-capture__dots button {
  background-color: #d6eaff;
}
.module-capture__dots .is-active button {
  background-color: transparent;
}
.no-touchevents .module-capture__dots li:not(.is-active) button:hover, .no-touchevents .module-capture__dots li:not(.is-active) button:focus {
  background-color: #a3d0ff;
}
.module-capture__dots svg {
  stroke: #5f71a5;
}
.module-capture__caption {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #e2e1d2;
}

.no-touchevents .module-card__link:hover ~ .module-card__inner, .no-touchevents .module-card__link:focus ~ .module-card__inner {
  transform: translateY(-12px);
  box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);
}
.no-touchevents .module-card__link:hover ~ .module-card__inner .module-card__dot, .no-touchevents .module-card__link:focus ~ .module-card__inner .module-card__dot {
  opacity: 1;
  transform: translateX(5px);
}
.no-touchevents .module-card__link:hover ~ .module-card__inner::before, .no-touchevents .module-card__link:focus ~ .module-card__inner::before {
  opacity: 0;
}
.module-card__dot {
  position: absolute;
  top: 43px;
  right: 35px;
  height: 15px;
  width: 17px;
  opacity: 0.2;
  background-image: url("../assets/images/icons/card-arrow@2x.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card__inner {
  border-radius: 16px;
  background: #fff;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-radius 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #edd0e8;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.no-touchevents .module-card-icon .module-card__link:hover ~ .module-card__inner .module-card-icon__title, .no-touchevents .module-card-icon .module-card__link:focus ~ .module-card__inner .module-card-icon__title {
  color: #a51890;
}
.module-card-icon__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card-icon__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-card-icon__icon {
  background-color: #ffe20e;
}

.module-card-text .module-card__inner {
  background: transparent;
}
.module-card-text__eyebrow {
  border-color: #e2e1d2;
  border-width: 2px;
}
.module-card-text__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.module-card-text__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}

.module-card-date:not(:first-child) {
  border-color: #e2e1d2;
  border-width: 2px;
}
.module-card-date__hour, .module-card-date__date {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}
.module-card-date__text, .module-card-date__caption {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
}
.module-card-date__text {
  color: #003fce;
}
.module-card-date__caption {
  color: #5f71a5;
}
.module-card-date + .module-card-date {
  margin-top: -2px;
}

.no-touchevents .module-card-press .module-card__link:hover ~ .module-card__inner .module-card-press__title, .no-touchevents .module-card-press .module-card__link:focus ~ .module-card__inner .module-card-press__title {
  color: #a51890;
}
.module-card-press__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card-press__date {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #5f71a5;
  border-color: #e2e1d2;
}

.module-card-small {
  min-height: 205px;
}
.no-touchevents .module-card-small .module-card__link:hover ~ .module-card__inner .module-card-small__title, .no-touchevents .module-card-small .module-card__link:focus ~ .module-card__inner .module-card-small__title {
  color: #a51890;
}
.module-card-small .module-card__inner {
  padding: 40px 95px 40px 40px;
}
.module-card-small__icon {
  background-color: #ffe20e;
}
.module-card-small__title {
  padding-top: 0;
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card-small__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-card-small--narrow {
  min-height: 0;
}
.module-card-small--narrow .module-card__link + .module-card__inner {
  padding: 40px 80px 40px 40px;
}
.module-card-small--narrow .module-card__inner {
  padding: 20px 40px 20px 40px;
}
.module-card-small--narrow .module-card__dot {
  top: 45px;
}

.module-card-habit__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.module-card-habit__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-card-habit__icon {
  background-color: #f7f6ec;
}

.no-touchevents .module-card-activity .module-card__link:focus ~ .module-card__inner .module-card__image-overlay, .no-touchevents .module-card-activity .module-card__link:hover ~ .module-card__inner .module-card__image-overlay {
  opacity: 1;
}
.no-touchevents .module-card-activity .module-card__link:focus ~ .module-card__inner .module-card-activity__image::after, .no-touchevents .module-card-activity .module-card__link:hover ~ .module-card__inner .module-card-activity__image::after {
  opacity: 0;
}
.module-card-activity .module-card__inner {
  background-color: transparent;
}
.module-card-activity .module-card__inner::before {
  content: none;
}
.module-card-activity .module-card__dot {
  display: none;
}
.module-card-activity .module-card__image-overlay::before {
  content: none;
}
.module-card-activity .module-card__image-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 2;
  background-image: linear-gradient(198deg, rgba(152, 42, 136, 0) 28%, rgba(152, 42, 136, 0.8) 70%);
}
.module-card-activity__image {
  border-radius: 24px;
}
.module-card-activity__image::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-image: linear-gradient(198deg, rgba(68, 23, 62, 0) 28%, rgba(68, 23, 62, 0.7) 70%);
}
.module-card-activity__tags {
  margin-bottom: 12px;
}
.module-card-activity__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.1666666667;
  letter-spacing: -0.5px;
}
.module-card-activity__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}
.module-card-activity__content {
  color: #fff;
}

.module-card-schedule__text, .module-card-schedule__caption {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
}
.module-card-schedule__text {
  color: #003fce;
}
.module-card-schedule__hour {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}
.module-card-schedule__caption {
  color: #5f71a5;
}
.module-card-schedule + .module-card-schedule {
  margin-top: -2px;
  border-color: #e2e1d2;
  border-width: 2px;
}

.no-touchevents .module-card-program .module-card__link:hover ~ .module-card__inner, .no-touchevents .module-card-program .module-card__link:focus ~ .module-card__inner {
  transform: translateY(0);
  box-shadow: none;
}
.no-touchevents .module-card-program .module-card__link:hover ~ .module-card__inner .module-card-program__title, .no-touchevents .module-card-program .module-card__link:focus ~ .module-card__inner .module-card-program__title {
  color: #a51890;
}
.no-touchevents .module-card-program .module-card__link:hover ~ .module-card__inner .module-card-program__logo::after, .no-touchevents .module-card-program .module-card__link:focus ~ .module-card__inner .module-card-program__logo::after {
  opacity: 1;
}
.module-card-program__location, .module-card-program__elligibility {
  color: #003fce;
}
.module-card-program__location {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
}
.module-card-program__elligibility {
  font-family: "Nunito", serif;
  font-weight: 600;
  font-style: normal;
}
.module-card-program__title {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card-program__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.module-card-program__logo {
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.module-card-program__logo::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  border-radius: 16px;
  box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.module-quote__inner {
  border-radius: 24px;
  border: 1px solid #e2e1d2;
  background-color: #fff;
}
.module-quote__image {
  border: 4px solid #3296FF;
}
.module-quote__dots {
  position: absolute;
  top: calc(50% - 67px);
  left: calc(50% - 67px);
  height: 134px;
  width: 134px;
  fill: transparent;
  stroke-width: 2px;
  stroke: #d6eaff;
  stroke-dasharray: 3px 12px;
  stroke-linecap: round;
}
.module-quote__dots circle {
  r: calc(50% - 4px);
}
.module-quote cite {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #5f71a5;
}
.module-quote p {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #06294e;
}
.module-quote b,
.module-quote strong {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 900;
  font-style: normal;
}
.module-quote i,
.module-quote em {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: italic;
}

.module-facts-slider__list-item-title {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #0067e2;
}
.no-touchevents .module-facts-slider__list-item-button:hover ~ .module-facts-slider__list-item-title, .no-touchevents .module-facts-slider__list-item-button:focus ~ .module-facts-slider__list-item-title {
  color: #a51890;
}
.module-facts-slider__list-item-text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #06294e;
}
.module-facts-slider__list-item__nav .svg {
  fill: #0067e2;
}
.module-facts-slider__list-item__nav-button {
  background: #f7f6ec;
}
.module-facts-slider__list-item__nav-progress {
  background-color: #d6eaff;
}
.module-facts-slider__list-item__nav-progress-bar {
  background-color: #0067e2;
}
@media (min-width: 51.75em) {
  .module-facts-slider__list-item.is-active .module-facts-slider__list-item-title {
    color: #06294e;
  }
}

.content .module-header {
  margin-bottom: 55px;
}
.content .module-header__text {
  margin-bottom: 80px;
}
.content .module-header--s {
  margin-bottom: 55px;
}
.content::after {
  position: absolute;
  top: 0;
  left: 50%;
  height: 1500px;
  width: 3500px;
  margin-left: -1750px;
  z-index: -1;
  border-radius: 50%;
  background-color: #fff;
}
.content--curvy {
  margin-top: 40px;
  padding-top: 144px;
  padding-bottom: 140px;
  overflow: hidden;
}
.content--curvy::after {
  content: "";
}
.content--curvy + .footer-join {
  padding-top: 0;
}

.content-cards {
  padding-bottom: 75px;
}
.content-cards.content--curvy {
  padding-top: 120px;
  padding-bottom: 120px;
}
.content-cards.content--curvy .module-card-icon .module-card__inner::before,
.content-cards.content--curvy .module-card-press .module-card__inner::before,
.content-cards.content--curvy .module-card-small .module-card__inner::before {
  content: "";
}

.content-column .module-text__title + .module-text__content {
  margin-top: 0;
}
@media (min-width: 51.75em) {
  .content-column .module-text__content {
    margin-top: 50px;
  }
}
.content-column .module-perks {
  margin-top: 20px;
}
.content-column .module-image {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 51.75em) {
  .content-column .content__column {
    margin-top: 105px;
    margin-bottom: 55px;
  }
}
@media (max-width: 51.74em) {
  .content-column:not(.content-quotes) .content__column:first-child {
    margin-bottom: 30px;
  }
}
@media (min-width: 76.25em) {
  .content-column--wide .content__column > * {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 76.25em) {
  .content-column--wide .content__column:nth-child(1) {
    flex-basis: calc((5 * calc((1160px - 440px) / 12)) + (4 * 40px));
    min-width: calc((5 * calc((1160px - 440px) / 12)) + (4 * 40px));
    max-width: calc((5 * calc((1160px - 440px) / 12)) + (4 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-column--wide .content__column:nth-child(1) {
    flex-basis: calc((5 * calc((1392px - 528px) / 12)) + (4 * 48px));
    min-width: calc((5 * calc((1392px - 528px) / 12)) + (4 * 48px));
    max-width: calc((5 * calc((1392px - 528px) / 12)) + (4 * 48px));
  }
}
@media (min-width: 76.25em) {
  .content-column--wide .content__column:nth-child(2) {
    flex-basis: calc((6 * calc((1160px - 440px) / 12)) + (6 * 40px));
    min-width: calc((6 * calc((1160px - 440px) / 12)) + (6 * 40px));
    max-width: calc((6 * calc((1160px - 440px) / 12)) + (6 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-column--wide .content__column:nth-child(2) {
    flex-basis: calc((6 * calc((1392px - 528px) / 12)) + (6 * 48px));
    min-width: calc((6 * calc((1392px - 528px) / 12)) + (6 * 48px));
    max-width: calc((6 * calc((1392px - 528px) / 12)) + (6 * 48px));
  }
}
@media (min-width: 51.75em) {
  .content-column:not(.content-column--reflect) .module-image .module-image__item,
  .content-column:not(.content-column--reflect) .module-image .module-image__elements {
    margin-left: -50px;
  }
}
@media (min-width: 51.75em) {
  .content-column--reflect .content__column {
    margin-top: 105px;
    margin-bottom: 55px;
  }
}
.content-column--reflect .module-image {
  transform: scaleX(-1);
}
@media (min-width: 51.75em) {
  .content-column--reflect .module-image .module-image__item,
  .content-column--reflect .module-image .module-image__elements {
    margin-right: -50px;
  }
}
.content-column--reflect .module-image img {
  transform: scaleX(-1);
}
@media (max-width: 51.74em) {
  .content-column--mobile-reflect .content__column:last-child {
    margin-bottom: 20px;
  }
}

.content-archive__items + .content-archive__items {
  margin-top: 40px;
}
.content-archive[data-column="1"] .module-card-program + .module-card-program {
  border-width: 2px;
  border-color: #e2e1d2;
}
.content-archive[data-column="1"] .content-archive__items + .content-archive__items {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 2px solid #e2e1d2;
}

.content-callout {
  padding-top: 80px;
}
.content-callout .module-header__text {
  margin-bottom: 0;
}
.content-callout .module-image:nth-child(1) {
  position: absolute;
  top: 57px;
  left: 50%;
  margin-left: -560px;
}
@media (max-width: 67.74em) {
  .content-callout .module-image:nth-child(1) {
    margin-left: -480px;
  }
}
@media (max-width: 51.74em) {
  .content-callout .module-image:nth-child(1) {
    margin-left: -370px;
    top: auto;
    bottom: 110px;
  }
}
@media (max-width: 37.49em) {
  .content-callout .module-image:nth-child(1) {
    margin-left: -260px;
    bottom: 10px;
  }
}
@media (max-width: 28.115em) {
  .content-callout .module-image:nth-child(1) {
    margin-left: -190px;
    bottom: -10px;
  }
}
.content-callout .module-image:nth-child(1) picture {
  height: 140px;
  width: 140px;
  clip-path: url(#small-large);
}
@media (max-width: 51.74em) {
  .content-callout .module-image:nth-child(1) picture {
    height: 120px;
    width: 120px;
  }
}
.content-callout .module-image:nth-child(1) .circle--1 {
  position: absolute;
  top: -40px;
  right: -25px;
  height: 56px;
  width: 46px;
}
@media (max-width: 51.74em) {
  .content-callout .module-image:nth-child(1) .circle--1 {
    top: -80px;
    right: 0px;
  }
}
@media (max-width: 28.115em) {
  .content-callout .module-image:nth-child(1) .circle--1 {
    right: 50px;
  }
}
@media (max-width: 28.115em) {
  .content-callout .module-image:nth-child(1) .circle--1 {
    height: 46px;
    width: 36px;
  }
}
.content-callout .module-image:nth-child(2) {
  position: absolute;
  bottom: 180px;
  left: 50%;
  margin-left: 318px;
}
@media (max-width: 67.74em) {
  .content-callout .module-image:nth-child(2) {
    margin-left: 260px;
  }
}
@media (max-width: 51.74em) {
  .content-callout .module-image:nth-child(2) {
    margin-left: 180px;
    bottom: 90px;
  }
}
@media (max-width: 37.49em) {
  .content-callout .module-image:nth-child(2) {
    bottom: 40px;
    margin-left: 120px;
  }
}
@media (max-width: 28.115em) {
  .content-callout .module-image:nth-child(2) {
    bottom: -20px;
    margin-left: 50px;
  }
}
.content-callout .module-image:nth-child(2) picture {
  height: 240px;
  width: 240px;
  clip-path: url(#large-large);
}
@media (max-width: 51.74em) {
  .content-callout .module-image:nth-child(2) picture {
    height: 180px;
    width: 180px;
  }
}
@media (max-width: 28.115em) {
  .content-callout .module-image:nth-child(2) picture {
    height: 140px;
    width: 140px;
  }
}

.content-flexible {
  padding-bottom: 120px;
}

.content-facts-slider::before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 40px;
  width: 4px;
  margin-left: -2px;
  content: "";
}

.content-keypoints {
  padding-top: 60px;
  padding-bottom: 60px;
}
.content-keypoints .module-header {
  margin-bottom: 50px;
}
@media (min-width: 37.5em) and (max-width: 67.74em) {
  .content-keypoints__list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }
}
@media (max-width: 67.74em) {
  .content-keypoints__text {
    margin-left: 0;
  }
}
@media (max-width: 37.49em) {
  .content-keypoints__item {
    width: 100%;
  }
}
@media (min-width: 37.5em) {
  .content-keypoints__item {
    width: calc(50% - 11px);
    max-width: 360px;
  }
}
@media (min-width: 37.5em) and (max-width: 67.74em) {
  .content-keypoints__item:nth-child(even) {
    margin-left: 22px;
  }
}
@media (min-width: 67.75em) and (max-width: 76.24em) {
  .content-keypoints__item {
    flex-basis: calc(25% - 18px);
    min-width: calc(25% - 18px);
    max-width: calc(25% - 18px);
  }
  .content-keypoints__item:not(:first-child) {
    margin-left: 20px;
  }
}
@media (min-width: 76.25em) {
  .content-keypoints__item {
    flex-basis: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
    min-width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
    max-width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-keypoints__item {
    flex-basis: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    min-width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    max-width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
.content-keypoints__box {
  height: 100%;
  width: 100%;
  padding: 57px 50px 50px;
  border-radius: 16px;
  background-color: #fff;
}
.content-keypoints__index {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #ff5f24;
}
.content-keypoints__text {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #a51890;
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content__column:first-child .content-keypoints__line {
  transform-origin: right center;
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content__column:last-child .content-keypoints__line {
  transform-origin: left center;
}
@media (min-width: 67.75em) {
  html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content-keypoints__index {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.4s 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.4s 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content-keypoints__line,
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content__line-spark::before {
  transform: scaleX(0);
  transition: transform 0.5s 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content-keypoints__circle::before {
  transform: scale(0);
  transition: transform 1.1s 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view .content__inner::before {
  transform: scale(0);
  transition: transform 1.1s 0.1s cubic-bezier(0.19, 1, 0.22, 1);
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view.in-view .content-keypoints__line,
html:not(.mobile):not(.ie) .content-keypoints.js-in-view.in-view .content__line-spark::before {
  transform: scaleX(1);
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view.in-view .content-keypoints__index {
  transform: translateY(0px);
  opacity: 1;
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view.in-view .content-keypoints__circle::before {
  transform: scale(1);
}
html:not(.mobile):not(.ie) .content-keypoints.js-in-view.in-view .content__inner::before {
  transform: scale(1);
}

.content-key-numbers {
  padding-top: 60px;
  padding-bottom: 60px;
}
.content-key-numbers .module-header {
  margin-bottom: 50px;
}
@media (min-width: 37.5em) and (max-width: 67.74em) {
  .content-key-numbers__list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 76.24em) {
  .content-key-numbers__list {
    align-items: stretch;
  }
}
.content-key-numbers__item {
  padding: 50px;
  border-radius: 16px;
  background-color: #fff;
}
@media (max-width: 37.49em) {
  .content-key-numbers__item {
    width: 100%;
  }
}
@media (min-width: 37.5em) {
  .content-key-numbers__item {
    width: calc(50% - 11px);
    max-width: 360px;
  }
}
@media (min-width: 37.5em) and (max-width: 67.74em) {
  .content-key-numbers__item:nth-child(even) {
    margin-left: 22px;
  }
}
@media (min-width: 67.75em) and (max-width: 76.24em) {
  .content-key-numbers__item {
    flex-basis: calc(25% - 18px);
    min-width: calc(25% - 18px);
    max-width: calc(25% - 18px);
  }
  .content-key-numbers__item:not(:first-child) {
    margin-left: 20px;
  }
}
@media (min-width: 76.25em) {
  .content-key-numbers__item {
    flex-basis: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
    min-width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
    max-width: calc((3 * calc((1160px - 440px) / 12)) + (2 * 40px));
  }
}
@media (min-width: 90.75em) {
  .content-key-numbers__item {
    flex-basis: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    min-width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
    max-width: calc((3 * calc((1392px - 528px) / 12)) + (2 * 48px));
  }
}
.content-key-numbers__value {
  margin-bottom: 12px;
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #ff5f24;
  font-size: 2.5rem;
  line-height: 1.1;
  letter-spacing: -1px;
}
.content-key-numbers__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}

.content-cards-slider .module-header__text {
  margin-bottom: 0;
}

.content-quotes .content__line {
  flex-basis: calc((2 * calc((1160px - 440px) / 12)) + (1 * 40px));
}
@media (min-width: 90.75em) {
  .content-quotes .content__line {
    flex-basis: calc((2 * calc((1392px - 528px) / 12)) + (1 * 48px));
  }
}
.content-quotes .content__line-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 140px;
  width: 140px;
  margin-top: -70px;
  margin-left: -70px;
  fill: transparent;
  stroke-width: 2px;
  stroke: rgba(226, 225, 210, 0.5);
  stroke-dasharray: 3px 12px;
  stroke-linecap: round;
}
.content-quotes .content__line-circle circle {
  r: calc(50% - 4px);
}
.content-quotes .content__line-segment--1 {
  min-height: 130px;
  max-height: 130px;
}
.content-quotes__desktop {
  position: relative;
}
.content-quotes__images-list {
  border-color: #e2e1d2;
  background-color: #e2e1d2;
}
.content-quotes__images svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  fill: transparent;
  stroke-width: 2px;
  stroke: rgba(226, 225, 210, 0.5);
  stroke-dasharray: 3px 12px;
  stroke-linecap: round;
}
.content-quotes__images svg circle {
  r: calc(50% - 4px);
}

.sidebar-author__title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.sidebar-author__text {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.sidebar-author__text a {
  color: #a51890;
}
.no-touchevents .sidebar-author__text a:hover, .no-touchevents .sidebar-author__text a:focus {
  color: #5f71a5;
}

.widget__title {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}
.widget__title::after {
  height: 2px;
  background-color: #e2e1d2;
}

.widget-text__lead {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}
.widget-text__typeset {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #5f71a5;
}
.widget-text__typeset h2:not(.widget__title),
.widget-text__typeset h3,
.widget-text__typeset h4,
.widget-text__typeset h5,
.widget-text__typeset h6 {
  font-family: "Nunito", serif;
  font-weight: 800;
  font-style: normal;
  color: #003fce;
}
.widget-text__typeset h5::after,
.widget-text__typeset h6::after {
  height: 2px;
  background-color: #e2e1d2;
}
.widget-text__typeset b,
.widget-text__typeset strong {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #06294e;
}
.widget-text__typeset i,
.widget-text__typeset em {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 400;
  font-style: italic;
}
.widget-text__typeset a:not(.button) {
  color: #003fce;
}
.widget-text__typeset a:not(.button) b,
.widget-text__typeset a:not(.button) strong {
  color: inherit;
}
.no-touchevents .widget-text__typeset a:not(.button):hover, .no-touchevents .widget-text__typeset a:not(.button):focus {
  color: #5f71a5;
}
.widget-text__typeset ul li::before {
  background-color: #003fce;
}
.widget-text__typeset ol li::before {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}

.widget-quote {
  border-color: #e2e1d2;
}
.widget-quote p {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.widget-quote cite {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: rgba(95, 113, 165, 0.5);
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.widget-image {
  border-radius: 16px;
}

.widget-video {
  border-radius: 16px;
}
.widget-video__play {
  z-index: 3;
}
.widget-video__play .svg {
  fill: #a51890;
}
.widget-video__poster::before, .widget-video__poster::after {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
}
.widget-video__poster::after {
  background-image: linear-gradient(198deg, rgba(68, 23, 62, 0) 28%, rgba(68, 23, 62, 0.7) 70%);
}
.no-touchevents .widget-video__poster:hover .widget-video__play, .no-touchevents .widget-video__poster:focus .widget-video__play {
  background-color: #a51890;
}

.widget-videos__item {
  border-radius: 16px;
  background-color: #fff;
}
.widget-videos__item-title {
  font-family: "Nunito", serif;
  font-weight: 700;
  font-style: normal;
  color: #003fce;
}
.widget-videos__item-text {
  font-family: "FF-Mark", serif;
  font-weight: 500;
  font-style: normal;
  color: #5f71a5;
}

.popup {
  background-color: rgba(68, 23, 62, 0.9);
}

.module-popup-video {
  --plyr-color-main: #a51890;
}
.module-popup-video .plyr {
  border-radius: 24px;
}

.module-popup-activity__content-title {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #06294e;
}
@media (max-width: 51.74em) {
  .module-popup-activity__content-title {
    font-family: "FF-Mark-Pro", serif;
    font-weight: 700;
    font-style: normal;
  }
}
.module-popup-activity__content-text {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #4D5A7C;
}
.module-popup-activity__content-text a {
  color: #a51890;
}

.module-popup-subscription__content-title {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #06294e;
}
.module-popup-subscription__content-text {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #e2e1d2;
}
.module-popup-subscription__content-text a {
  color: #a51890;
}
.module-popup-subscription__perks {
  background-color: #00e0ac;
}
.module-popup-subscription__perks-title {
  font-family: "FF-Mark-Pro", serif;
  font-weight: 700;
  font-style: normal;
  color: #06294e;
}
.module-popup-subscription__perks-list-item {
  font-family: "FF-Mark", serif;
  font-weight: 400;
  font-style: normal;
  color: #06294e;
}

@font-face {
  font-family: "FF-Mark-Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/FF-Mark-Pro-Regular.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Pro-Regular.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark-Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/FF-Mark-Pro-Bold.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Pro-Bold.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/FF-Mark-Book.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Book.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/FF-Mark-Medium.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Medium.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark-Pro";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/FF-Mark-Pro-Heavy.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Pro-Heavy.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark-Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/FF-Mark-Pro-Regular-Italic.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Pro-Regular-Italic.woff") format("woff");
}
@font-face {
  font-family: "FF-Mark";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/FF-Mark-Medium-Italic.woff2") format("woff2"), url("../assets/fonts/FF-Mark-Medium-Italic.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Nunito-Extra-Bold.woff2") format("woff2"), url("../assets/fonts/Nunito-Extra-Bold.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Nunito-Bold.woff2") format("woff2"), url("../assets/fonts/Nunito-Bold.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Nunito-Semi-Bold.woff2") format("woff2"), url("../assets/fonts/Nunito-Semi-Bold.woff") format("woff");
}