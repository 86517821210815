.module-card {
  &__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner {
      transform: translateY(-12px);
      box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);

      .module-card__dot {
        opacity: 1;
        transform: translateX(5px);
      }

      &::before {
        opacity: 0;
      }
    }
  }

  &__dot {
    @include position(absolute, 43px 35px null null);
    @include size(17px, 15px);
    opacity: 0.2;
    background-image: url('../assets/images/icons/card-arrow@2x.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    transition: opacity 0.2s $ease-out-quart, transform 0.3s $ease-out-quart;
  }

  &__inner {
    border-radius: 16px;
    background: $brand-white;
    transition: transform 0.3s $ease-out-quart, box-shadow 0.3s $ease-out-quad, border-radius 0.3s $ease-out-quart;

    &::before {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      border-radius: 16px;
      border: 1px solid $brand-purple-light;
      transition: opacity 0.3s $ease-out-quart;
    }
  }
}
