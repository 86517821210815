.module-pagination {
  border-width: 2px;
  border-color: $brand-grey;

  &__button {
    @include font('sans-serif', 'medium');
    color: $brand-blue-brighter;

    .svg {
      position: relative;
      top: 1px;

      &--arrow-right {
        margin-left: 13px;
      }

      &--arrow-left {
        margin-right: 13px;
      }
    }
  }

  &__list {
    &-item {
      @include font('sans-serif', 'book');
      color: $brand-blue-brighter;

      &--active {
        @include font('sans-serif', 'bold');
        background-color: $brand-white;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-purple-bright;
      }
    }
  }
}
