.module-image {
  position: relative;

  &__video-button {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
    z-index: 2;

    &-play {
      @include position(absolute, 50% null null 50%);
      @include size(72px);
      @include margin(-36px null null -36px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $brand-white;
      transition: background-color 0.3s $ease-out-quart;
      fill: $brand-purple-bright;
  
      @include mq($until: 'm') {
        @include size(56px);
        @include margin(null);
        @include margin(-28px null null -28px);
      }
      
      .svg {
        transition: fill 0.3s $ease-out-quart;
      }
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      .module-image__video-button-play {
        fill: $brand-white;
        background-color: $brand-purple-bright;
      }
    }
  }

  &__elements {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
  }
  
  &__item {
    transition: opacity .3s $ease-out-quad;

    picture {
      @include margin(null auto);
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      @include size(100%);
      @include object-fit(cover);
      display: block;
    }

    &--1 {
      transition-delay: .2s;
    }

    &--2 {
      transition-delay: .35s;
    }
  }

  &--large {
    width: 415px;
    margin-left: auto;

    @include mq($until: 'm') {
      width: 375px;
    }

    @include mq($until: 's') {
      width: 285px;
    }

    .module-image__items {
      @include mq($from: 'm') {
        margin-top: -40px;
      }
    }

    .module-image__item--1 {
      picture {
        @include size(414px);
        clip-path: url(#large-large);

        @include mq($until: 'xl') {
          @include size(360px);
        }

        @include mq($until: 'm') {
          @include size(300px);
        }

        @include mq($until: 's') {
          @include size(252px);
        }
      }
    }

    .module-image__item--2 {
      picture {
        @include size(200px);
        margin-top: -60px;
        margin-left: 260px;
        clip-path: url(#large-small);

        @include mq($until: 'xl') {
          @include size(170px);
          margin-left: 210px;
          margin-top: -20px;
        }

        @include mq($until: 's') {
          @include size(126px);
        }

        @include mq($until: 'xs') {
          margin-left: 160px;
        }
      }
    }

    .circle--1 {
      @include position(absolute, null 20px 250px null);
      @include size(28px, 35px);

      @include mq($until: 'xl') {
        bottom: 200px;
        right: 50px;
      }

      @include mq($until: 's') {
        right: 0;
        bottom: 170px;
      }
    }
  }

  &--medium {
    width: 375px;
    
    @include mq($until: 'm') {
      top: -20px;
      width: 300px;
    }

    @include mq($until: 's') {
      width: 200px;
    }

    .module-image__item--1 {
      margin-left: -60px;

      picture {
        @include size(460px);
        clip-path: url(#medium-large);

        @include mq($until: 'xl') {
          @include size(360px);
        }

        @include mq($until: 'm') {
          @include size(300px);
        }

        @include mq($until: 's') {
          @include size(290px);
        }
      }
    }

    .module-image__item--2 {
      picture {
        @include position(absolute, -20px -30px null null);
        @include size(140px);
        clip-path: url(#small-small);

        @include mq($until: 'm') {
          @include size(110px);
        }

        @include mq($until: 's') {
          @include size(85px);
        }
      }
    }

    .circle--1 {
      @include position(absolute, -44px 100px null null);
      @include size(28px, 36px);

      @include mq($until: 's') {
        right: 70px;
      }

      &::before {
        transform: scaleX(-1); 
      }
    }
  }

  &--small {
    width: 370px;

    @include mq($until: 's') {
      width: 250px;
    }
    
    .module-image__item--1 {
      picture {
        @include size(367px);
        clip-path: url(#small-large);

        @include mq($until: 'xl') {
          @include size(300px);
        }

        @include mq($until: 'm') {
          @include size(260px);
        }

        @include mq($until: 's') {
          @include size(240px);
        }
      }
    }

    .module-image__item--2 {
      @include position(absolute, -40px -30px null null);

      picture {
        @include size(127px);
        margin-left: 390px;
        clip-path: url(#small-small);
        
        @include mq($until: 's') {
          @include size(90px);
        }
      }
    }

    .circle--1 {
      @include position(absolute, -20px 120px null null);
      @include size(26px, 35px);

      @include mq($until: 's') {
        right: 70px;
      }

      &::before {
        transform: scaleX(-1);
      }
    }
  }
}

html.ie {
  .module-image__item {
    picture {
      transform: scale(.7);
      overflow: hidden;
      border-radius: 50%;
    }
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .module-image__item {
    opacity: 0;
  }

  &.in-view {
    .module-image__item {
      opacity: 1;
    }
  }
}