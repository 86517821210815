.module-form__message {
  @include font('alternative', 'extra-bold');

  &--valid {
    border-color: $brand-purple-bright;
    background-color: #f5ecfa;
    color: $brand-purple-bright;
  }

  &--error {
    border-color: $brand-orange-bright;
    background-color: #fff4ee;
    color: $brand-orange-bright;
  }
}
