.header-inner {
  &__title {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    letter-spacing: -2px;
  }

  &__text,
  &__metas {
    color: $brand-blue;
  }
  
  &__text {
    @include font('sans-serif', 'regular');
  }

  &__metas {
    @include font('sans-serif', 'medium');
  }
}
