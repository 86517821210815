.module-card-small {
  min-height: 205px;

  .module-card__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner {
      .module-card-small__title {
        color: $brand-purple-bright;
      }
    }
  }

  .module-card__inner {
    padding: 40px 95px 40px 40px;
  }

  &__icon {
    background-color: $brand-yellow-bright;
  }

  &__title {
    @include padding(0 null null);
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
    transition: color 0.3s $ease-out-quart;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }

  &--narrow {
    min-height: 0;

    .module-card__link {
      + .module-card__inner {
        padding: 40px 80px 40px 40px;
      }
    }

    .module-card__inner {
      padding: 20px 40px 20px 40px;
    }

    .module-card__dot {
      top: 45px;
    }
  }
}
