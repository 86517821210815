.widget {
  &__title {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;

    &::after {
      height: 2px;
      background-color: $brand-grey;
    }
  }
}
