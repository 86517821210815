.module-card-program {
  .module-card__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner {
      transform: translateY(0);
      box-shadow: none;

      .module-card-program__title {
        color: $brand-purple-bright;
      }

      .module-card-program__logo {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__location,
  &__elligibility {
    color: $brand-blue-brighter;
  }

  &__location {
    @include font('alternative', 'extra-bold');
  }

  &__elligibility {
    @include font('alternative', 'semi-bold');
  }

  &__title {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    transition: color 0.3s $ease-out-quart;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }

  &__logo {
    transition: transform 0.3s $ease-out-quart;

    &::after {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      content: '';
      z-index: -1;
      opacity: 0;
      border-radius: 16px;
      box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);
      transition: opacity 0.3s $ease-out-quart;
    }
  }
}
