.module-header {
  &__title {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
    line-height: (46 / 44);
    letter-spacing: -1.5px;
  }

  &__text,
  &__caption {
    @include font('sans-serif', 'regular');
    color: $brand-blue;

    a {
      color: $brand-blue-bright;
    }
  }
}
