.sidebar-author {
  &__title {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;

    a {
      color: $brand-purple-bright;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-blue;
      }
    }
  }
}
