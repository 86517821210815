.widget-text {
  &__lead {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
  }

  &__typeset {
    @include font('sans-serif', 'regular');
    color: $brand-blue;

    h2:not(.widget__title),
    h3,
    h4,
    h5,
    h6 {
      @include font('alternative', 'extra-bold');
      color: $brand-blue-brighter;
    }

    h5,
    h6 {
      &::after {
        height: 2px;
        background-color: $brand-grey;
      }
    }

    b,
    strong {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    i,
    em {
      @include font('sans-serif', 'regular-italic');
    }

    a:not(.button) {
      color: $brand-blue-brighter;

      b,
      strong {
        color: inherit;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-blue;
      }
    }

    ul {
      li::before {
        background-color: $brand-blue-brighter;
      }
    }

    ol {
      li::before {
        @include font('sans-serif', 'bold');
        color: $brand-blue-brighter;
      }
    }
  }
}
