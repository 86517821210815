.module-filters-bar {
  @include mq($from: 'm') {
    border-width: 2px;
    border-color: $brand-grey;
  }

  &__text,
  &__sorting-select {
    @include font('alternative', 'semi-bold');
    color: $brand-blue;
  }

  &__tags {
    @include mq($until: 'm') {
      border-color: $brand-blue-light;
    }

    &-item {
      @include font('alternative', 'extra-bold');
      background-color: $brand-white;
      color: $brand-blue-bright;
    }
  }

  &__sorting {
    &-select {
      border-color: $brand-grey;
      background-color: $brand-white;
    }

    .svg--caret-down {
      fill: $brand-blue-brighter;
    }
  }
}
