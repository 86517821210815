.footer-join {
  .module-header {
    &__title {
      color: $brand-white;
    }
  }

  &__inner {
    background-color: $brand-blue-bright;

    &::before {
      @include position(absolute, 0 null null 50%);
      @include size(3500px, 1500px);
      @include margin(null null null -1750px);
      z-index: -2;
      border-radius: 50%;
      background-color: $brand-blue-bright;
    }

    &::after {
      content: '';
      opacity: 0.25;
      background-image: url('../assets/images/decorations/dot-pattern-3@2x.png');
      background-size: 38px auto;
    }
  }

  &--affiliate {
    .module-header {
      &__title {
        color: $brand-blue-brighter;
      }
    }

    .footer-join__inner {
      background-color: $brand-yellow;

      &::before {
        background-color: $brand-yellow;
      }

      &::after {
        content: none;
      }
    }
  }

  &--curvy {
    .footer-join__inner {
      overflow: hidden;
      padding-top: 144px;
      background-color: transparent;

      &::before {
        content: '';
      }
    }
  }
}
