.content-quotes {
  .content__line {
    flex-basis: gs(2, 1);

    @include mq($from: 'xxl') {
      flex-basis: gs(2, 1, 1392px, 12, 48px);
    }

    &-circle {
      @include position(absolute, 50% null null 50%);
      @include size(140px);
      @include margin(-70px null null -70px);
      fill: transparent;
      stroke-width: 2px;
      stroke: $brand-grey-light;
      stroke-dasharray: 3px 12px;
      stroke-linecap: round;

      circle {
        r: calc(50% - 4px);
      }
    }

    &-segment {
      &--1 {
        min-height: 130px;
        max-height: 130px;
      }
    }
  }

  &__desktop {
    position: relative;
  }

  // .content__column {
  //   margin-top: 0;
  // }

  &__images {
    &-list {
      border-color: $brand-grey;
      background-color: $brand-grey;
    }

    svg {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      fill: transparent;
      stroke-width: 2px;
      stroke: $brand-grey-light;
      stroke-dasharray: 3px 12px;
      stroke-linecap: round;

      circle {
        r: calc(50% - 4px);
      }
    }
  }
}
