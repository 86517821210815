.module-card-date {
  &:not(:first-child) {
    border-color: $brand-grey;
    border-width: 2px;
  }

  &__hour,
  &__date {
    @include font('alternative', 'extra-bold');
    color: $brand-blue-brighter;
  }

  &__text,
  &__caption {
    @include font('sans-serif', 'regular');
  }

  &__text {
    color: $brand-blue-brighter;
  }

  &__caption {
    color: $brand-blue;
  }

  + .module-card-date {
    margin-top: -2px;
  }
}
