.content-archive {
  &__items {
    + .content-archive__items {
      margin-top: 40px;
    }
  }

  &[data-column="1"] {
    .module-card-program {
      + .module-card-program {
        border-width: 2px;
        border-color: $brand-grey;
      }
    }

    .content-archive__items {
      + .content-archive__items {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 2px solid $brand-grey;
      }
    }
  }
}
