.module-card-press {
  .module-card__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner {
      .module-card-press__title {
        color: $brand-purple-bright;
      }
    }
  }

  &__title {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
    transition: color 0.3s $ease-out-quart;
  }

  &__date {
    @include font('sans-serif', 'medium');
    color: $brand-blue;
    border-color: $brand-grey;
  }
}
