.module-card-habit {
  &__title {
    @include font('alternative', 'bold');
    color: $brand-blue-brighter;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-blue;
  }

  &__icon {
    background-color: $brand-grey-lighter;
  }
}
