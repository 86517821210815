.content {
    .module-header {
        margin-bottom: 55px;

        &__text {
            margin-bottom: 80px;
        }

        &--s {
            margin-bottom: 55px;
        }
    }

    &::after {
        @include position(absolute, 0 null null 50%);
        @include size(3500px, 1500px);
        @include margin(null null null -1750px);
        z-index: -1;
        border-radius: 50%;
        background-color: $brand-white;
    }

    &--curvy {
        margin-top: 40px;
        padding-top: 144px;
        padding-bottom: 140px;
        overflow: hidden;

        &::after {
            content: '';
        }

        + .footer-join {
            padding-top: 0;
        }
    }
}
